import React, { Fragment } from 'react';
import './helpPage.scss';
import { ProjectAndFinancingAgreementsFAQ } from './faq';
import { Accordion, AccordionSummary, AccordionDetails } from '../../component/accordion/accordion';

const ContractHelp = () => {
  const [expanded, setExpanded] = React.useState<string | false>('panel00');
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div className="faq-pane">
      {ProjectAndFinancingAgreementsFAQ.map((faq, index) => (
        <Fragment key={index}>
          <ol>
            {faq.questions.map((question, qIndex) => (
              <Fragment key={qIndex}>
                <Accordion expanded={expanded === `panel${index}${qIndex}`} onChange={handleChange(`panel${index}${qIndex}`)}>
                  <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <div className="question">{question.question}</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='description'>
                      <ul className='unorederList'>
                        {question?.answers?.map((answer, index) => (
                          <Fragment key={index}>
                            <li>{answer.desc}</li>
                            <ul className="bulletin">
                              {answer?.points?.map((point, index) => (
                                <li key={index}>{point.point}</li>
                              ))}
                            </ul>
                            {answer?.image && <img src={answer.image} alt="related image" className='qa-answer' />}
                          </Fragment>
                        ))}
                        {question?.notes?.map((note, index) => (
                          <Fragment key={index}>
                            <li><em>{note.desc}</em></li>
                            {note?.image && <img src={note.image} alt="related image" className='qa-answer' />}
                          </Fragment>
                        ))}
                      </ul>
                    </div>
                  </AccordionDetails>
                </Accordion>                
              </Fragment>
            ))}
          </ol>
        </Fragment>
      ))}
    </div>
  );
};

export default ContractHelp;
