import { ROLES } from "../constants/commonConstants";

const commonRoles = [
  ROLES.SALES_REPRESENTATIVE,
  ROLES.SUPER_ADMIN,
  ROLES.USER_MGMT_ADMIN,
  ROLES.GUEST,
];

export const PROJECT_AND_FINANCING_AGREEMENTS_ROLES = [
  ROLES.CONTRACTS_TRAIL,
  ROLES.CONTRACTS_SUBSCRIBER,
  ...commonRoles,
];

export const FINANCIAL_MODELS_ROLES = [
  ROLES.FINANCIAL_MODELS_TRAIL,
  ROLES.FINANCIAL_MODEL_SUBSCRIBER,
  ...commonRoles,
];

export const showReleaseBannerDays = 7;

export const maximumFilterAllowedToSave = 5;