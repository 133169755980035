/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import _ from "lodash";
import {
  ContractTypeList,
  LenderList,
  SponsorList,
} from "../../constants/api-urlConstants";
import instance from "../../utils/axiosInstance";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CommonContextValueStore } from "../../constants/commonContext";
import { ErrorCodes } from "../../constants/messageCodes";
import { MessageText } from "../../constants/messageText";
import Accordions from "../filterDropdown/accordion";
import "../sidebar/sidebar.scss";

interface Props {
  onFilters: (note: any) => void;
  sidebarToggled: (note: any) => void;
  removeFilters:any;
  applySavedFilter:any;
}

export default function AgreementSideBar (prop: Props) {
  const {setSnackabrConfig,setUserHeartBeat,timeExit} = React.useContext(CommonContextValueStore);

  const[toogleagrreSideNav,setToggleagrreSideNav] =useState<any>(true);

  const [filterList, setFilterList] = useState<any>([
    {
      name: "Type of agreement",
      list: [],
      filtered_list: [],
      count: null,
      key: "CONTRACT_TYPE",
      loading: true,
      checked: [],
      searchTxtValue:'',
      searchPlaceholder:'Search agreement types'
    },
    {
      name: "Sponsor",
      list: [],
      filtered_list: [],
      count: null,
      key: "SPONSOR",
      loading: true,
      checked: [],
      searchTxtValue:'',
      searchPlaceholder:'Search Sponsors'

    },
    {
      name: "Lender",
      list: [],
      filtered_list: [],
      count: null,
      key: "LENDER",
      loading: true,
      checked: [],
      searchTxtValue:'',
      searchPlaceholder:'Search Lenders'
    },

  ]);
  const setFilterListValues = (filterKey: any, listData: any,selectedItems:any = null) => {
    filterList.map((item: any) => {
      if (item.key == filterKey) {
        item.list = listData;
        item.filtered_list = listData;
        item.count = listData.length;
        item.loading = false;
        item.checked = selectedItems ? selectedItems : [];
        item.checked_count = item.checked.length;
        return;
      }
    });
    setFilterList([...filterList]);
  };

  const noFilterListValues = (filterKey: any, listData: any) => {
    filterList.map((item: any) => {
      if (item.key == filterKey) {
        item.loading = false;
        return;
      }
    });
    setFilterList([...filterList]);
  };


  const getTypesOfAgreementsList = async () => {
    const TYPES_OF_AGREEMENTS_URL = `${ContractTypeList}`;
    instance
      .get(TYPES_OF_AGREEMENTS_URL)
      .then((response) => {
        if (response.data.success) {
          const selectedItems =  priserveFilter("CONTRACT_TYPE");
          const res = response.data;
          if(selectedItems){
            selectedItems.filter((filterItem:any) =>{
              res.data.map((item:any)=>{
                if(filterItem.key == item.key){
                  item.isSelected = true;
                }             
            });
            })
          }
          if (res.data.length > 0) {
            setFilterListValues("CONTRACT_TYPE", res.data,selectedItems);
          }
          else{
            noFilterListValues("CONTRACT_TYPE", res.data);
          }
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
        console.log(error);
      });
  };

  const getSponsorsList = async() => {

    const SPONSORS_LIST_URL = `${SponsorList}`;

    instance

      .get(SPONSORS_LIST_URL)
      .then((response) => {
        setUserHeartBeat(timeExit());
        if (response.data.success) {
          const selectedItems =  priserveFilter("SPONSOR");
          const res = response.data;
          if(selectedItems){
            selectedItems.filter((filterItem:any) =>{
              res.data.map((item:any)=>{
                if(filterItem.key == item.key){
                  item.isSelected = true;
                }             
            });
            })
          }
          if (res.data.length > 0) {
            setFilterListValues("SPONSOR", res.data,selectedItems);
          }
          else{
            noFilterListValues("SPONSOR", res.data);
          }
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
        console.log(error);
      });
  };

  const getLenderList = async () => {

    const LENDER_LIST_URL = `${LenderList}`;
    instance
      .get(LENDER_LIST_URL)
      .then((response) => {
        if (response.data.success) {
          const selectedItems =  priserveFilter("LENDER");
          const res = response.data;
          if(selectedItems){
            selectedItems.filter((filterItem:any) =>{
              res.data.map((item:any)=>{
                if(filterItem.key == item.key){
                  item.isSelected = true;
                }             
            });
            })
          }
          if ( res.data.length > 0) {
            setFilterListValues("LENDER",  res.data ,selectedItems);
          }
          else{
            noFilterListValues("LENDER",  res.data);
          }
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
        console.log(error);
      });
  };

// when filter prsent in session storege then he call
  const priserveFilter =(filterNameKey:any)=>{
    const agreementFilters: any = JSON.parse(
      sessionStorage.getItem("agreementFilters") || '[]'
    );
      const selectedItems:any = [];
    if(agreementFilters.length){
      
      agreementFilters.map((item: any) => {
          if (filterNameKey == item.type) {
            selectedItems.push(item) 
          }
        })
        return selectedItems;  
      };
      
};
//this function used for when user remove chip then he call
  const uncheckFilter=(item:any)=>{

  let selectedFilters: any = [];
    filterList.map((uncheckedFilter:any) =>{
      if(uncheckedFilter.key == item.type){
        const arrayWithoutD =  uncheckedFilter.checked.filter(function (subItem:any) {
          return subItem.key !== item.key;
       });
        uncheckedFilter.checked = arrayWithoutD;

        uncheckedFilter.filtered_list.map((subItem:any)=>{
            if(subItem.key == item.key){
              subItem.isSelected = false;
            }
        })
      }
      selectedFilters = [...selectedFilters, ...uncheckedFilter.checked]; 
    });
    setFilterList([...filterList]);
   };

//this function used for when saved filter apply then he call
const checkedFilter=(item:any)=>{
  filterList.map((items: any) => {
    items.checked=[];
    items.searchTxtValue="";
    items.filtered_list.map((subItem:any)=>{
      subItem.isSelected = false;
    })
  })
  setFilterList([...filterList]);
  let selectedFilters: any = [];
  filterList.map((checkedFilter:any) =>{
    item.map((subItem:any)=>{
      if(checkedFilter.key == subItem.type){
        checkedFilter.checked.push(subItem);
        checkedFilter.filtered_list.map((item:any)=>{
          if(subItem.key == item.key){
            item.isSelected = true;
          }})
        }
      })
    selectedFilters = [...selectedFilters, ...checkedFilter.checked]; 
  });
  setFilterList([...filterList]);
 }

  const delayedQueryAgreementFilter = useCallback(
    _.debounce((q: any) => selectedFilterList(q), 700),
    []
  );

  //this function use for celar all filter 
  const filterEmty = useCallback(
    () => {
      filterList.map((item: any) => {
        requestSearch('',item);
        item.checked=[];
        item.searchTxtValue="";
        item.filtered_list.map((subItem:any)=>{
          subItem.isSelected =false
        })
      })
      setFilterList([...filterList]);
      sessionStorage.setItem("searchTextValueAgreement",'');
      let selectedFilters: any = [];
      sessionStorage.setItem("agreementFilters", JSON.stringify(selectedFilters));

      prop.onFilters(selectedFilters);
    },
    [],
  );

  const selectedFilterList = (value: any) => {
    let selectedFilters: any = [];
    filterList.map((item: any) => {
      selectedFilters = [...selectedFilters, ...item.checked];
    });
    prop.onFilters(selectedFilters);
  };

  function escapeRegExp(value: any) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const requestSearch = (searchValue: any, currentFilter: any) => {
    const searchRegex: any = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = currentFilter.list.filter((row: any) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row.value?.toString());
      });
    });
    currentFilter.searchTxtValue = searchValue;
    currentFilter.filtered_list = filteredRows;
    setFilterList([...filterList]);
  };

   //this memo used for when saved filter apply then he call
  useMemo(()=>{
    if(prop.applySavedFilter.isChipAdd){
      checkedFilter(prop.applySavedFilter.chipItem);
    }
  },[prop.applySavedFilter])

  useEffect(() => {
    if (prop.removeFilters.isChipRemoved) {
      uncheckFilter(prop.removeFilters.chipItem);
    } else {
      getSponsorsList();
      getTypesOfAgreementsList();
      getLenderList();
    }

  }, [prop.removeFilters]);



  return (
    <div className="sideBarwrappers">
      {toogleagrreSideNav && (

          <div className="sidebar">
            <div className={`${toogleagrreSideNav ? "sidebarWrapper" : " siderButton"}`}>
              <div className="filterTop">
                <span className="filterText">Filters</span>
                <div className="clearText" onClick={() => {
                  filterList.map((item: any) => {
                    if(item.checked.length){
                      filterEmty();
                    }else{
                      return;
                    }})
                }}>
                  Clear all
                </div>
              </div>
              <div className="sidebarFilter">
           {filterList.map((values: any) => {
              return(
                <Accordions
                key={values.name}
                name={values.name}
                filterlist={values.list}
                list={values.filtered_list}
                loading={values.loading}
                searchTxtValue={values.searchTxtValue}
                onSearchReq={(value:any)=>{
                  requestSearch(value,values)
                }}
                checkedlistValues={(value: any) => {
                  if (value.isSelected == true) {
                    values.checked.push(value);
                    values.checked.map((item:any,index:any)=>{
                      if(!item.isSelected){
                        if(item.key == value.key){
                        values.checked.splice(index,1)
                        }
                      }
                    })  
                  }
                   else {
                    values.checked.map((item:any,index:any)=>{
                      if(item.key == value.key){
                        values.checked.splice(index,1)
                       }
                     })}
                  delayedQueryAgreementFilter(filterList);
                } } 
                searchPlaceholders={values.searchPlaceholder}
                />

              )
           })}
              </div>
            </div>
          </div>

      )}
      <div className={toogleagrreSideNav ? "toogleButton" : "closeToggle"}>
        {toogleagrreSideNav ? (
          <ArrowBackIosNewIcon
            className="arrowButton"
            onClick={() => {
              setToggleagrreSideNav(!toogleagrreSideNav);
              prop.sidebarToggled(!toogleagrreSideNav);
            }}
          />
        ) : (
          <ArrowForwardIosIcon
            className="leftArrowButton"
            onClick={() => {
              setToggleagrreSideNav(!toogleagrreSideNav);
              prop.sidebarToggled(!toogleagrreSideNav);
            }}
          />
        )}
      </div>
    </div>
  );
};
