/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { useEffect, useState } from 'react';
import './kibanaDashboard.scss';
import { kibanaLoginAPIUrl, kibanaLoginDetails, kibanaVersion } from "../../constants/env-url";
import { CircularProgress } from '@mui/material';

interface KibanaDashboardProps {
  iframeUrl: string;
}

export const KibanaDashboard: React.FC<any> = (props: KibanaDashboardProps) => {

  const { iframeUrl } = props;
  const [loadIframe, setLoadIframe] = useState(false);

  const logIntoKibana = () => {
    const payload = {
      providerType: "basic",
      providerName: "basic",
      currentURL: "",
      params: JSON.parse(kibanaLoginDetails || '{}') ,
    };

    (async () => {
      await fetch(
        `${kibanaLoginAPIUrl}`,
        {
          method: "POST",
          headers: {
            accept: "*/*",
            "accept-language": "en-US,en;q=0.9",
            "content-type": "application/json",
            "kbn-version": `${kibanaVersion}`,
            "sec-ch-ua":
              '"Not.A/Brand";v="8", "Chromium";v="114", "Google Chrome";v="114"',
            "sec-ch-ua-mobile": "?0",
            "sec-ch-ua-platform": '"macOS"',
            "sec-fetch-dest": "empty",
            "sec-fetch-site": "same-origin",
            "Access-Control-Allow-Credentials": "true",
            "x-kbn-context":
              "%7B%22type%22%3A%22application%22%2C%22name%22%3A%22security_login%22%2C%22url%22%3A%22%2Flogin%22%7D",
          },
          credentials: "include",
          body: JSON.stringify(payload),
        }
      );
    })().then(() => {});
  };

  useEffect(() => {
    if (iframeUrl) {
      logIntoKibana();
      logIntoKibana();
    }
    setTimeout(() => {
      setLoadIframe(true);
    }, 3000);
  }, []);

  return (
    <div className="iframeDiv">
      {iframeUrl &&
        <>
          {
            loadIframe ?
            <iframe src={iframeUrl} height="98%" width="99%"></iframe>
            :
            <div className="center-align h-full">
              <CircularProgress size={28} />
            </div>
          }
        </>
      }
    </div>
  )
}