import { KibanaDashboard } from "../kibanaDashboard/kibanaDashboard";
import { contractModelKibanaUrl} from "../../constants/env-url";

const ContractModel = () => {
  return (
    <KibanaDashboard
      iframeUrl={contractModelKibanaUrl}
    />
  );
}

export default ContractModel;
