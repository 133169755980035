/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/



import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { accessMangerBasePath } from "../constants/env-url";
import { showContracts, showFinancialModels } from "../utils";




interface GuardedRouteProps {
    children: React.ReactChild,    
    roleRequired?: "SUPER_ADMIN" | "USER"
}

export const getCurrentUserRoles = () => {
    const user: any = JSON.parse(localStorage.getItem('user-info') || '{}');
    return user.roles || [];
}

const useAuth = () => {
	//get item from localstorage

	let user: any = JSON.parse(localStorage.getItem('user-info') || '{}');


	if (user) {
		return {
			auth: true,
			role: user.isAdmin ? 'SUPER_ADMIN' : 'USER',
		}
	} else {
		return {
			auth: false,
			role: null,
		}
	}
}

const GuardedRoute: React.FC<GuardedRouteProps> = (props: any) => {    
    const { children } = props;    
    const {auth, role} = useAuth();
    const roles = getCurrentUserRoles();

    
    if ( Cookies.get('auth') == 'false'  &&  Cookies.get('guest-auth') == 'false' ) {
            localStorage.clear();
            window.location.href =`${accessMangerBasePath}/login`;            
            return 
    }

    if (props.roleRequired) {

        return props.roleRequired === role ? (
            children				
       ) : (
           <Navigate to={showContracts(roles) ? "/dashboard/agreement" : '/help'} />
       )
	} 

    return children;
};

export default GuardedRoute;