/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from "@mui/material/MenuItem";
import "./navbar.scss";
import {
  LogoutUser,
  UserDetails,
  UserTrailDays,
} from "../../constants/api-urlConstants";
import { useLocation, useNavigate, } from "react-router-dom";
import { ConfirmationBox } from "../ConfirmationBox/ConfirmationBox";
import { accessMangerBasePath, apiBasePathAuth, apiBasePathUserDetail } from "../../constants/env-url";
import instance from "../../utils/axiosInstance";
import { ResetPasswordPage } from "../../pages/resetPassword/resetPassword";
import { CommonContextValueStore } from "../../constants/commonContext";
import { ErrorCodes } from "../../constants/messageCodes";
import { MessageText } from "../../constants/messageText";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, CircularProgress, Tab, Tabs } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SubscribePopup from "../subscribePopup/subscribePopup";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Assignment } from '@mui/icons-material';
import { showContracts, showFinancialModels } from "../../utils";

export const Navbar: React.FC<any> = () => {
  const { trigerUser, setTrigerUser, setSnackabrConfig } = React.useContext(CommonContextValueStore);

  const [openResetPass, setOpenResetPass] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [openSubscribePopup, setOpenSubscribePopup] = React.useState(false);
  const [tabs, setTabs] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (location.pathname === '/dashboard') {
      handleTabChange(location.pathname);
    } else if (location.pathname.includes('/dashboard/term')) {
      handleTabChange(location.pathname);
    } else if (location.pathname === '/dashboard/agreement') {
      handleTabChange(location.pathname);      
    } else if (location.pathname === '/dashboard/model-table') {
      handleTabChange(location.pathname);
    } else if (location.pathname === '/help') {
      handleTabChange(location.pathname);
    }
  }, [window.location.pathname])

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isGuestUser) {
      setAnchorElUser(event.currentTarget);
    }
  };

  const handleOpenHelpMenu = (event: React.MouseEvent<HTMLElement>) => {
    navigate("/help");
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [userData, setUserData] = React.useState<any>({});
  const navigate = useNavigate();
  const [trailUserData, setTrailUserData] = React.useState<any>({});
  const [isGuestUser, setIsGuestUser] = React.useState<any>(false);
  const [isTrialUser, setIsTrialUser] = React.useState<any>(false);
  const location = useLocation();
  const currentTab = localStorage.getItem("currentTab");
  const [value, setValue] = React.useState(currentTab);

  const onTabChanged = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTabChange(newValue);
    setValue(newValue);
  };  

  const handleTabChange = (value: string) => {
    localStorage.setItem("currentTab", value);
    setValue(value);
  }

  const setSelectedTabChange = (tab: any) => {
    localStorage.setItem("currentTab", tab);
    if (window.location.search !== '' && tab === '/dashboard/term') {
      navigate(tab + window.location.search);
    } else {
      navigate(tab);
    }
  };

  const getTrailUSerDays = () => {
    const USER_API_BASE_URL = `${apiBasePathUserDetail}${UserTrailDays}`;
    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        setTrailUserData(response.data.data)

      })
      .catch((error) => {
        console.log(error)
      });
  }

  const defineTabs = (userRoles: string[] = []) => {
    const tabs = [];
    if (showContracts(userRoles)) {
      tabs.push({ name: 'Agreements', routeTo: '/dashboard/agreement', icon: Assignment });
      tabs.push({ name: 'Financial Models', routeTo: '/dashboard/model-table', icon: Assignment });
      tabs.push({ name: 'Find & Compare Terms', routeTo: '/dashboard/term', icon: Assignment });
      tabs.push({ name: 'Map', routeTo: '/map', icon: Assignment });
    }
    tabs.push({ name: 'FAQ', routeTo: '/help', icon: HelpOutlineIcon });
    setTabs(tabs);
  }

  const getUserDetails = () => {
    setIsLoading(true);
    const USER_API_BASE_URL = `${apiBasePathAuth}${UserDetails}`;
    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        if (response.data.success) {
          const res = response.data;
          setUserData(res.data);
          defineTabs(res?.data?.roles);
          const isAdmin_value = res?.data?.roles && res?.data?.roles.length > 0 && res?.data?.roles.includes("SUPER_ADMIN");
          const isDemo_value = res?.data?.roles && res?.data?.roles.length > 0 && res?.data?.roles.includes("DOC_SEARCH_DEMO_USER");
          setIsTrialUser(res?.data?.roles && res?.data?.roles.length > 0 && res?.data?.roles.includes("DOC_SEARCH_DEMO_USER"));
          if (res?.data?.roles && res?.data?.roles.length > 0 && res?.data?.roles.includes("GUEST")) {
            setIsGuestUser(res?.data?.roles && res?.data?.roles.length > 0 && res?.data?.roles.includes("GUEST"));
          } else {
            getTrailUSerDays();
          };
          const userInfo: any = {
            id: res?.data.id,
            roles: res?.data.roles,
            isAdmin: isAdmin_value,
            isDemo: isDemo_value
          }
          localStorage.setItem('user-info', JSON.stringify(userInfo));
        }
        if (response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR) {
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const logoutUser = () => {
    const USER_API_BASE_URL = `${apiBasePathAuth}${LogoutUser}`;
    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = `${accessMangerBasePath}/login`;
      })
      .catch((error) => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = `${accessMangerBasePath}/login`;
      });
  };

  const logoutConfirm = () => {
    setAnchorElUser(null);
    setShowConfirmation(true);
  }
  const EditProfile = () => {
    setAnchorElUser(null);
    navigate("/profile");
  }

  const onConfirmAction = (value: any) => {
    if (value) {
      setShowConfirmation(false);
      logoutUser();
    } else {
      setShowConfirmation(false);
    }
  }

  const openResetPassItem = () => {
    setAnchorElUser(null);
    setOpenResetPass(true);
  };


  function stringAvatar(name: string) {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  React.useEffect(() => {
    getUserDetails();
  }, [trigerUser]);

  return (
    <>
      <div className="navbar" >
        <div className="wrapper">
          <div className="logo-wrapper">
            <div className="logo">
              <img
                src={require("../../assets/images/infraclearlogo.png")}
                className="imglogo"
                onClick={() => {
                  navigate(showContracts(userData?.roles || []) ? "/dashboard/agreement" : "/dashboard/agreement")
                }}
              />
            </div>

            <Box sx={{ borderColor: "#2A90BD" }}>
              {value &&
                <Tabs value={value} onChange={onTabChanged}>
                  {tabs.map((tab, index) => {
                    const Icon = tab.icon;
                    return (
                      <Tab
                        key={index}
                        className="tabsProject"
                        value={tab.routeTo}
                        label={
                          <React.Fragment>
                            <div className="menuTab">
                              <span className="namestab">{tab.name}</span>
                              <span className="userLogo">
                                <Icon />
                              </span>
                            </div>
                          </React.Fragment>
                        }
                        id={`simple-tab-${index}`}
                        aria-controls={`simple-tabpanel-${index}`}
                      />
                    )
                  })}
                </Tabs>
              }
            </Box>
          </div>
          <div className="navBarRight">
            <div className="items">
              <div className="profile">
                <Box sx={{ flexGrow: 0 }} className="trialWapper">
                  <div className="trialTextIconMain">
                    {
                      trailUserData?.userType == "TRIAL" &&
                      <>
                        <Button className={trailUserData?.remainingTrialDays > 5 ? 'trialDaysGreen' : 'trailDaysRed'}>
                          <span className="trialDays">{trailUserData?.remainingTrialDays} Days left</span>
                        </Button>
                        <Button onClick={() => { setOpenSubscribePopup(true) }} className="trialDaysGreen subscribe" >
                          <span className="subscribeUser">Subscribe</span>
                        </Button>
                      </>
                    }
                  </div>
                </Box>

                <Box sx={{ flexGrow: 0 }} className="profileWrapper leftBorderAdd">
                  <div className="profilename" onClick={handleOpenUserMenu}>
                    <Avatar className="avatarProfileName" {...stringAvatar(`${userData?.firstName} ${userData?.lastName}`)} />
                    <span className="textProfileName"> {userData?.firstName} {userData?.lastName}</span>
                  </div>

                  {!isGuestUser &&
                    <Tooltip title="Open settings" className="profileButton">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <KeyboardArrowDownIcon style={{ color: "#1976D2" }} />
                      </IconButton>
                    </Tooltip>
                  }
                  <Menu
                    sx={{ mt: "30px" }}
                    id="menu-appbar"
                    className="menulist"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={EditProfile}>
                      <span className="itemMenuList">
                        Edit Profile
                      </span>
                    </MenuItem>
                    <MenuItem onClick={openResetPassItem} >
                      <span className="itemMenuList">
                        Reset Password
                      </span>

                    </MenuItem>
                    <MenuItem onClick={logoutConfirm}>
                      <span className="itemMenuList">
                        Logout
                      </span>
                    </MenuItem>
                  </Menu>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmation &&
        <ConfirmationBox openConfirmation={showConfirmation} closeConfirmation={onConfirmAction} />
      }
      {openSubscribePopup &&
        <SubscribePopup openSubscribePopup={openSubscribePopup} closeSubscribePopup={() => {
          setOpenSubscribePopup(false)
        }} />
      }
      {openResetPass &&
        <ResetPasswordPage openReset={openResetPass} trialValue={isTrialUser} closePopup={(value: boolean) => { setOpenResetPass(false) }} />
      }
      {isLoading &&
        <div className="center-align h-full">
          <CircularProgress size={28} />
        </div>
      }
    </>
  );
};
