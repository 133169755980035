/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { Alert,Snackbar, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { CommonContextValueStore } from '../../constants/commonContext';
import { MessageText } from '../../constants/messageText';


export const SnackBarMessage: React.FC<any> = (props) => {

    const {setSnackabrConfig} = React.useContext(CommonContextValueStore);

   
    const [openSnackBar, setOpenSnackBar] = React.useState(props.snacBarValue.snackFlag);

    useEffect(() => {
        setOpenSnackBar(props.snacBarValue.snackFlag);
    }, [props.snacBarValue]);
 
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {

            return;
        }

        setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: false
          });
        setOpenSnackBar(false);
        // props.snacBarValue.snackFlag = false;

    };
    return (

        <div className='snackBarWrapper'>
            <Stack spacing={2} sx={{ width: '100%' }}>
             

                <Snackbar open={openSnackBar} autoHideDuration={2500} onClose={handleClose} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                    <Alert onClose={handleClose} severity={props.snacBarValue.type} sx={{ width: '100%' }}>
                        {props.snacBarValue.message}
                    </Alert>
                </Snackbar>
            </Stack>
        </div>

    )

}