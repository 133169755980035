import { Fragment, useEffect, useState } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box } from "@mui/material";

const MetaData = (props: any) => {

  const { data } = props;
  const [isOpen, setIsOpen] = useState(true);
  const [metaData, setMetaData] = useState<any>(data);

  useEffect(() => {
    setMetaData(data);
  }, [data])

  const onClickMetaHeader = () => {
    setIsOpen(isOpen => !isOpen);
    const metaData = document.getElementById('meta-data')
    const document_body = document.getElementById('document_body');
    if (isOpen) {
      metaData?.classList?.add('closed');
      document_body?.classList.add('closed');
    } else {
      metaData?.classList?.remove('closed');
      document_body?.classList.remove('closed');
    }
  }

  const ExpandableText = (props: { items: string[] }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    return (
      <div>
        {
          props?.items?.length === 1 ? props?.items?.map(item => (
            item
          )) :
            <ul className="expandable-box">
              {
                isExpanded ? props?.items?.map((item, index) => (
                  <li key={index} >{item}</li>
                )) : props?.items?.map((item, index) => {
                  if (index < 3) {
                    return (<li key={index} >{item}</li>);
                  }
                  return null;
                })
              }
            </ul>
        }
      </div>
    )

  }

  return (
    <Fragment>
      {isOpen ?
        <Box sx={{ width: '100%' }}>
          <div id="meta_header" onClick={onClickMetaHeader}>
            Metadata
            {isOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
          </div>
          <div className={`meta-content open`}>
            <table className="meta-info">
              <tbody>
                {metaData?.project &&
                  <>
                    <tr>
                      <td>Project Name</td>
                    </tr>
                    <tr>
                      <td>{metaData?.project}</td>
                    </tr>
                  </>
                }
                {metaData?.docType &&
                  <>
                    <tr>
                      <td>Agreement Type</td>
                    </tr>
                    <tr>
                      <td>{metaData?.docType}</td>
                    </tr>
                  </>
                }
                {metaData?.contractDate &&
                  <>
                    <tr>
                      <td>Signed Date</td>
                    </tr>
                    <tr>
                      <td>{metaData?.contractDate}</td>
                    </tr>
                  </>
                }
                {metaData?.country &&
                  <>
                    <tr>
                      <td>Country</td>
                    </tr>
                    <tr>
                      <td>{metaData?.country}</td>
                    </tr>
                  </>
                }
                {(metaData?.sectors.length !== 0) &&
                  <>
                    <tr>
                      <td>Sector</td>
                    </tr>
                    <tr>
                      <td>{<ExpandableText items={metaData?.sectors} />}</td>
                    </tr>
                  </>
                }
                {(metaData?.subSectors.length !== 0) &&
                  <>
                    <tr>
                      <td>Sub-sector</td>
                    </tr>
                    <tr>
                      <td>{<ExpandableText items={metaData?.subSectors} />}</td>
                    </tr>
                  </>
                }
                {(metaData?.parties.length !== 0) &&
                  <>
                    <tr>
                      <td>Organizations Named</td>
                    </tr>
                    <tr>
                      <td>{<ExpandableText items={metaData?.parties} />}</td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
          </div>
        </Box>
        :
        <div className="pointer expander" onClick={onClickMetaHeader} >
          <KeyboardArrowRightIcon />
        </div>
      }
    </Fragment>
  );
}

export default MetaData;
