import { useState } from "react";
import Box from '@mui/material/Box';

import { EmbeddedPDF } from "../../component/pdfeditor/EmbeddedPdf";
import { apiBasePathSearch } from "../../constants/env-url";
import { PdfPathUrl } from "../../constants/api-urlConstants";
import TermText from "./termText";
import Summary from "./summary";

const tabs = [
  { label: 'PDF', value: 'pdf' },
  { label: 'Text', value: 'text' },
  // { label: 'Summary', value: 'summary' },
];

const TermViewer = (props: any) => {
  const { s3Key: s3KeyProp, metaData } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const s3Key = s3KeyProp || urlParams.get('s3Key');
  const defaultView = urlParams.get('defaultTab');

  const [activetab, setActivetab] = useState(defaultView === 'text' ? tabs[1].value : tabs[0].value);

  const handleChange = (value: string) => {
    setActivetab(value);
    onClickTab(value);
  };

  const onClickTab = (activeTabIndex: string) => {
    const index = 0;
    const ele: any = document.getElementsByClassName('document-content')?.[index];
    if (ele && activeTabIndex === tabs?.[0]?.value) {
      ele.style.display = 'none';
    } else if (ele && activeTabIndex === tabs?.[1]?.value) {
      ele.style.display = 'flex';
    }
  }

  return (
    <div className="term-search-container">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <div className="tabs">
            {tabs?.map((tab, index) =>
              <div className={`tab ${activetab === tab.value ? 'active' : ''}`} key={index} onClick={() => handleChange(tab.value)}>{tab.label}</div>
            )}
          </div>
        </Box>
      </Box>
      {activetab === tabs?.[0]?.value &&
        <div className="navigation-panel">
          <EmbeddedPDF
            docUrl={`${apiBasePathSearch}${PdfPathUrl}?fileName=${s3Key}`}
            viewerHeight="calc(100vh - 165px)"
          />
        </div>
      }
      {activetab === tabs?.[1]?.value &&
        <TermText />
      }
      {activetab === tabs?.[2]?.value &&
        <Summary metaData={metaData} />
      }
    </div>
  );
}

export default TermViewer;
