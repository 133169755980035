import { Fragment, useContext, useEffect, useRef, useState } from "react"
import { Box, Grid, CircularProgress, Button } from "@mui/material";
import Select from 'react-select';
import { Close } from "@mui/icons-material";
import moment from "moment";
import { useDebounce } from "use-debounce";
import { components } from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GridSortModel } from "@mui/x-data-grid";
import { GridRowGroupingModel } from "@mui/x-data-grid-premium";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { LocalizationProvider, PickersShortcutsItem, PickersShortcutsProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from 'dayjs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import { DateRange } from "@mui/x-date-pickers-pro";
import Divider from '@mui/material/Divider';

import { DateFilters, FilterValues } from "../findTerms/model";
import { apiBasePathSearch } from "../../../constants/env-url";
import { agreementDetails, applyUserFilter, deleteUserSearchFilter, documentCount, projectFilter, userFilters } from "../../../constants/api-urlConstants";
import instance from "../../../utils/axiosInstance";
import './style.scss';
import DataTable from "../../../component/datatable/datatable";
import { SnackBarMessage } from "../../../component/snackBar/snackBar";
import { MessageText } from "../../../constants/messageText";
import { Modal } from "../../../component/Modal";
import SaveFilter, { SaveFilterRef } from "./saveFilter";
import { TAB_NAMES } from "../../../utils/constant";
import SavedFilters from "./filters";
import CompsTable from "./CompsTable";
import { Filter, SaveFilterPayload } from "./model";
import { maximumFilterAllowedToSave } from "../../../utils/config";
import { ErrorCodes } from "../../../constants/messageCodes";
import { CommonContextValueStore } from "../../../constants/commonContext";
import { postActivity } from "../trackActivity";

const today = dayjs();
const yesterday = today.subtract(1, 'day');

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Last 15 Days',
    getValue: () => {
      return [today.subtract(15, 'day'), yesterday];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      return [today.subtract(30, 'day'), yesterday];
    },
  },
  {
    label: 'Last 60 Days',
    getValue: () => {
      return [today.subtract(60, 'day'), yesterday];
    },
  },
];

const initialFilterValues: FilterValues[] = [
  {
    displayName: 'Region',
    field: 'region',
    options: []
  },
  {
    displayName: 'Project or Parent Company Agreement',
    field: 'financial_level',
    options: []
  },
  {
    displayName: 'Agreement Name',
    field: 'contract_name',
    options: []
  },
  {
    displayName: 'Country',
    field: 'country',
    options: []
  },
  {
    displayName: 'Project Name',
    field: 'project',
    options: []
  }
];

const initialFilterValuesWithCount = [
  {
    displayName: 'Sector',
    field: 'sector',
    options: [],
    bucketSize: 1000
  },
  {
    displayName: 'Sub-sector',
    field: 'subsector',
    options: [],
    bucketSize: 1000
  },
  {
    displayName: 'Agreement Type',
    field: 'doc_type',
    options: [],
    bucketSize: 1000
  },
  {
    displayName: 'Organizations Named',
    field: 'party',
    options: [],
    bucketSize: 10000
  },
  {
    displayName: 'Sponsor',
    field: 'sponsor',
    options: [],
    bucketSize: 1000
  },
  {
    displayName: 'Lender',
    field: 'lender',
    options: [],
    bucketSize: 1000
  },
]

const AgreementTable = () => {

  const columns = [
    {
      field: "contract",
      headerName: "Name",
      flex: 1,
      hideable: false,
      searchable: true,
      groupable: false,
    },
    {
      field: "docType",
      headerName: "Type",
      flex: 1,
      searchable: true,
      groupable: true,
    },
    {
      field: "project",
      headerName: "Project Name",
      flex: 1,
      searchable: true,
      groupable: true,
    },
    {
      field: "sectors",
      headerName: "Sector",
      flex: 1,
      searchable: true,
      groupable: true,
      renderCell: (params: any) => {
        return (
          <div>{params?.formattedValue ? params?.row?.sectors?.join(' | ') || params?.rowNode?.groupingKey : ''}</div>
        );
      },
    },
    {
      field: "subSectors",
      headerName: "Sub-sector",
      flex: 1,
      searchable: true,
      groupable: true,
      renderCell: (params: any) => {
        return (
          <div>{params?.formattedValue ? params?.row?.subSectors?.join(' | ') || params?.rowNode?.groupingKey : ''}</div>
        );
      },
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      searchable: true,
      groupable: true,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      searchable: true,
      groupable: true,
    },
    {
      field: "contractDate",
      headerName: "Signed Date",
      flex: 1,
      searchable: false,
      groupable: false,
      renderCell: (params: any) => {
        return (
          <div>{params?.formattedValue ? moment(params?.row?.contractDate, 'YYYY-MM-DD').format('ll') || '' : ''}</div>
        );
      },
    },
    {
      field: "datePublished",
      headerName: "Date Added",
      flex: 1,
      searchable: false,
      groupable: false,
      renderCell: (params: any) => {
        return (
          <div>{params?.formattedValue ? moment(params?.row?.datePublished, 'YYYY-MM-DD').format('ll') || '' : ''}</div>
        );
      },
    },
    {
      field: "projectId",
      headerName: "Term View",
			flex: 0,
			width: 90,
      sortable: false,
      searchable: false,
      groupable: false,
      renderCell: (params: any) => {
        if (params?.row?.hasTerms) {
          const url = `${window.location.origin}/dashboard/term?doc_id=${params.row.docId}&contractName=${params.row.contract}`;
          return (
            <a href={url}>Find Terms</a>
          );
        } else {
          return '';
        }
      },
    },
    {
      field: "bucketId",
      headerName: "View PDF",
			flex: 0,
			width: 90,
      hideable: false,
      sortable: false,
      searchable: false,
      groupable: false,
      renderCell: (params: any) => {
        if (params?.formattedValue) {
          const fileName = `${params.row.bucketId}indexed_document.pdf`;
          const path = `/project-details?agreementId=${fileName}`;
          const url = `${window.location.origin}${path}`;
          return (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                const payload = {
                  fileName: fileName,
                  contractName: params?.row?.contract || '',
                  contractDate: params?.row?.contractDate || '',
                  contractTypeName: params?.row?.docType || '',
                  financialLevel: params?.row?.financialLevel || '',
                  projectName: params?.row?.project || '',
                  country: params?.row?.country || '',
                  sectors: params?.row?.sectors.join(', ') || '',
                  subSectors: params?.row?.subSectors.join(', ') || '',
                  fileUrl: path
                };
                postActivity(payload);
              }}
            >
              Open
            </a>
          );
        } else {
          return '';
        }
      },
    },
  ];

  const [order, setOrder] = useState<any>({});
  const [filters, setFilters] = useState<any>({});
  const [appliedDashboardFilters, setAppliedDashboardFilters] = useState<any>([]);
  const [filterOptions, setFilterOptions] = useState<any>({
    'financial_level': [
      {
        "filterKey": "Parent Company",
        "filterValue": "Parent Company",
        "filterCount": null
      },
      {
        "filterKey": "Project",
        "filterValue": "Project",
        "filterCount": null
      }
    ]
  });
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 20,
    sortDirection: 'ASC',
    sortField: 'project_id'
  });
  const { setSnackabrConfig } = useContext(CommonContextValueStore);
	const saveFilterRef = useRef<SaveFilterRef>(null);
  const [inputSearchText, setInputSearchText] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [debouncedText] = useDebounce(inputSearchText, 1000);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [aggregationBucketPage, setAggregationBucketPage] = useState<any>({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [rowGroupingModel, setRowGroupingModel] = useState<GridRowGroupingModel>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<any>([null, null]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<any>({
    datePublished: false,
    projectId: false
  });
  const [msgConfig, setMsgConfig] = useState({
    type: '',
    message: '',
    snackFlag: false
  });
  const [showSaveFilterModel, setShowSaveFilterModel] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [showSavedFilters, setShowSavedFilters] = useState(false);
  const [showSaveFilter, setShowSaveFilter] = useState(false);

  useEffect(() => {
    const field = Object.keys(debouncedText)?.[0];
    const value = debouncedText?.[field];
    if (field && value) {
      updateSingleFieldFilterOptionsOnSearch(field, value);
    }
  }, [debouncedText]);

  useEffect(() => {
    const queryFilters = createFilterPayload();
		fetchTermDetails(queryFilters);
		fetchTermCounts(queryFilters);
		fetchTableData(pageState.page, pageState.pageSize, queryFilters, pageState.sortField, pageState.sortDirection);
    fetchSavedFilters();
	}, []);

  const fetchSavedFilters = () => {
    const filterAPI = `${apiBasePathSearch}${userFilters}?filterPage=${TAB_NAMES.AGREEMENT}`;
    instance.get(filterAPI).then((res: any) => {
      const data = res.data || [];
      setSavedFilters(data);
    });
  }

  const fetchFilterBasedOnId = (filterId: string) => {
    const filterAPI = `${apiBasePathSearch}${applyUserFilter}?filterId=${filterId}`;
    instance.post(filterAPI).then((res: any) => {
      const data = res.data;
      const userFilter = JSON.parse(data.userFilter);
      const fromDate = userFilter?.startDateFilter || '';
      const toDate = userFilter?.endDateFilter || '';
      if (fromDate && toDate) {
        const dateRange = [dayjs(fromDate), dayjs(toDate)]
        setSelectedDateRange(dateRange);
      }
      setAppliedDashboardFilters(userFilter.dashboardFilters);

      fetchTermCounts(userFilter.dashboardFilters, { fromDate, toDate });
      fetchTableData((userFilter.page + 1), userFilter.size, userFilter.dashboardFilters, userFilter.sortField, userFilter.sortDirection, { fromDate, toDate });
      let filters: any = {};
      userFilter?.dashboardFilters?.map((df: any) => {
        filters[df?.field] = df.filterCriterias?.map((x: any) => ({ filterKey: x, filterValue: x }));
      });
      setFilters(filters);
    });
  }

  // fetch table data
  const fetchTableData = (page: number, pageSize: number, dashboardFilters = [], sortField: string = pageState.sortField, sortDirection = pageState.sortDirection, dateFilters: DateFilters = { fromDate: '', toDate: '' }) => {
    const payload = {
      page: page,
      size: pageSize,
      sortField,
      sortDirection,
      dashboardFilters: dashboardFilters,
      startDateFilter: dateFilters?.fromDate ? dateFilters?.fromDate : "",
      endDateFilter: dateFilters?.toDate ? dateFilters?.toDate : "",
      rangeStatus: true
    }
    fetchData(payload);
  }

  const createFilterPayload = () => {
    const country = searchParams.get('country');
    const project_name = searchParams.get('project_name');

    const queryParamFilters: any[] = [];
    country && addFilters(queryParamFilters, 'country', country);
    project_name && addFilters(queryParamFilters, 'project', project_name);

    const previousFilter = JSON.parse(JSON.stringify(filters));
		const updatedFilter: any = {};
		project_name && (updatedFilter.project = [{
			"filterKey": project_name,
			"filterValue": project_name,
		}]);
		country && (updatedFilter.country = [{
			"filterKey": country,
			"filterValue": country,
		}]);

		if (updatedFilter?.project || updatedFilter?.country) {
			const newFilters = {
				...previousFilter,
				...updatedFilter
			};
			setFilters(newFilters);
		}

    setAppliedDashboardFilters(queryParamFilters);
    return queryParamFilters as [];
  }

  const addFilters = (dashboardFilters: any[], field: string, value: string) => {
    dashboardFilters.push({
      field: field,
      filterCriterias: value.split(','),
      elasticCriteria: "INCLUDE"
    })
  }

  const fetchData = (payload: any) => {
    const filterAPI = `${apiBasePathSearch}${agreementDetails}`;
    instance.post(filterAPI, payload).then((res: any) => {
      const data = res?.data?.data?.dataList?.map((v: any, i: number) => ({ ...v, id: i + 1 })) || [];
      setPageState((old) => ({
        ...old,
        page: payload.page,
        pageSize: payload.size,
        sortDirection: payload.sortDirection,
        sortField: payload.sortField,
        data: data
      }));
    }).catch((error) => {
      setMsgConfig({
        type: 'error',
        message: error?.response?.data?.message_code || MessageText.INVALID_SEARCH_RESULTS,
        snackFlag: true
      });
    });
  }

  // fetch total count
  const fetchTermCounts = (dashboardFilters = [], dateFilters: DateFilters = { fromDate: '', toDate: '' }) => {
    const payload = {
      dashboardFilters: dashboardFilters,
      documentSearchPage: TAB_NAMES.AGREEMENT,
      startDateFilter: dateFilters?.fromDate ? dateFilters?.fromDate : "",
      endDateFilter: dateFilters?.toDate ? dateFilters?.toDate : "",
      rangeStatus: true
    }
    const filterAPI = `${apiBasePathSearch}${documentCount}`;
    instance.post(filterAPI, payload).then((res: any) => {
      setPageState(old => ({ ...old, total: res?.data?.documentCount }));
    }).catch(() => {});
  }

  const fetchTermDetails = (dashboardFilters = []) => {
    
    const filterAPI = `${apiBasePathSearch}${projectFilter}`;
    [...initialFilterValues, ...initialFilterValuesWithCount].map(filter => {
      const payload: any = {
        field: filter.field,
        documentSearchPage: TAB_NAMES.AGREEMENT,
        dashboardFilters: dashboardFilters,
        aggregationBucketPage: 0,
        bucketSort: 'DOC_COUNT',
        sortOrder: "Desc"
      };
      if (filter.bucketSize) {
        payload.bucketSize = filter.bucketSize;
      }
      (filter.field) && (filter?.field !== 'financial_level') && instance.post(filterAPI, payload).then((res: any) => {
        const data = res.data;
        setFilterOptions((old: any) => ({
          ...old,
          [filter.field]: data?.filterDetailsList,
        }));
      }).catch(() => {});
    })
  }

  const getLabelForField = (field: string) => {
    return [...initialFilterValues, ...initialFilterValuesWithCount].find(filter => filter.field === field)?.displayName || field;
  }

  const onScrollEnd = (field: string) => {
    if ((aggregationBucketPage?.[field]?.currentCount < aggregationBucketPage?.[field]?.totalAvailable) || !aggregationBucketPage?.[field]?.currentCount) {
      const newCount = (aggregationBucketPage?.[field]?.currentCount || 0) + 20;
      fetchFilterOptions({ field: field }, appliedDashboardFilters, newCount, 'DOC_COUNT', 'DESC', true, true);
    }
  }

  const onChangeFilter = (field: string, value: any, item: any) => {
    const previousFilter = JSON.parse(JSON.stringify(filters));
    const newFilters = {
      ...previousFilter,
      [field]: value
    };
    setFilters(newFilters);
    const unFilteredFields = [...initialFilterValues, ...initialFilterValuesWithCount].filter((key) => !newFilters?.[key?.field] || (!Object.keys(newFilters).includes(key.field) && newFilters?.[key?.field]));
    const dashboardFilters: any = Object.keys(newFilters).filter((key) => {
      return newFilters?.[key]?.length > 0;
    }).map((key) => {
      const criterias = newFilters?.[key]?.map((m: any) => m?.filterKey) || newFilters?.[key]?.map((m: any) => m?.filterValue) || [];
      return (criterias?.length) && {
        field: key,
        filterCriterias: criterias?.filter((a: any) => a),
        elasticCriteria: "INCLUDE"
      }
    }).filter(filters => filters?.filterCriterias?.length);

    setAppliedDashboardFilters(dashboardFilters);
    updateFilterOptions(unFilteredFields, dashboardFilters, item);

    let existingDateRange: DateFilters = { fromDate: '', toDate: '' };
    if (selectedDateRange?.[0] && selectedDateRange?.[1]) {
      existingDateRange.fromDate = dayjs(selectedDateRange?.[0]).format('YYYY-MM-DD');
      existingDateRange.toDate = dayjs(selectedDateRange?.[1]).format('YYYY-MM-DD');
    }
    fetchTermCounts(dashboardFilters, existingDateRange);
    fetchTableData(pageState.page, pageState.pageSize, dashboardFilters, pageState.sortField, pageState.sortDirection, existingDateRange);
  }

  const updateFilterOptions = (unFilteredFields: any[], dashboardFilters: any[], item: any) => {
    if (item) {
      unFilteredFields.map(filter => {
        if (filter.field) {
          if (filter?.bucketSize) {
						fetchFilterOptions(filter, dashboardFilters, 0, "DOC_COUNT", "DESC", false, false);
					} else {
						fetchFilterOptions(filter, dashboardFilters, 0, "DOC_COUNT", "DESC", true, false);
					}
        }
      })
    } else {
      unFilteredFields.map(filter => {
        if (filter?.bucketSize) {
          fetchFilterOptions(filter, dashboardFilters, 0, "DOC_COUNT", "DESC", false, false);
        } else {
          fetchFilterOptions(filter, dashboardFilters, 0, "DOC_COUNT", "DESC", true, false);
        }
      })
    }
  }
  
  const fetchFilterOptions = (filter: any, dashboardFilters: any, aggregationBucketPage: number = 0, bucketSort = "DOC_COUNT", sortOrder = "DESC", filterValue = true, isOnScroll = false) => {
    const filterAPI = `${apiBasePathSearch}${projectFilter}`;
    const payload = {
      field: filter.field,
      dashboardFilters: dashboardFilters,
      documentSearchPage: TAB_NAMES.AGREEMENT,
			bucketSize : filterValue ? 20 : filter.field === 'party' ? 10000 : 1000,
      aggregationBucketPage,
      bucketSort,
      sortOrder
    };
    (filter.field) && (filter.field !== 'financial_level') && instance.post(filterAPI, payload).then((res: any) => {
      const data = res.data;
      filterValue ? setFilterOptions((old: any) => ({
        ...old,
        [filter.field]: [
          ...(isOnScroll ? (old?.[filter?.field] || []) : []),
          ...data?.filterDetailsList
        ],
      })) : setFilterOptions((old: any) => ({
        ...old,
        [filter.field]: [
          ...data?.filterDetailsList
        ],
      }));
      setAggregationBucketPage((old: any) => ({
        ...old,
        [filter.field]: {
          totalAvailable: data?.cardinalCount,
          currentCount: aggregationBucketPage
        }
      }))
    }).catch(() => {});
  }

  const onClickRemoveFilter = (field: string) => {
    onChangeFilter(field, null, field === 'project' ? null : field);
    const country = searchParams.get('country');
		const projectName = searchParams.get('project_name');

		if (country || projectName) {
			navigate('/dashboard/agreement');			
		}
  }

  const updateSingleFieldFilterOptionsOnSearch = (field: string, value: string) => {
    setIsLoading(true);
    const filterAPI = `${apiBasePathSearch}${projectFilter}`;
    const payload = {
      field: field,
      wildCardFilterStatus: true,
      wildCardFilterCriteria: value,
      documentSearchPage: TAB_NAMES.AGREEMENT,
      aggregationBucketPage: 0,
      bucketSort: 'DOC_COUNT',
      sortOrder: "Desc",
      bucketSize : field === 'party' ? 10000 : 1000,
			dashboardFilters : appliedDashboardFilters,
    };
    instance.post(filterAPI, payload).then((res: any) => {
      const data = res.data;
      setFilterOptions((old: any) => ({
        ...old,
        [field]: data?.filterDetailsList,
      }));
    }).catch(() => {}).finally(() => {
      setIsLoading(false);
    });
  }

  const onSortModelChange = (currentSortModel: GridSortModel) => {
    setSortModel(currentSortModel);
    const field = currentSortModel?.[0]?.field;
    const sort = currentSortModel?.[0]?.sort;
    const fromDate = selectedDateRange?.[0] && dayjs(selectedDateRange?.[0]).format('YYYY-MM-DD') || '';
    const toDate = selectedDateRange?.[1] && dayjs(selectedDateRange?.[1]).format('YYYY-MM-DD') || '';
    const fields: any = {
      contract: "contract_name",
      docType: "doc_type",
      project: "project",
      sectors: "sector",
      subSectors: "subsector",
      region: "region",
      country: "country",
      contractDate: "contract_date"
    };
    if (field && sort) {
      fetchTableData(pageState.page, pageState.pageSize, appliedDashboardFilters, fields?.[field], (sort)?.toUpperCase(), { fromDate: fromDate, toDate: toDate });
    }
  }

  const Option = (props: any) => {
    const isMulti = props?.selectProps?.isMulti || false;
		return (
			<div>
				<components.Option {...props}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}>
            <input
              type={isMulti ? "checkbox" : "radio"}
              defaultChecked={props.isSelected}
              className="select-checkbox"
            />
            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ overflowWrap: 'break-word', maxWidth: '75%' }}>{props.label}</Box>
              <span>{(props?.data?.filterCount)?.toLocaleString()}</span>
            </Box>
          </Box>
				</components.Option>
			</div>
		);
	};

  const handleSort = (event: any, initialFilterValue: any, bucketSort: string) => {
		event.stopPropagation();
		const newOrder = (order?.[initialFilterValue.field]?.order === 'ASC' ? 'DESC' : 'ASC');
		setOrder((old: any) => ({...old, [initialFilterValue.field]: { key: bucketSort, order: newOrder } }));
		fetchFilterOptions(initialFilterValue, appliedDashboardFilters, 0, bucketSort, newOrder, false, false);
	}

  const onClickClearAllFilter = () => {
    setFilters({});
    setAppliedDashboardFilters([]);
    fetchTermDetails([]);
    fetchTermCounts([]);
    setSelectedDateRange([null, null]);
    fetchTableData(pageState.page, pageState.pageSize, []);
  }

  const onClickSaveFilter = () => {
    setShowSaveFilterModel(true);
  }

  const onClickClearAllTableFilter = () => {
    setRowGroupingModel([]); // reset row grouping
    setSortModel([]); // reset sorting
    
    fetchTermCounts([]);
    fetchTableData(pageState.page, pageState.pageSize, appliedDashboardFilters, 'project_id', 'ASC');
  }
  const handleClearSearch = (field: string) => {
		setTimeout(() => {
			updateSingleFieldFilterOptionsOnSearch(field, '');
		}, 1100);
	}

  const handleFilterOptionsClick = (field: string, item: any) => {
    setShowSaveFilter(true);
    if (!filters[field]) {
      onChangeFilter(field, [item], item);
    } else if (filters[field] && !filters[field]?.includes(item, 0)) {
      const newFilter = JSON.parse(JSON.stringify(filters));
			newFilter?.[field]?.push?.(item);
			onChangeFilter(field, newFilter?.[field], item);
    };
  }

  const onClickClearDateRange = () => {
    setSelectedDateRange([null, null]);
    fetchTableData(pageState.page, pageState.pageSize, appliedDashboardFilters, 'project_id', 'ASC');
    fetchTermCounts(appliedDashboardFilters);
  }

  const CustomRangeShortcuts = (props: PickersShortcutsProps<DateRange<Dayjs>>) => {
    const { items, onChange, isValid, changeImportance = 'accept' } = props;
  
    if (items == null || items.length === 0) {
      return null;
    }
  
    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid });
  
      return {
        label: item.label,
        onClick: () => {
          onChange(newValue, changeImportance, item);
        },
        disabled: !isValid(newValue),
      };
    });
  
    return (
      <Box
        sx={{
          gridRow: 1,
          gridColumn: 2,
        }}
      >
        <List
          dense
          sx={(theme) => ({
            display: 'flex',
            px: theme.spacing(4),
            '& .MuiListItem-root': {
              pt: 0,
              pl: 0,
              pr: theme.spacing(1),
            },
          })}
        >
          {resolvedItems.map((item) => {
            return (
              <ListItem key={item.label} sx={{ width: 'unset' }}>
                <Chip {...item} sx={{ backgroundColor: '#eef6ff', ":hover": { backgroundColor: '#1976d2', color: '#ffffff' } }} />
              </ListItem>
            );
          })}
        </List>
        <Divider />
      </Box>
    );
  }

  const closeSaveFilterPopup = () => {
    setShowSaveFilterModel(false);
  }

  const onSaveFilter = (values: any) => {
    const fromDate = selectedDateRange?.[0] && dayjs(selectedDateRange?.[0]).format('YYYY-MM-DD') || '';
    const toDate = selectedDateRange?.[1] && dayjs(selectedDateRange?.[1]).format('YYYY-MM-DD') || '';
    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      sortField: pageState.sortField,
      sortDirection: pageState.sortDirection,
      dashboardFilters: appliedDashboardFilters,
      userFilterName: values.filterName,
      startDateFilter: fromDate,
      endDateFilter: toDate,
    }
    saveOrUpdateFilter(payload);
  }

  const onUpdateFilter = (filter: Filter) => {
    const payload = filter.userFilter ? JSON.parse(filter.userFilter) : {};
    payload.userFilterId = filter.id;
    payload.userFilterName = filter.name;
    saveOrUpdateFilter(payload);
  }

  const onReplaceFilter = (filter: { id: number, name: string }) => {
    const fromDate = selectedDateRange?.[0] && dayjs(selectedDateRange?.[0]).format('YYYY-MM-DD') || '';
    const toDate = selectedDateRange?.[1] && dayjs(selectedDateRange?.[1]).format('YYYY-MM-DD') || '';
    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      sortField: pageState.sortField,
      sortDirection: pageState.sortDirection,
      dashboardFilters: appliedDashboardFilters,
      userFilterName: filter.name,
      userFilterId: filter.id,
      startDateFilter: fromDate,
      endDateFilter: toDate,
    }
    saveOrUpdateFilter(payload);
  }

  const saveOrUpdateFilter = (payload: SaveFilterPayload) => {
    const filterAPI = `${apiBasePathSearch}${agreementDetails}`;
    instance.post(filterAPI, payload).then(() => {
      closeSaveFilterPopup();
      fetchSavedFilters();
    }).catch(error => {
			if (error?.response?.data?.message_code === ErrorCodes.FILTER_NAME_NOT_UNIQUE) {
				setSnackabrConfig({
					type: 'error',
					message: MessageText.FILTER_NAME_NOT_UNIQUE,
					snackFlag: true
				});
				saveFilterRef?.current?.resetLoading();
			}
    }).finally(() => {
      setShowSaveFilter(false);
    });
  }

  const onDeleteFilter = (filter: Filter) => {
    const filterAPI = `${apiBasePathSearch}${deleteUserSearchFilter}?filterId=${filter.id}&filterPage=${TAB_NAMES.AGREEMENT}`;
    instance.post(filterAPI).then(() => {
      fetchSavedFilters();
    });
  }

  return (
    <Fragment>
      <div className="filter-wrapper">
        <Box sx={{ width: '100%' }}>
          {((appliedDashboardFilters?.length > 0) || (selectedDateRange?.filter((a: any) => a)?.length > 1)) &&
            <div className="filters">
              {appliedDashboardFilters?.map((f: any, index: number) => (
                <div key={index} className="filter-value">
                  <span><span className="font-bold">{getLabelForField(f.field)}</span>: {f.filterCriterias.join(' | ')}</span> <Close onClick={() => { onClickRemoveFilter(f.field) }} />
                </div>
              ))}
              <>
                {selectedDateRange?.length > 1 && selectedDateRange?.[0] && selectedDateRange?.[1] && (
                  <div className="filter-value">
                    <span><span className="font-bold">Date Added</span>: {dayjs(selectedDateRange?.[0]).format('MM/DD/YYYY')} - {dayjs(selectedDateRange?.[1]).format('MM/DD/YYYY')}</span>
                    <Close onClick={() => { onClickClearDateRange(); }} />
                  </div>
                )}
              </>
            </div>
          }
        </Box>
        
        <Box sx={{ display: 'flex', gap: '10px' }}>
          {((appliedDashboardFilters?.length > 0) || (selectedDateRange?.filter((a: any) => a)?.length > 1)) &&
            <Fragment>
              {showSaveFilter &&
                <Button
                  onClick={() => onClickSaveFilter()}
                  variant="contained"
                >
                  Save
                </Button>
              }
              <Button
                onClick={() => onClickClearAllFilter()}
                variant="outlined"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Clear All
              </Button>
            </Fragment>
          }
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => savedFilters?.length ? setShowSavedFilters(true) : {}}
            variant="contained"
          >
            Saved Filters {savedFilters?.length ? `(${savedFilters?.length})` : '(0)'}
          </Button>
        </Box>
      </div>
      <section className="term-dashboard">
        <Box sx={{ display: 'flex', gap: '15px' }}>
					<Box sx={{ width: 'calc(100% - 150px)' }}>
          <Grid container spacing={2}>
            {initialFilterValues.map((filter, index) => {
              const searchingField = Object.keys(inputSearchText)?.[0];
              return (
                <Grid item sm={6} lg={4} key={index} sx={{ width: '100%' }}>
                  <label className="label">{filter?.displayName}</label>
                  <Select
                    isMulti={filter.field !== 'financial_level'}
                    closeMenuOnSelect= {false}
                    hideSelectedOptions= {false}
                    isLoading={searchingField === filter?.field && isLoading}
                    components={{
                      LoadingIndicator: () => <CircularProgress size={14} sx={{ marginRight: '8px' }} />,
                      Option
                    }}
                    placeholder={`Select ${filter?.displayName?.toLowerCase()}`}
                    options={filterOptions?.[filter?.field] || []}
                    value={filters?.[filter?.field] || null}
                    isClearable
                    onChange={(value) => {
                      const filterValue = filter.field === 'financial_level' ? [value] : value?.length ? value : null;
                      onChangeFilter(filter.field, filterValue, filter.field === 'project' ? null : filter);
                      setShowSaveFilter(true);
                    }}
                    onInputChange={(value, actionMeta) => {
                      if (value) {
                        setInputSearchText({ [filter.field]: value });
                      } else if (!value && actionMeta?.action === "input-change") {
                        setTimeout(() => {
                          updateSingleFieldFilterOptionsOnSearch(filter.field, value);
                        }, 1000);
                      }
                    }}
                    onMenuScrollToBottom={() => {
                      onScrollEnd(filter?.field);
                    }}
                    styles={{
                      // @ts-ignore
                      container: (provided: MediaProvider) => ({
                        ...provided,
                        marginTop: '5px',
                      }),
                      control: (provided: any) => ({
                        ...provided,
                        cursor: 'pointer'
                      }),
                      input: (provided: any) => ({
                        ...provided,
                        cursor: 'text'
                      }),
                      // @ts-ignore
                      option: (provided: MediaProvider, state: any) => ({
                        ...provided,
                        color: "#000000",
                        backgroundColor: "#ffffff",
                        "&:hover": { backgroundColor: "#1976d2", color: "#ffffff" },
                      }),
                      menu: (baseStyles) => ({ ...baseStyles, zIndex: 10 })
                    }}
                    getOptionLabel={(option: any) => option.filterKey || option.filterValue || ""}
                    getOptionValue={(option: any) => option.filterKey || option.filterValue || ""}
                  />
                </Grid>
              )
            })}
            <Grid item sm={6} lg={4} sx={{ width: '100%' }}>
              <label className="label">Date Added</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  sx={{ marginTop: '5px', width: '100%' }}
                  slots={{
                    field: SingleInputDateRangeField,
                    shortcuts: CustomRangeShortcuts,
                  }}
                  slotProps={{
                    shortcuts: {
                      items: shortcutsItems,
                    },
                    actionBar: { actions: [] },
                  }}
                  name="allowedRange"
                  className="custom-date-range-picker"
                  value={selectedDateRange}
                  onChange={(dateRange) => {
                    const fromDate = dateRange?.[0] && dayjs(dateRange?.[0]).format('YYYY-MM-DD') || '';
                    const toDate = dateRange?.[1] && dayjs(dateRange?.[1]).format('YYYY-MM-DD') || '';
                    if (fromDate && toDate) {
                      setSelectedDateRange(dateRange);
                      fetchTermCounts(appliedDashboardFilters, { fromDate, toDate });
                      fetchTableData(pageState.page, pageState.pageSize, appliedDashboardFilters, 'contract_date', 'DESC', { fromDate, toDate });
                    }
                  }}
                  maxDate={dayjs()}
                />
              </LocalizationProvider>
            </Grid>            
          </Grid>
          </Box>
          <Box sx={{ width: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<div className={`doc-count`}>
							<Box sx={{ fontSize: 18 }}>{(pageState.total)?.toLocaleString()}</Box>
							<Box sx={{ margin: 1, opacity: '0.7' }}>Agreements</Box>
						</div>
					</Box>
        </Box>

        <CompsTable
          handleSort={handleSort}
          order={order}
          filterOptions={filterOptions}
          updateSingleFieldFilterOptionsOnSearch={updateSingleFieldFilterOptionsOnSearch}
          handleClearSearch={handleClearSearch}
          handleFilterOptionsClick={handleFilterOptionsClick}
          compsFilters={initialFilterValuesWithCount}
        />
        
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => onClickClearAllTableFilter()}
            variant="contained"
            disabled={!rowGroupingModel.length && !sortModel.length}
          >Reset</Button>
        </Box>

        <DataTable
          // @ts-ignore
          disableColumnFilter
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel: any) => setColumnVisibilityModel(newModel)}
          dataGridColumn={columns}
          tableConfig={pageState}
          onPageChangeCallback={(pageValue: any) => {
            const page = pageValue + 1;
            let existingDateRange: DateFilters = { fromDate: '', toDate: '' };
            if (selectedDateRange?.[0] && selectedDateRange?.[1]) {
              existingDateRange.fromDate = dayjs(selectedDateRange?.[0]).format('YYYY-MM-DD');
              existingDateRange.toDate = dayjs(selectedDateRange?.[1]).format('YYYY-MM-DD');
            }
            fetchTableData(page, pageState.pageSize, appliedDashboardFilters, pageState.sortField, pageState.sortDirection, existingDateRange);
          }}
          onPageSizeCallback={(pageSizeValue: any) => {
            let existingDateRange: DateFilters = { fromDate: '', toDate: '' };
            if (selectedDateRange?.[0] && selectedDateRange?.[1]) {
              existingDateRange.fromDate = dayjs(selectedDateRange?.[0]).format('YYYY-MM-DD');
              existingDateRange.toDate = dayjs(selectedDateRange?.[1]).format('YYYY-MM-DD');
            }
            fetchTableData(pageState.page, pageSizeValue, appliedDashboardFilters, pageState.sortField, pageState.sortDirection, existingDateRange);
          }}
          handleClick='projectDetails'
          navigateTab={undefined}
          rowGroupingModel={rowGroupingModel}
          onRowGroupingModelChange={(model: any) => {
            setRowGroupingModel(model)
          }}
          rowHeight={45}
          // @ts-ignore
          getDetailPanelContent={({ row }) => (
            <Box sx={{ p: 2 }}>
              <div className="label">Organizations Named</div>
              <div>{row.parties?.join(' | ')}</div>
              
              {row?.summary &&
                <Box sx={{ marginTop: 2 }}>
                  <div className="label">Summary</div>
                  <div>{row?.summary}</div>
                </Box>
              }
            </Box>
          )}
          getDetailPanelHeight={() => 'auto'}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          showFooterMessage={pageState?.total > 10000}
        />
      </section >
      {
        msgConfig.snackFlag &&
        <div>
          <SnackBarMessage snacBarValue={msgConfig} />
        </div>
      }
      <Modal
        title={(savedFilters.length < maximumFilterAllowedToSave) ? 'Save Filter' : "Replace Existing Filter"}
        open={showSaveFilterModel}
        onClose={() => closeSaveFilterPopup()}
        bodyClassName="hide-body"
      >
        <SaveFilter
          ref={saveFilterRef}
          onSaveFilter={onSaveFilter}
          onReplaceFilter={onReplaceFilter}
          onCancel={() => closeSaveFilterPopup()}
          savedFilters={savedFilters}
        />
      </Modal>
      <Modal
        title="Saved Filters"
        open={showSavedFilters}
        onClose={() => setShowSavedFilters(false)}
      >
        <SavedFilters
          savedFilters={savedFilters}
          onCancel={() => setShowSavedFilters(false)}
          onSelectFilter={(filter) => {
            setShowSavedFilters(false);
            fetchFilterBasedOnId(filter.id);
            setShowSaveFilter(false);
          }}
          onUpdateFilter={onUpdateFilter}
          onConfirmDelete={onDeleteFilter}
        />
      </Modal>
    </Fragment >
  );
}

export default AgreementTable;
