/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/


import Cookies from "js-cookie";
import moment from "moment";
import { createContext, ReactNode, useState } from "react";
import { InvalidateToken } from "./api-urlConstants";
import { accessMangerBasePath, apiBasePathAuth } from "./env-url";
import instance from "../utils/axiosInstance";


interface ProviderProps {
    children: ReactNode
  }
  
export const CommonContextValueStore =createContext<any>([]);

export const CommonContextValueProvider = (props: ProviderProps): JSX.Element => {
    const { children } = props;
    const [openModal, setOpenModal] = useState(false);
    const [closePopupConfirmation, setClosPopupeConfirmation] = useState(false); 
    const [isGuestUser, setIsGuestUser] = useState<any>(Cookies.get("guest-auth"));
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userHeartBeat, setUserHeartBeat] = useState<any>();
    const [trigerUser, setTrigerUser] = useState(false);
    const [snackabrConfig, setSnackabrConfig ] = useState({
      type: 'error',
      message: '',
      snackFlag: false
    });

    const InvalidateTokens = () => {
      const USER_API_BASE_URL = `${apiBasePathAuth}${InvalidateToken}`;
      instance
        .get(USER_API_BASE_URL)
        .then((response) => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = `${accessMangerBasePath}/login`;
        })
        .catch((error) => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = `${accessMangerBasePath}/login`;
        });
    };
  

    const timeExit = () => {
      if(!isGuestUser){
        const timeCookie: any = Cookies.get("ic-token-validity");
        const expireTimestamp = moment(timeCookie).unix();
        const currentTimeStamp = Math.floor((new Date()).getTime() / 1000)
        const diffValue = expireTimestamp - currentTimeStamp;
        let timerValue:any
        if (isNaN(diffValue)){
          timerValue = 0;
          InvalidateTokens();
        }else if(diffValue < 0 ){
          timerValue = 0;
          InvalidateTokens();
        }else{
          timerValue =diffValue
        }
        return diffValue;
      }
    };
  

    return(

        <CommonContextValueStore.Provider value={{trigerUser,setOpenModal,isGuestUser, setIsGuestUser,openModal, setTrigerUser,snackabrConfig, setSnackabrConfig,userHeartBeat, setUserHeartBeat ,timeExit,setClosPopupeConfirmation,closePopupConfirmation,setShowConfirmation,showConfirmation,InvalidateTokens}}>
                  {children}
        </CommonContextValueStore.Provider>

    )
}
