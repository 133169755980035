import { AccordionSummary, AccordionSummaryProps, styled } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
  ))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
        margin: '5px 0 !important',
    },
  }
));

export default CustomAccordionSummary;
