import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import { useEffect, useLayoutEffect, useState } from "react";

interface TreeMapProps {
  data: any[],
  onClickData?: (key: any) => void;
}

const TreeMap = (props: TreeMapProps) => {

  const { data, onClickData } = props;
  const [ series, setSeries ] = useState<any>();

  useLayoutEffect(() => {
    const root = am5.Root.new("chart");
    if (root) {
      let zoomableContainer = root.container.children.push(
        am5.Container.new(root, { // ZoomableContainer
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout,
          // pinchZoom: true
        })
      );
      
      // let zoomTools = zoomableContainer.children.push(am5.ZoomTools.new(root, {
      //   target: zoomableContainer
      // }));

      let series = zoomableContainer.children.push( // zoomableContainer.contents.children
        am5hierarchy.Treemap.new(root, {
          singleBranchOnly: false,
          downDepth: 1,
          upDepth: -1,
          initialDepth: 2,
          valueField: "filterCount",
          categoryField: "filterKey",
          childDataField: "children",
          nodePaddingOuter: 0,
          nodePaddingInner: 0,
          maskContent: false,
          sort: "descending",
          layoutAlgorithm: "binary"
        })
      );
      setSeries(series);

      series.rectangles.template.setAll({
        strokeWidth: 2
      });

      series.nodes?.template?.events?.on("click", (ev) => {
        onClickData?.(ev?.target?.dataItem?.dataContext);
      });

      series.set("selectedDataItem", series.dataItems[0]);
    }
  }, []);

  useEffect(() => {
    series?.data?.setAll(data);
  }, [data])

  return (
    <div id="chart" style={{ width: '100%', height: '400px' }}></div>
  );
}

export default TreeMap;
