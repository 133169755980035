import Cookies from "js-cookie";

export const STORAGE = {
  RELEASE_INFO_SEEN: 'release-info-seen',
  RELEASE_BANNER_SEEN_DATES: 'release-banner-seen-dates',
  BANNER_CLOSED_BY_MANUALLY: 'banner-closed-by-manually'
};

export const setItem = (key: string, value: string) => {
  Cookies.set(key, value);
}

export const getItem = (key: string) => {
  return Cookies.get(key);
}

export const sector_color_codes: any = {
  'Power Storage': "#49D3FE",
  'Utilities': "#F4900A",
  'Power Generation': "#50A965",
  'Power T&D': "#E9DA28",
  'Oil and Gas': "#9f744c",
  'Social, Health, and Defense': "#00ACA1",
  'Transportation': "#D11A1A",
  'Mining': "#968F7D",
  'Municipal Infrastructure': "#003CDC",
  'Agriculture': "#7D2A2A",
  'Telecoms': "#f46a9b",
  'Other': "#000404",
  'Industrial': "#BB80BC",
  'Forestry': "#035E24",
  'Hydrogen and Ammonia': "#f7dfeb",
  'Real Estate': "#e6e1d3",
};

export const TAB_NAMES = {
  AGREEMENT: 'AGREEMENT',
  MODEL: 'MODEL',
  TERMS: 'TERMS'
};