import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './announcement.scss';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Divider, IconButton } from '@mui/material';
import { Transiton } from '../pdfView/pdfview';
import { useState } from 'react';
import { EmbeddedPDF } from '../pdfeditor/EmbeddedPdf';

interface AnnouncementBannerProps {
  onClose?: () => void;
  releaseNotesPDFUrl: string;
  showAnnouncement: boolean;
}
const AnnouncementBanner = (props: AnnouncementBannerProps) => {
  const { onClose, releaseNotesPDFUrl, showAnnouncement } = props;
  const [openModal, setOpenModal] = useState(showAnnouncement);

  useEffect(() => {
    setOpenModal(showAnnouncement);
  }, [showAnnouncement]);

  const handleClose = () => {
    setOpenModal(false);
  }

  return (
    <>
      <div className='announcement'>
        <div>
          We are pleased to announce our latest release. To review the update <strong className='cursor-pointer'><u onClick={() => setOpenModal(true)}>click here</u></strong>.
        </div>
        <CloseIcon
          onClick={() => onClose?.()}
          className='cursor-pointer'
        />
      </div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        TransitionComponent={Transiton}
        className="announcement-dialogbox"
      >
        <DialogTitle className="dialogTitleStyle flex justify-between">
          <span className="titleText">Release notes</span>
          <IconButton className="closeButton" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider className="divider"/>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText minWidth={550}>
            <EmbeddedPDF docUrl={releaseNotesPDFUrl} collapseBookmarkByDefault />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AnnouncementBanner;