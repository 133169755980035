/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { Backdrop, Chip, CircularProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import DataTable from "../../component/datatable/datatable";
import { SideBar } from "../../component/sidebar/sidebar";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import instance from "../../utils/axiosInstance";
import { Projects } from "../../constants/api-urlConstants";
import { MessageText } from "../../constants/messageText";
import _ from "lodash";
import { CommonContextValueStore } from "../../constants/commonContext";
import React from "react";
import { ErrorCodes } from "../../constants/messageCodes";
import "../agreements/agreements.scss";
import { SearchHeader } from "../../component/searchHeader/searchHeader";

export const ProjectsPage: React.FC<any> = () => {
  const { setSnackabrConfig, setUserHeartBeat, timeExit } = React.useContext(
    CommonContextValueStore
  );

  const [filterApply, setFilterApply] = useState<any>([]);
  const [sidebarToggledChanged, setSidebarToggledChanged] =
    useState<any>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [ShowLoading, setShowLoading] = useState(true);

  const [dataLoading, setDataLoading] = useState(false);
  const [removeFilterApply, setRemoveFilterApply] = useState<any>({
    isChipRemoved: false,
    chipItem: null,
  });
  const [savedFilterApply, setSavedFilterApply] = useState<any>({
    isChipAdd: false,
    chipItem: null,
  });
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 50,
    searchParam: "",
  });

  const columns = [
    {
      field: "name",
      headerName: "Project name",
      minWidth: 420,
      flex: 1,
      sortable: false,
      filterable: false,
      className: "StyleColumnsNAmw ",
      renderCell: (params: any) => {
        return (
          <div className="cellWithImg">
            <Tooltip title={params?.row?.name}>
              <div className="contractNameText">{params.row.name}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "sector",
      headerName: "Sector",
      minWidth: 225,
      flex: 1,
      sortable: false,

      filterable: false,
      headerClassName: "StyleColumns",
      renderCell: (params: any) => {
        return (
          <div className="addEllipsis">
            <Tooltip title={params?.row?.sector}>
              <div className="contractNameText">
                {params.row.sector ? params.row.sector : "- -"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "subSector",
      headerName: "Sub-sector",
      minWidth: 225,
      flex: 1,
      sortable: false,
      filterable: false,
      headerClassName: "StyleColumns",
      renderCell: (params: any) => {
        return (
          <div className="addEllipsis">
            <Tooltip title={params?.row?.subSector}>
              <div className="contractNameText">
                {params.row.subSector ? params.row.subSector : "- -"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: "country",
      headerName: "Country",
      minWidth: 200,
      flex: 1,
      sortable: false,
      filterable: false,
      headerClassName: "StyleColumns",
      renderCell: (params: any) => {
        return (
          <div className="addEllipsis">
            <Tooltip title={params?.row?.country}>
              <div className="contractNameText">
                {params.row.country ? params.row.country : "- -"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  //This function is Projects API call
  const postAllProjectList = (
    pageNo: any,
    pageSizeNo: any,
    filtersObj: any,
    searchTextValue: any
  ) => {
    sessionStorage.setItem("pageSize", JSON.stringify(pageSizeNo));
    sessionStorage.setItem("pageNumber", JSON.stringify(pageNo));
    sessionStorage.setItem("searchTextValue", searchTextValue);

    setDataLoading(true);
    const countryFilter: any = [];
    const sectorFilter: any = [];
    const subSectorFilter: any = [];

    filtersObj.map((item: any) => {
      if (item.type == "COUNTRY") {
        countryFilter.push(item.key);
      }

      if (item.type == "SECTOR") {
        sectorFilter.push(item.key);
      }

      if (item.type == "SUB_SECTOR") {
        subSectorFilter.push(item.key);
      }
    });

    const payload = {
      page: pageNo,
      size: pageSizeNo,
      countries: countryFilter,
      sectors: sectorFilter,
      subSectors: subSectorFilter,
      searchText: searchTextValue ? searchTextValue : "",
    };

    const ALL_CONTRACT_FOR_REVIEW_URL = `${Projects}`;
    instance
      .post(ALL_CONTRACT_FOR_REVIEW_URL, payload)
      .then((response) => {
        setDataLoading(false);
        setShowLoading(false);
        setUserHeartBeat(timeExit());
        if (response.data.success) {
          const res = response.data.data;
          setPageState((old) => ({
            ...old,
            isLoading: false,
            page: pageNo,
            pageSize: pageSizeNo,
            searchParam: searchTextValue,
            data: res.dataList,
            total: res.totalRecords,
          }));
          setFilterApply(res.chips);
        }
        if (response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR) {
          setSnackabrConfig({
            type: "error",
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true,
          });
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: "error",
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true,
        });
        console.log(error);
      });
  };

  //This function is used for delete chip
  const deleteChip = (item: any) => () => {
    const index = filterApply.indexOf(item);
    filterApply.splice(index, 1);
    setRemoveFilterApply({
      isChipRemoved: true,
      chipItem: item,
    });
    sessionStorage.setItem("projectFilters", JSON.stringify(filterApply));
    postAllProjectList(
      pageState.page,
      pageState.pageSize,
      filterApply,
      pageState.searchParam
    );
  };

  //This function call when enter search box value

  useEffect(() => {
    const appliedFilter: any = JSON.parse(
      sessionStorage.getItem("projectFilters") || "{}"
    );
    if (appliedFilter.length) {
      setFilterApply(appliedFilter);
    }
    const pageSize: any = sessionStorage.getItem("pageSize");
    const pageNumberVal: any = sessionStorage.getItem("pageNumber");
    const searchText: any = sessionStorage.getItem("searchTextValue");

    postAllProjectList(
      pageNumberVal ? +pageNumberVal : pageState.page,
      pageSize ? +pageSize : pageState.pageSize,
      appliedFilter.length ? appliedFilter : filterApply,
      searchText ? searchText : pageState.searchParam
    );
  }, [sidebarToggledChanged]);

  return (
    <>
      <SearchHeader
        searchTxtValue={(value: any) => {
          sessionStorage.setItem("searchTextValue", value);
          const pageSize: any = sessionStorage.getItem("pageSize");
          postAllProjectList(1, +pageSize, filterApply, value);
        }}
        textValue={sessionStorage.getItem("searchTextValue")}
        dataLoading={dataLoading}
        searchPlaceholders={MessageText.PROJECT_SEARCH_PLACEHOLDER}
      />
      <div className="agreementHomeWrapper">
        <div
          className={
            sidebarToggledChanged ? "toogleButton" : "ptojectSidebarStyle"
          }
        >
          <SideBar
            onFilters={(filter: any) => {
              sessionStorage.setItem("projectFilters", JSON.stringify(filter));
              const pageSize: any = sessionStorage.getItem("pageSize");
              const searchText: any = sessionStorage.getItem("searchTextValue");
              postAllProjectList(1, +pageSize, filter, searchText);
              // setFilterApply([...filter]);
            }}
            sidebarToggled={(value: any) => {
              setSidebarToggledChanged(!value);
            }}
            removeFilters={removeFilterApply}
            applySavedFilter={savedFilterApply}
          />
        </div>
        <div className="projectGridtableClass">
          <div
            className={
              filterApply.length > 0 ? "tableHeader" : "noFilterHeader"
            }
          >
            <div className="filterApply">
              {!ShowLoading && (
                <>
                  <div className="chipsADD">
                    {filterApply.length > 0 &&
                      filterApply.map((item: any) => {
                        return (
                          <Chip
                            key={item.key}
                            label={item.value}
                            variant="outlined"
                            deleteIcon={
                              <CloseRoundedIcon className="chipCross" />
                            }
                            onDelete={deleteChip(item)}
                            className="chipClass"
                          />
                        );
                      })}
                  </div>
                  <div className="projectCountMain">
                    <span className="headersText">
                      <span className="projectCount">
                        {Math.round(pageState.total).toLocaleString("en-US")}
                        &nbsp;
                      </span>
                      {filterApply.length === 0 && "Projects"}
                      {filterApply.length > 0 && (
                        <span style={{ paddingLeft: 5 }}>
                          {pageState.data.length == 1
                            ? " Project"
                            : " Projects"}
                        </span>
                      )}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            {pageState.data.length > 0 && (
              <div
                className={
                  filterApply.length > 0 ? "noFilter" : "filterApplyFilter"
                }
              >
                <DataTable
                  dataGridColumn={columns}
                  tableConfig={pageState}
                  onPageChangeCallback={(pageValue: any) => {
                    postAllProjectList(
                      pageValue + 1,
                      pageState.pageSize,
                      filterApply,
                      pageState.searchParam
                    );
                  }}
                  onPageSizeCallback={(pageSizeValue: any) => {
                    postAllProjectList(
                      1,
                      pageSizeValue,
                      filterApply,
                      pageState.searchParam
                    );
                  }}
                  navigateTab={`/project-details`}
                  handleClick="projects"
                  tableHeaderName="Projects"
                  showPDF={(flag: any) => {
                    setShowConfirmation(flag);
                  }}
                />
              </div>
            )}
          </div>
          {pageState.data.length === 0 && !ShowLoading && (
            <div>
              {!dataLoading && (
                <div className="noFilter">
                  <div className="noData">
                    <img
                      className="ImgStyleNoFilter"
                      src={require("../../assets/images/empty_state.png")}
                    />

                    <span className="nofilrerHelperText">
                      {MessageText.nofilter_present}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Backdrop
          sx={{
            color: "#1976D2",
            backgroundColor: "#c0c6cc70",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={dataLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};
