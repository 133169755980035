/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "../../component/navbar/navbar";
import { SnackBarMessage } from "../../component/snackBar/snackBar";
import { CommonContextValueStore } from "../../constants/commonContext";
import "./home.scss";

import SessionTimer from "../../component/sessionExpirePopup/sessionTimer";
import AnnouncementBanner from "../../component/AnnouncementBanner";
import instance from "../../utils/axiosInstance";
import { apiBasePathAuth, apiBasePathSearch } from "../../constants/env-url";
import { ReleaseNotes, ReleaseNotesPDF } from "../../constants/api-urlConstants";
import { STORAGE, getItem, setItem } from "../../utils/constant";
import moment from "moment";
import { removeDuplicateFromStringArray } from "../../utils";
import { showReleaseBannerDays } from "../../utils/config";

export const HomePage: React.FC<any> = () => {

  const { snackabrConfig ,setUserHeartBeat,timeExit,setShowConfirmation,isGuestUser} = React.useContext<any>(CommonContextValueStore);
  const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [releaseNotesPDFUrl, setReleaseNotesPDFUrl] = useState('');

  const isReleaseInfoSeen = () => getItem(STORAGE.RELEASE_INFO_SEEN) === 'true';

  useEffect(() => {
    const RELEASE_NOTES_BASE_URL = `${apiBasePathAuth}${ReleaseNotes}`;
    try {
      instance.get(RELEASE_NOTES_BASE_URL)
      .then((response) => {
        if (response.data.success) {
          const releaseInfo = response.data.data;
          if (releaseInfo.active) {
            const pdfUrl = `${apiBasePathSearch}${ReleaseNotesPDF}?fileName=${releaseInfo.s3Key}`;
            setReleaseNotesPDFUrl(pdfUrl);
            setShowAnnouncement(!isReleaseInfoSeen());
            setItem(STORAGE.RELEASE_INFO_SEEN, 'true');
            
            /** Show / hide Announcement banner **/
            const today = moment().format('MM-DD-YYYY');
            const releaseSeenDates = removeDuplicateFromStringArray(JSON.parse(getItem(STORAGE.RELEASE_BANNER_SEEN_DATES) || '[]'));
            if (releaseSeenDates.length <= showReleaseBannerDays && !(localStorage.getItem(STORAGE.BANNER_CLOSED_BY_MANUALLY) === 'true')) {
              if (!releaseSeenDates.includes(today)) {
                releaseSeenDates.push(today);
              }
              setItem(STORAGE.RELEASE_BANNER_SEEN_DATES, JSON.stringify(releaseSeenDates));
              setShowAnnouncementBanner(true);
            }
          }
        }
      });
    } catch (e) {
      console.error("Error in loading release information.");
    }
  }, [])

  window.onfocus = function() {
    const timerValue= timeExit();
    if (timerValue < 300) {
      setUserHeartBeat(timerValue);
      setShowConfirmation(true);
    } else {
      setShowConfirmation(false);
      setUserHeartBeat(timerValue);
    }
  };
 
  useEffect(() => {
    if(!isGuestUser){
      setUserHeartBeat(timeExit());
    }
  }, []);

  return (
    <>
      <div className="homeWrapper">
        {showAnnouncementBanner &&
          <AnnouncementBanner
            showAnnouncement={showAnnouncement}
            releaseNotesPDFUrl={releaseNotesPDFUrl}
            onClose={() => {
              localStorage.setItem(STORAGE.BANNER_CLOSED_BY_MANUALLY, 'true');
              setShowAnnouncementBanner(false);
            }}
          />
        }
        <Navbar />
        <div className="outletWrapper">
          <Outlet />
        </div>
      </div>
      {snackabrConfig.snackFlag && (
        <div>
          <SnackBarMessage snacBarValue={snackabrConfig} />
        </div>
      )}
      {!isGuestUser &&
        <SessionTimer />
      }
    </>
  );
};
