/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import _ from "lodash";
import instance from "../../utils/axiosInstance";
import {
  CountryList,
  SectorsList,
  SubSectorsList,
} from "../../constants/api-urlConstants";
import { ErrorCodes } from "../../constants/messageCodes";
import { MessageText } from "../../constants/messageText";
import Accordions from "../filterDropdown/accordion";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import "./sidebar.scss";
import { CommonContextValueStore } from "../../constants/commonContext";

interface Props {
  onFilters: (note: any) => void;
  sidebarToggled: (note: any) => void;
  removeFilters:any;
  applySavedFilter:any;
}

export const SideBar: React.FC<any> = (prop: Props) => {
  const {setSnackabrConfig,setUserHeartBeat,timeExit} = React.useContext(CommonContextValueStore);

  const subSectorIndex = 2;
  const[toogleSideNav,setToggleSideNav] =useState<any>(true);

  const [filterList, setFilterList] = useState<any>([
    {
      name: "Country",
      list: [],
      filtered_list: [],
      count: null,
      key: "COUNTRY",
      loading: true,
      checked: [],
      searchTxtValue:'',
      searchPlaceholder:'Search Countries'
    },
    {
      name: "Sector",
      list: [],
      filtered_list: [],
      count: null,
      key: "SECTOR",
      loading: true,
      checked: [],
      searchTxtValue:'',
      searchPlaceholder:'Search Sectors'
    },
    {
      name: "Sub-sector",
      list: [],
      filtered_list: [],
      count: null,
      key: "SUB_SECTOR",
      loading: true,
      checked: [],
      searchTxtValue:'',
      searchPlaceholder:'Search Sub-sectors'
    },
  ]);
  const setFilterListValues = (filterKey: any, listData: any,selectedItems:any = null) => {
    filterList.map((item: any) => {
      if (item.key == filterKey) {
        // setFilterList((old:any) => ({ ...old,loading: false, list:listData, count:listData.length }));
        item.list = listData;
        item.filtered_list = listData;
        item.count = listData.length;
        item.loading = false;
        item.checked = selectedItems ? selectedItems : [];
        return;
      }
    });
    setFilterList([...filterList]);
  };
  const noFilterListValues = (filterKey: any, listData: any) => {
    filterList.map((item: any) => {
      if (item.key == filterKey) {      
        item.loading = false;
        return;
      }
    });
    setFilterList([...filterList]);
  };

  const getSectorsList = () => {
    const SECTORS_LIST_URL = `${SectorsList}`;
    instance
      .get(SECTORS_LIST_URL)
      .then((response) => {
        if (response.data.success) {
         const selectedItems =  priserveFilter("SECTOR");
         if(selectedItems){
           getSubSectorList(selectedItems)
         }
          const res = response.data;
          if(selectedItems){
            selectedItems.filter((filterItem:any) =>{
              res.data.map((item:any)=>{
                if(filterItem.key == item.key){
                  item.isSelected = true;
                }             
            });
            })
          }
          if (res.data.length > 0) {
            setFilterListValues("SECTOR", res.data,selectedItems);
          }
          else{
            noFilterListValues("SECTOR", res.data)
          }
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
        console.log(error);
      });
  };

  const getCountryList = () => {
    const COUNTRY_LIST_URL = `${CountryList}`;
    instance
      .get(COUNTRY_LIST_URL)
      .then((response) => {
        setUserHeartBeat(timeExit());
        if (response.data.success) {
         const selectedItems =  priserveFilter("COUNTRY");
          const res = response.data;
          if(selectedItems){
            selectedItems.filter((filterItem:any) =>{
              res.data.map((item:any)=>{
                if(filterItem.key == item.key){
                  item.isSelected = true;
                }             
            });
            })
          }
          if (res.data.length > 0) {
            setFilterListValues("COUNTRY",res.data,selectedItems);
          }
          else{
            noFilterListValues("COUNTRY", res.data)
          }
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
        console.log(error);
      });
  };

  const getSubSectorList = (sectorKey:any,isFunctionCalled:any=false) => {
    filterList[subSectorIndex].checked=[];
    filterList[subSectorIndex].loading=true;

    setFilterList([...filterList]);

    let filters: any =[];
    sectorKey.map((sector:any,index:any)=>{
      const SectorID=sector.key;
      if(index == 0){
        filters += `sectorIds=${SectorID}`;
      }else{
        filters += `&sectorIds=${SectorID}`;
      }
    });
    const SUB_SECTOR_LIST_URL = `${SubSectorsList}?${filters}`;
    instance
      .get(SUB_SECTOR_LIST_URL)
      .then((response) => {
        if (response.data.success) {
          if(isFunctionCalled){
            selectedFilterList();
          }
         const selectedItems =  priserveFilter("SUB_SECTOR");
         const res = response.data;
          if(selectedItems){
            selectedItems.filter((filterItem:any) =>{
              res.data.map((item:any)=>{
                if(filterItem.key == item.key){
                  item.isSelected = true;
                }             
            });
            })
          }
          setFilterListValues("SUB_SECTOR", res.data,selectedItems);
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
        console.log(error);
      });
  };

//this function used for when user remove chip then he call
  const uncheckFilter=(item:any)=>{

    let selectedFilters: any = [];
    filterList.map((uncheckedFilter:any) =>{
      if(uncheckedFilter.key == item.type){
        const ArrayOfWithoutRemoveItem =  uncheckedFilter.checked.filter(function (subItem:any) {
          return subItem.key !== item.key;
       });
        uncheckedFilter.checked = ArrayOfWithoutRemoveItem;
        uncheckedFilter.filtered_list.map((subItem:any)=>{
          if(subItem.key == item.key){
            subItem.isSelected = false;
          }
        })
        if(item.type == "SECTOR"){
          getSubSectorList(uncheckedFilter.checked);
          delayedQueryFilters( uncheckedFilter.checked);
        }
      }
      selectedFilters = [...selectedFilters, ...uncheckedFilter.checked]; 
    });
    setFilterList([...filterList]);

  }

// when filter prsent in session storege then he call
  const priserveFilter =(filterNameKey:any)=>{
      const projectFilters: any = JSON.parse(
        sessionStorage.getItem("projectFilters") || '[]'
      );
        const selectedItems:any = [];
      if(projectFilters.length){
        
        projectFilters.map((item: any) => {
            if (filterNameKey == item.type) {
              selectedItems.push(item) 
            }
          })
          return selectedItems;  
        };
        
  }

//this function used for when saved filter apply then he call
  const checkedFilter=(item:any)=>{

    filterList.map((items: any) => {
      items.checked=[];
      items.searchTxtValue='';
      items.filtered_list.map((subItem:any)=>{
        subItem.isSelected = false;
      })
    })
    setFilterList([...filterList]);
    let selectedFilters: any = [];
    filterList.map((checkedFilter:any) =>{
      item.map((subItem:any)=>{
        if(checkedFilter.key == subItem.type){
          checkedFilter.checked.push(subItem);
          if(subItem.type == "SECTOR"){
            getSubSectorList(checkedFilter.checked)
          }
          checkedFilter.filtered_list.map((item:any)=>{
            if(subItem.key == item.key){
              item.isSelected = true;
            }})
          }
        })
      selectedFilters = [...selectedFilters, ...checkedFilter.checked]; 
    });
    setFilterList([...filterList]);
   }
   
  const delayedQueryFilters = useCallback(
    _.debounce((q: any) => selectedFilterList(q), 700),
    []
  );

  //this function use for celar all filter 
  const filterEmty = useCallback(
    () => {
      filterList.map((item: any) => {
        requestSearch('',item)
        item.checked=[];
        item.searchTxtValue= '';
        item.filtered_list.map((subItem:any)=>{
          subItem.isSelected =false
        })
      })
      getSubSectorList([]);
      setFilterList([...filterList]);
      sessionStorage.setItem("searchTextValue", "");
      let selectedFilters: any = [];
       sessionStorage.setItem("projectFilters", JSON.stringify(selectedFilters));
      //  setFilterList([...filterList]);
      prop.onFilters(selectedFilters);
    },
    [],
  );
  //All selected value combine one array
  const selectedFilterList = (value: any = null) => {
    let selectedFilters: any = [];
    filterList.map((item: any) => {
      selectedFilters = [...selectedFilters, ...item.checked];
    });

    prop.onFilters(selectedFilters);
  };


  function escapeRegExp(value: any) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const requestSearch = (searchValue: any, currentFilter: any) => {
    const Regex: any = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = currentFilter.list.filter((row: any) => {
      return Object.keys(row).some((field) => {
        return Regex.test(row.value?.toString());
      });
    });
    currentFilter.filtered_list = filteredRows;
    currentFilter.searchTxtValue = searchValue;

    setFilterList([...filterList]);
  };
  
   //this memo used for when saved filter apply then he call
    useMemo(()=>{
      if(prop.applySavedFilter.isChipAdd){
        checkedFilter(prop.applySavedFilter.chipItem);
      }
    },[prop.applySavedFilter])

  useEffect(() => {

    if (prop.removeFilters.isChipRemoved) {
      uncheckFilter(prop.removeFilters.chipItem);
    } else {
      getSubSectorList([]);
      getCountryList();
      getSectorsList();
    }
  }, [prop.removeFilters]);

  return (

    <div className="sideBarwrappers">

     {
        toogleSideNav&&
        <div className="sidebar">    
          <div className={`${toogleSideNav ? 'sidebarWrapper' : ' siderButton'}`}>
    
            <div className="filterTop"> 
            <span className="filterText">Filters</span>
            <span className="clearText" onClick={() => {
              filterList.map((item: any) => {
                if(item.checked.length){
                  filterEmty();
                }else{
                  return;
                }
              })
            }
            
          }
            
            >Clear all</span>
                  
            </div>
    
            <div className="sidebarFilter">
              <div className="sidebarFilterMain">
              {filterList.map((values:any) => {
                return (
                      <Accordions
                    key={values.name}
                    name={values.name}
                    filterlist={values.list}
                    list={values.filtered_list}
                    loading={values.loading}
                    searchTxtValue={values.searchTxtValue}
                    onSearchReq={(value: any) => {
                      requestSearch(value, values);
                    } }
                    checkedlistValues={(value: any) => {
                      if (value.isSelected == true) {
                        values.checked.push(value);
                        values.checked.map((item: any, index: any) => {
                          if (!item.isSelected) {
                            if (item.key == value.key) {
                              values.checked.splice(index, 1);
                            }
                          }
                        });
                      }
                      else {
                        values.checked.map((item: any, index: any) => {
                          if (item.key == value.key) {
                            values.checked.splice(index, 1);
                          }
                        });
                      }

                      if (value.type == "SECTOR") {
                        getSubSectorList(values.checked, true);
                      } else {
                        delayedQueryFilters(filterList);
                      }
                    } } 
                      searchPlaceholders={values.searchPlaceholder}                     
                      />
                );
              })}
              </div>
            </div>
    
          </div>
        </div>
        
        }
      <div className={toogleSideNav ?'toogleButton':'closeToggle'} 
      >
         {toogleSideNav ? (
          <ArrowBackIosNewIcon
            className="arrowButton"
            onClick={() => {
              setToggleSideNav(!toogleSideNav);
              prop.sidebarToggled(!toogleSideNav);
            }}
          />
        ) : (
          <ArrowForwardIosIcon
            className="leftArrowButton"
            onClick={() => {
              setToggleSideNav(!toogleSideNav);
              prop.sidebarToggled(!toogleSideNav);
            }}
          />
        )}
      </div>
    </div>
  );
};
