import { auditDocumentSearch } from "../../constants/api-urlConstants";
import { apiBasePathSearch } from "../../constants/env-url";
import instance from "../../utils/axiosInstance";

type ActivityPayload = {
  fileName: string,
  contractName: string,
  contractDate: string,
  contractTypeName: string,
  financialLevel: string,
  projectName: string,
  country: string,
  sectors: string,
  subSectors: string,
  fileUrl: string
}

export const postActivity = (payload: ActivityPayload) => {
  const url = `${apiBasePathSearch}${auditDocumentSearch}`;
  instance.post(url, payload).then((res: any) => {});  
}