/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import CustomCheckbox from "./checkboxCustom/checkboxCustom";
import "./listCheckbox.scss";

interface Props {
  value: any;
  checkedValue: (values: any) => void;
}
export default function CheckboxList(prop: Props) {
  const [checkedID, setCheckedID] = useState(false);

  const handleToggle = (value: any) => () => {
    setCheckedID(!checkedID);
    value.isSelected = !value.isSelected;
    prop.checkedValue(value);
    return;
  };

  return (
    <ListItem disablePadding className="listMainWrapper">
      <ListItemButton
        className="itemButtons"
        onClick={handleToggle(prop.value)}
      >
        <ListItemIcon sx={{ minWidth: 0 }}>
          <CustomCheckbox checked={prop.value.isSelected} disableRipple />
        </ListItemIcon>
        <Tooltip title={prop.value.value}>
          <ListItemText
            className="listItemText"
            id={prop.value.value}
            primary={prop.value.value}
          />
        </Tooltip>
      </ListItemButton>
    </ListItem>
  );
}
