import { Fragment, useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";

import instance from "../../utils/axiosInstance";
import { MessageText } from "../../constants/messageText";
import { apiBasePathSearch } from "../../constants/env-url";
import { checkSummaryRequest, summaryRequest } from "../../constants/api-urlConstants";
import { SnackBarMessage } from "../../component/snackBar/snackBar";
import { ErrorCodes } from "../../constants/messageCodes";

const Summary = (props: { metaData: any }) => {

  const { metaData } = props;
  const hashParams = new URLSearchParams(window.location.search);
  const [summaryString, setSummaryString] = useState('');
  const [isSummaryRequested, setIsSummaryRequested] = useState<boolean | null>(null);
  
  const [msgConfig, setMsgConfig] = useState({
    type: '',
    message: '',
    snackFlag: false
  });

  useEffect(() => {
    checkIsSummaryRequested();
  }, []);

  const checkIsSummaryRequested = () => {
    const apiUrl = `${apiBasePathSearch}${checkSummaryRequest}`;
    const s3Key = hashParams.get('s3Key')?.replaceAll('/indexed_document.pdf', '');
    const payload = {
      s3_xml_key: `${s3Key}/main.xml`,
      status: "PENDING"
    };
    instance.post(apiUrl, payload).then(res => {
      if (typeof res?.data === 'string') {
        setSummaryString(res?.data);
        setIsSummaryRequested(false);
      } else if (res.data.message_code === ErrorCodes.SUMMARY_EXISTS) {
        setIsSummaryRequested(true);
      } else {
        setIsSummaryRequested(false);
      }
    }).catch(() => {
      setIsSummaryRequested(false);
    });
  }

  const onClickSummaryRequest = () => {
    setIsSummaryRequested(true);

    const apiUrl = `${apiBasePathSearch}${summaryRequest}`;
    const s3Key = hashParams.get('s3Key')?.replaceAll('/indexed_document.pdf', '');
    const payload = {
      projectName: metaData?.contractName,
      s3_xml_key: `${s3Key}/main.xml`,
      status: "PENDING",
      termURL: window.location.href
    };
    instance.post(apiUrl, payload).then(res => {
      if (res?.data?.success) {
        setMsgConfig({
          type: 'success',
          message: MessageText.SUMMARY_REQUEST_SUCCESS,
          snackFlag: true
        });
      } else {
        setMsgConfig({
          type: 'error',
          message: MessageText.SUMMARY_REQUEST_ERROR,
          snackFlag: true
        });
      }
    }).catch(() => {
      setMsgConfig({
        type: 'error',
        message: MessageText.SUMMARY_REQUEST_ERROR,
        snackFlag: true
      });
      setIsSummaryRequested(false);
    }).finally(() => {
      checkIsSummaryRequested();
    })
  }

  return (
    <Fragment>
      {!summaryString ?
        <Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 150px)' }}>
            <Box sx={{ padding: '10px' }}>
              <Box>The summary is not yet available. Please click the button below to request one.</Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                {isSummaryRequested !== null &&
                  <Button
                    variant="contained"
                    onClick={() => isSummaryRequested ? {} : onClickSummaryRequest()}
                    disabled={Boolean(isSummaryRequested)}
                  >
                    {isSummaryRequested ? 'Summary requested' : 'Request Summary'}
                  </Button>
                }
                {isSummaryRequested === null &&
                  <Button>
                    <CircularProgress size={26} />
                  </Button>
                }
              </Box>
            </Box>
          </Box>
        </Fragment>
        :
        <div className="summary-text">
          <div dangerouslySetInnerHTML={{ __html: summaryString}} />
        </div>
      }
      {
        msgConfig.snackFlag &&
        <div>
          <SnackBarMessage snacBarValue={msgConfig} />
        </div>
      }
    </Fragment>
  );
}

export default Summary;
