import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Fragment, useEffect, useState } from "react";

import instance from "../../utils/axiosInstance";
import { GetDocument } from "../../constants/api-urlConstants";
import HTMLIndexSearch from './htmlIndexSearch';
import { Box, CircularProgress } from '@mui/material';

interface TermFinderProps {
  s3Key?: string;
  contractName?: string;
  index?: number;
}

const TermHtmlText: React.FC = (props: TermFinderProps) => {
  const { s3Key: s3KeyByProp, contractName: contractNameByProps, index = 0 } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const s3Key = s3KeyByProp || urlParams.get('s3Key');
  const contractName = contractNameByProps || urlParams.get('contractName');
  const [htmlString, setHtmlString] = useState<string>();
  const [apiState, setApiState] = useState<{ loading: boolean, error: string }>({ loading: false, error: '' });

  useEffect(() => {
    
    setApiState({
      loading: true,
      error: ''
    });

    const fileName = s3Key?.replace('indexed_document.pdf', 'indexed_document.html');
    instance
    .get(`${GetDocument}?fileName=${fileName}`)
    .then((res: any) => {
      let htmlString: string = res?.data;
      if (typeof htmlString === 'string') {
        setHtmlString(htmlString);
        setTimeout(() => {
          highlightTOContent();
          highlightTOBody();
        }, 100);
        setApiState({
          loading: false,
          error: ''
        });
      } else {
        setApiState({
          loading: false,
          error: 'Failed to load text'
        });
      }
    }).catch(() => {
      setApiState({
        loading: false,
        error: 'Failed to load text'
      });
    });
  }, []);

  useEffect(() => {
    highlightTOContent();
    highlightTOBody();
  }, [location]);

  useEffect(() => {
    insertSearchBoxForIndex();
  }, [htmlString])

  const highlightTOContent = () => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const elementId = window.location.hash.substring(1);
    const namedDest = hashParams.get('nameddest') || elementId;
    if (namedDest) {
      const element: any = document?.querySelector(`#li-${namedDest}`);
      if (element) {
        element.scrollIntoView();
        const existingSelectedTerms: NodeListOf<HTMLElement> = document.querySelectorAll('.selected_term');
        for (let i = 0 ; i < existingSelectedTerms.length ; i++) {
          existingSelectedTerms[i].className = '';
        }
        element.className = 'selected_term';
      }
    }
  }

  const addCollapseBehaviour = () => {
    const tocLists: any = document.querySelectorAll('#table-of-contents');
    Array.from(tocLists).map((toc: any) => {
      toc.className = 'open';
    })

  }

  const insertSearchBoxForIndex = () => {
    addCollapseBehaviour();
    const searchDiv: Element = document.createElement('div');
    searchDiv.id = 'search-wrapper';
    const root = ReactDOM.createRoot(
      searchDiv as HTMLElement
    );

    const toc_header = document.getElementById('toc_header');
    const tocList = document.querySelector('#toc-list');
    if (toc_header && tocList) {
      root.render(
        <HTMLIndexSearch toc={tocList?.children || []} index={index} />
      );
      tocList?.parentNode?.insertBefore?.(searchDiv, tocList);
    }
  }

  const highlightTOBody = () => {
    
    const ele: any = document.getElementById('document_header');
    if (ele) {
      ele.textContent = contractName?.replaceAll('[', '')?.replaceAll(']', '');
    }
    
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const elementId = window.location.hash.substring(1);
    const namedDest = hashParams.get('nameddest') || elementId;
    if (namedDest) {
      const element: any = document?.querySelector(`#${(namedDest).replace('#', 'li-')}`);
      if (element) {
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
        const existingSelectedTerms: NodeListOf<HTMLElement> = document.querySelectorAll('.selected_term_body');
        for (let i = 0 ; i < existingSelectedTerms.length ; i++) {
          existingSelectedTerms[i].className = '';
        }
        element.className = 'selected_term_body';
      }
    }
  }

  return (
    <Fragment>
      {(apiState.loading) &&
        <Box
          className="center-align"
          sx={{ height: 'calc(100vh - 200px)' }}
        >
          <CircularProgress size={28} />
        </Box>
      }
      {apiState.error ?
        <Box
          sx={{ height: 'calc(100vh - 200px)' }}
          className="center-align"
        >{apiState.error}</Box>
        :
        (htmlString &&
          <div
            dangerouslySetInnerHTML={{__html: htmlString}}
          />
        )
      }
    </Fragment>
  )
}

export default TermHtmlText;
