/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { Autocomplete, TextField } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React from "react";
import "./singleDropDown.scss";

interface Props {
  formikRef: any;
  dropDownId: any;
  dropDownName: any;
  dropDownLabel: any;
  margin: any;
  optionData: any;
  loadingRef: any;
  onChangeCallback: any;
  defaultValue: any;
  filterByOptionValue:any;
  disabled:any;
}
export const AutoCompleteSingleSelect: React.FC<any> = (props: Props) => {

  return (
    <div>
      <Autocomplete
        disablePortal
        id={props.dropDownId}
        loading={props.loadingRef}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        loadingText="Loading..."
        filterOptions={props.filterByOptionValue}
        options={props.optionData}
        getOptionLabel={(option: any) => option.value || option.name || " "}
        onChange={(e, newValue) => {
          props.onChangeCallback(newValue);
        }}
        renderInput={(params) => (
          <Field
            {...params}
            InputLabelProps={{ shrink: true }}
            name={props.dropDownName}
            as={TextField}
            size="small"
            label={props.dropDownLabel}
            margin={props.margin}
            error={
              props.formikRef.touched.hasOwnProperty(props.dropDownName) &&
              Boolean(props.formikRef.errors.hasOwnProperty(props.dropDownName))
            }
            helperText={<ErrorMessage name={props.dropDownName} />}
          />
        )}
      />
    </div>
  );
};
