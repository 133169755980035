/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";
import "./subscribePopup.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { MessageText } from "../../constants/messageText";
import { apiBasePathAuth } from "../../constants/env-url";
import instance from "../../utils/axiosInstance";
import {
  GetSubscriptionRequest,
  JobRole,
  RequestSubscription,
} from "../../constants/api-urlConstants";
import { ErrorCodes } from "../../constants/messageCodes";
import { CommonContextValueStore } from "../../constants/commonContext";
import { TextBox } from "../allInputsFields/textBox/textBox";
import MuiPhoneNumber from "material-ui-phone-number-2";
import { CircularProgress, IconButton } from "@mui/material";
import { AutoCompleteSingleSelect } from "../allInputsFields/singleDropdown/singleDropdown";
import CloseIcon from "@mui/icons-material/Close";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
  openSubscribePopup: any;
  closeSubscribePopup: (value: any) => void;
}

export default function SubscribePopup(props: Props) {

  const {setSnackabrConfig,setUserHeartBeat,timeExit} = React.useContext(CommonContextValueStore);
  const [open, setOpen] = React.useState(props.openSubscribePopup);
  const [userData, setUserData] = React.useState<any>({});
  const [userDetailsLoading, setUserDetailsLoading] = React.useState(false);

  const [submitFormLoading, setsubmitFormLoading] = React.useState(false);
  const [noOptionJobRole, setNoOptionJobRole] = React.useState(false);
  const [jobFunctionList, setJobFunctionList] = React.useState([]);

  const initial_state = {
    accountManagerEmail: userData?.accountManagerEmail,
    accountManagerName: userData?.accountManagerName,
    authorizerEmail: userData?.authorizerEmail== null ? "": userData?.authorizerEmail,
    authorizerName: userData?.authorizerName == null ? "": userData?.authorizerName,
    authorizerPhone: userData?.authorizerPhone,
    companyName: userData?.companyName,
    email: userData?.email,
    name: userData?.name,
    phone: userData?.phone,
    role: userData?.role,
    subscriptionRequested: userData?.subscriptionRequested,
    subscriptionStatus: userData?.subscriptionStatus,
    contract: true,
    financialModel: false,
  };

  const noFreeMailBoxes =
    /^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|hotmail|aol)).*\.[a-zA-Z]{2,6}$/gm;
  const onlyText = /^[A-Za-z0-9 ]+$/;

  const SignupFormValidation = Yup.object().shape({
    email: Yup.string()
      .email(MessageText.VALIDATION_EMAIL_FORMAT)
      .matches(noFreeMailBoxes, MessageText.VALIDATION_EMAIL_DOMAIN)
      .required(MessageText.VALIDATION_WORK_EMAIL_REQUIRED),
    phone: Yup.string()
      .required(MessageText.VALIDATION_PHONENUMBER_REQUIRED)
      .phone("", true, MessageText.VALIDATION_PHONENUMBER_FORMAT),
    role: Yup.object()
      .required(MessageText.VALIDATION_JOBFUN_REQUIRED)
      .typeError(MessageText.VALIDATION_JOBFUN_REQUIRED),
    authorizerName: Yup.string()
      .min(2, MessageText.VALIDATION_MIN_2)
      .trim(MessageText.VALIDATION_AUTHORIZER_NAME_REQUIRED)
      .matches(onlyText,MessageText.SPECIAL_CHARACTERS_ARE_NOT_ALLOWED)
      .required(MessageText.VALIDATION_AUTHORIZER_NAME_REQUIRED),
    authorizerEmail: Yup.string()
      .email(MessageText.VALIDATION_EMAIL_FORMAT)
      .matches(noFreeMailBoxes, MessageText.VALIDATION_EMAIL_DOMAIN)
      .required(MessageText.VALIDATION_AUTHORIZER_EMAIL_REQUIRED),
  });

  const handleClose = () => {
    setOpen(false);
    props.closeSubscribePopup(false);
  };

  const getSubscriptionRequest = () => {
    setUserDetailsLoading(true);
    const USER_API_BASE_URL = `${apiBasePathAuth}${GetSubscriptionRequest}`;
    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        setUserHeartBeat(timeExit());
        if (response.data.success) {
          setUserDetailsLoading(false);
          const res = response.data.data;
          setUserData(res);
        }
        if (response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR) {
          setUserDetailsLoading(false);
          setSnackabrConfig({
            type: "error",
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true,
          });
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: "error",
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true,
        });
        setUserDetailsLoading(false);
        // console.log(error);
      });
  };

  const postRequestSubscription = (data: any) => {

    setsubmitFormLoading(true);
    const payload = {
      phone: data.phone,
      role: {
        key: data.role.key,
        value: data.role.value,
      },
      accountManagerName: data.accountManagerName,
      accountManagerEmail: data.accountManagerEmail,
      authorizerName: data.authorizerName,
      authorizerEmail: data.authorizerEmail,
      authorizerPhone: data.authorizerPhone,
      contract: data.contract,
      financialModel: data.financialModel,
    };

    const EDIT_USER_DETAILS_API_BASE_URL = `${apiBasePathAuth}${RequestSubscription}`;
    instance
      .post(EDIT_USER_DETAILS_API_BASE_URL, payload)
      .then((response) => {
        setUserHeartBeat(timeExit());
        if (response.data.success) {
          setsubmitFormLoading(false);
          handleClose();
          setSnackabrConfig({
            type: "success",
            message: MessageText.PURCHASE_ORDER_REQUESTED_SUCCESSFULLY,
            snackFlag: true,
          });
        } else {
          setsubmitFormLoading(false);
        }
      })
      .catch((error) => {
        setsubmitFormLoading(false);
        setSnackabrConfig({
          type: "error",
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true,
        });

        console.log(error);
      });
  };

  const getJobFunction = () => {
    let USER_API_BASE_URL = `${apiBasePathAuth}${JobRole}`;
    setNoOptionJobRole(true);
    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        setNoOptionJobRole(false);
        if (response.data.success) {
          const res = response.data.data;
          setJobFunctionList(res);
        }
        if (response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR) {
          setSnackabrConfig({
            type: "error",
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true,
          });
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: "error",
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true,
        });
        // console.log(error);
      });
  };

  React.useEffect(() => {
    getJobFunction();
    getSubscriptionRequest();
  }, []);

  return (
    <div className="subscribeWrapper">
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
      >
        <div className="subscribeWrapper scrollbarColor">
          <div className="subscribeTitle">
            Subscribe
            {userData.subscriptionRequested && (
              <IconButton className="closeButton" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
          {userDetailsLoading ? (
            <div className="userDetailLoading">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Formik
                enableReinitialize={true}
                initialValues={initial_state}
                validationSchema={SignupFormValidation}
                onSubmit={(value: any) => {
                  postRequestSubscription(value);
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="subscribeContent">
                      <div className="contentText">
                        <span className="topTextBold">
                          {MessageText.THANK_YOU_FOR_INTEREST_TO_PURCHASE}
                          <br />
                          {MessageText.PLEASE_CONFIRM_YOUR_INFORMATION}
                        </span>
                      </div>
                      <div className="formTextFields">
                        <TextBox
                          formikRef={formik}
                          textBoxName={"companyName"}
                          textBoxLabel={"Company Name *"}
                          textBoxDisable={true}
                          inputPropsObj={{ spellCheck: false }}
                          margin={"dense"}
                          onChangeCallBack={(data: any) => {
                            formik.setFieldValue("companyName", data);
                          }}
                        />
                        <TextBox
                          formikRef={formik}
                          textBoxName={"name"}
                          textBoxDisable={true}
                          textBoxLabel={"Individual Name *"}
                          inputPropsObj={{
                            spellCheck: false,
                          }}
                          margin={"dense"}
                          onChangeCallBack={(data: any) => {
                            formik.setFieldValue("name", data);
                          }}
                        />
                        <TextBox
                          formikRef={formik}
                          textBoxName={"email"}
                          textBoxDisable={true}
                          inputPropsObj={{ spellCheck: false }}
                          textBoxLabel={"Email *"}
                          margin={"dense"}
                          onChangeCallBack={(data: any) => {
                            formik.setFieldValue("email", data);
                          }}
                        />
                        <div className="phoneNumberWrapper ">
                          <Field
                            as={MuiPhoneNumber}
                            id="phone"
                            defaultCountry={"us"}
                            disableAreaCodes
                            disabled={formik.values.subscriptionRequested}
                            fullWidth
                            autoFormat={false}
                            margin="dense"
                            label="Phone# *"
                            variant="outlined"
                            name="phone"
                            onBlur={formik.handleBlur}
                            sx={{ mb: 1.5 }}
                            size="small"
                            autoComplete="off"
                            style={{ colorScheme: "dark" }}
                            InputLabelProps={{ shrink: true }}
                            onChange={formik.handleChange("phone")}
                            error={
                              formik.touched.phone &&
                              Boolean(formik.errors.phone)
                            }
                            helperText={<ErrorMessage name="phone" />}
                          />
                        </div>
                        <AutoCompleteSingleSelect
                          formikRef={formik}
                          dropDownId={"combo-box-demo"}
                          dropDownName={"role"}
                          dropDownLabel={"Job Role*"}
                          margin={"dense"}
                          disabled={formik.values.subscriptionRequested}
                          defaultValue={formik.values.role}
                          optionData={jobFunctionList}
                          loadingRef={noOptionJobRole}
                          onChangeCallback={(data: any) => {
                            formik.setFieldValue("role", data);
                          }}
                        />
                      </div>
                      <div className="contentText">
                        <span className="topTextBold">
                          {" "}
                          Please <b>complete</b> the following fields.{" "}
                        </span>
                        <br />
                        {MessageText.ACCOUNT_MANAGER_SALES_CONTACT_HELP_TEXT}
                      </div>
                      <div className="formTextFields">
                        <TextBox
                          formikRef={formik}
                          textBoxName={"accountManagerName"}
                          textBoxLabel={"Name"}
                          inputPropsObj={{
                            maxLength: 25,
                            spellCheck: false,
                          }}
                          textBoxDisable={formik.values.subscriptionRequested}
                          margin={"dense"}
                          onChangeCallBack={(data: any) => {
                            formik.setFieldValue("accountManagerName", data);
                          }}
                        />

                        <TextBox
                          formikRef={formik}
                          textBoxName={"accountManagerEmail"}
                          inputPropsObj={{ spellCheck: false }}
                          textBoxLabel={"Email"}
                          textBoxDisable={formik.values.subscriptionRequested}
                          margin={"dense"}
                          onChangeCallBack={(data: any) => {
                            formik.setFieldValue("accountManagerEmail", data);
                          }}
                        />
                      </div>
                      <div className="contentText">
                        {MessageText.AUTHORIZE_THE_SUBSCRIPTION_HELP_TEXT}
                      </div>
                      <div className="formTextFields">
                        <TextBox
                          formikRef={formik}
                          textBoxName={"authorizerName"}
                          textBoxLabel={"Name *"}
                          textBoxDisable={formik.values.subscriptionRequested}
                          inputPropsObj={{
                            maxLength: 25,
                            spellCheck: false,
                          }}
                          margin={"dense"}
                          onChangeCallBack={(data: any) => {
                            formik.setFieldValue("authorizerName", data);
                          }}
                        />
                        <TextBox
                          formikRef={formik}
                          textBoxName={"authorizerEmail"}
                          inputPropsObj={{ spellCheck: false }}
                          textBoxLabel={"Email *"}
                          textBoxDisable={formik.values.subscriptionRequested}
                          margin={"dense"}
                          onChangeCallBack={(data: any) => {
                            formik.setFieldValue("authorizerEmail", data);
                          }}
                        />
                        <div className="phoneNumberWrapper ">
                          <Field
                            as={MuiPhoneNumber}
                            id="authorizerPhone"
                            defaultCountry={"us"}
                            disableAreaCodes
                            fullWidth
                            autoFormat={false}
                            disabled={formik.values.subscriptionRequested}
                            margin="dense"
                            label="Phone#"
                            variant="outlined"
                            name="authorizerPhone"
                            onBlur={formik.handleBlur}
                            sx={{ mb: 1.5 }}
                            size="small"
                            autoComplete="off"
                            style={{ colorScheme: "dark" }}
                            InputLabelProps={{ shrink: true }}
                            onChange={formik.handleChange("authorizerPhone")}
                            error={
                              formik.touched.authorizerPhone &&
                              Boolean(formik.errors.authorizerPhone)
                            }
                            helperText={<ErrorMessage name="authorizerPhone" />}
                          />
                        </div>
                        {/* <div className="subscribing-role">
                          <div className="contentText">Subscription For *</div>
                          <div className="items">
                            <div className="item">
                              <Field
                                type="checkbox"
                                name="contract"
                                onChange={formik.handleChange("contract")}
                                disabled={formik.values.subscriptionRequested}
                              /> Project And Financing Agreements
                            </div>
                            <div className="item">
                              <Field
                                type="checkbox"
                                name="financialModel"
                                onChange={formik.handleChange("financialModel")}
                                disabled={formik.values.subscriptionRequested}
                              /> Financial Models
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    <DialogActions className="actionDialog">
                      {formik.values.subscriptionRequested ? (
                        <div className="submitFormPopup">
                          <div className="">
                            {formik?.values?.subscriptionStatus === "REJECTED" ?
                              <HighlightOffIcon color="error" />
                              :
                              <VerifiedTwoToneIcon
                                className="filedIcon"
                                color="success"
                              />
                            }
                          </div>
                          <span className="submitFormText">
                            {formik?.values?.subscriptionStatus === "REJECTED" ? MessageText.PURCHASE_ORDER_REJECT_TEXT_ONE : MessageText.PURCHASE_OEDER_RECEIVED_HELP_TEXT_ONE}
                            <br />
                            {
                              formik?.values?.subscriptionStatus === "REJECTED" ? MessageText.PURCHASE_ORDER_REJECT_TEXT_SECOND : MessageText.PURCHASE_OEDER_RECEIVED_HELP_TEXT_SECOND
                            }{" "}
                          </span>
                        </div>
                      ) : (
                        <>
                          <Button
                            className="cancelButton"
                            variant="contained"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>

                          <Button
                            type="submit"
                            className="submitButton"
                            variant="contained"
                            disabled={
                              !(formik.isValid && formik.dirty) ||
                              submitFormLoading || !(formik.values.contract || formik.values.financialModel)
                            }
                            // onClick={handleClose}
                          >
                            {!submitFormLoading ? (
                              "Submit"
                            ) : (
                              <div className="submitFormLoading">
                                <CircularProgress color="inherit" size={20} />
                              </div>
                            )}
                          </Button>
                        </>
                      )}
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}
