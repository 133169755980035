/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { createContext, useEffect, useState } from "react";
import { LicenseInfo } from '@mui/x-license-pro';
import "./App.css";
import AppRoutes from "./routes/routes";
import { CommonContextValueProvider } from "./constants/commonContext";
import instance from "./utils/axiosInstance";
import { apiBasePathAuth, muiLicense } from "./constants/env-url";
import { UserDetails } from "./constants/api-urlConstants";

export const CurrentUserContext = createContext({});

export default function App() {
  LicenseInfo.setLicenseKey(muiLicense || '');

  const [user, setUser] = useState({});

  useEffect(() => {
    const USER_API_BASE_URL = `${apiBasePathAuth}${UserDetails}`;
    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        if (response.data.success) {
          const res = response.data;
          setUser(res.data);
        }
      })
      .catch(() => {});
  }, []);

  return (
    <CommonContextValueProvider>
      <CurrentUserContext.Provider value={user}>
        <div className="App">
          <AppRoutes />
        </div>
      </CurrentUserContext.Provider>
    </CommonContextValueProvider>
  )
}
