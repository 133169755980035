/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import "./sessionExpirePopup.scss";
import { MessageText } from "../../constants/messageText";
import instance from "../../utils/axiosInstance";
import { HeartBeat } from "../../constants/api-urlConstants";
import { CommonContextValueStore } from "../../constants/commonContext";
import { apiBasePathUserDetail } from "../../constants/env-url";
import CountdownTimer from "./countDownTimer";

export const SessionExpirePopup: React.FC<any> = ({
  openConfirmation,
  closeConfirmation,
  expireTime,
}) => {
  const [open, setOpen] = React.useState(openConfirmation);
  const {setUserHeartBeat,setClosPopupeConfirmation,timeExit,TimerPopupValue} = React.useContext(CommonContextValueStore);

  const handleNo = () => {
    setOpen(false);
    closeConfirmation(false);
    setClosPopupeConfirmation(true);
  };

  const handleYes = () => {
    setOpen(false);
    getHeartBeat();
    closeConfirmation(true);
  };

  const getHeartBeat = () => {
    const HEART_BEAT_API_BASE_URL = `${apiBasePathUserDetail}${HeartBeat}`;
    instance
      .get(HEART_BEAT_API_BASE_URL)
      .then((response) => {
        setUserHeartBeat(timeExit())
        setClosPopupeConfirmation(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
  
      <Dialog open={open}  className="logoutPopup">

        <DialogTitle className="dialogTitleStyle">
          <span className="titleText">{MessageText.SESSION_EXPIRE_TITLE}</span>

        </DialogTitle>
        <Divider className="divider"/>
        <DialogContent>
          <DialogContentText>
            <div className="titleDesc">
                <div>{MessageText.SESSION_EXPIRE_HELP_TEXT}<CountdownTimer
                initialSeconds={expireTime}
                closeConfirmation={handleNo}
              /></div>  
                <div>
                {MessageText.SESSION_EXPIRE_HELP_TEXT_QUA} <b>stay signed in</b>.
                </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{p:"3px 20px 20px 20px;"}}>
          <Button
            className="confirmation"
            variant="contained"
            onClick={handleYes}
            autoFocus
          >
          Stay signed in
          </Button>
        </DialogActions>

  
      </Dialog>
  
  );
};
