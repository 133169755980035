import { Accordion, Box, Card, CardContent, Grid, IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { memo, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CustomAccordionSummary from "../accordionSummary";

interface CompsTableProps {
  handleSort: (event: any, initialFilterValue: any, bucketSort: string) => void;
  order: any;
  filterOptions: any;
  updateSingleFieldFilterOptionsOnSearch: (field: string, value: string) => void;
  handleClearSearch: (field: string) => void;
  handleFilterOptionsClick: (field: string, item: any) => void;
  compsFilters: any[];
}

const CompsTable = (props: CompsTableProps) => {
  const {
    handleSort, order, filterOptions, updateSingleFieldFilterOptionsOnSearch,
    handleClearSearch, handleFilterOptionsClick, compsFilters
  } = props;
  const [isAdvancedFilterExpanded, setIsAdvancedFilterExpanded] = useState<boolean>(false);

  return (
    <Grid container spacing={2} marginTop={1} marginBottom={1.5}>
      {compsFilters.map((initialFilterValue, fIndex) => (
        <CompsGrid
          key={`filter-comps-table-${fIndex}`}
          initialFilterValue={initialFilterValue}
          handleSort={handleSort}
          order={order}
          filterOptions={filterOptions}
          updateSingleFieldFilterOptionsOnSearch={updateSingleFieldFilterOptionsOnSearch}
          handleClearSearch={handleClearSearch}
          handleFilterOptionsClick={handleFilterOptionsClick}
          isAdvancedFilterExpanded={isAdvancedFilterExpanded}
          setIsAdvancedFilterExpanded={setIsAdvancedFilterExpanded}
        />
      ))}
    </Grid>
  );
}

const CompsGrid = memo(function CompsGrid(props: any) {
  const { initialFilterValue, handleSort, order, filterOptions,
    updateSingleFieldFilterOptionsOnSearch, handleClearSearch,
    handleFilterOptionsClick, isAdvancedFilterExpanded, setIsAdvancedFilterExpanded
  } = props;
  const [searchString, setSearchString] = useState("");

  return (
    <Grid item md={6} lg={4} sx={{ width: '100%' }}>
      <Accordion expanded={isAdvancedFilterExpanded}>
        <CustomAccordionSummary
          onClick={() => setIsAdvancedFilterExpanded(!isAdvancedFilterExpanded)}
        >
          <Box sx={{ width: '100%', padding: '10px', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}
              onClick={(event) => isAdvancedFilterExpanded ? handleSort(event, initialFilterValue, 'BUCKET') : {}}
            >
              <label className="label pointer">{initialFilterValue?.displayName} {(order?.[initialFilterValue?.field]?.key !== 'DOC_COUNT' && order?.[initialFilterValue?.field]?.order) && ((order?.[initialFilterValue.field]?.order === 'DESC') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />)} </label> ({filterOptions?.[initialFilterValue?.field]?.length || 0})
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onClick={(event) => isAdvancedFilterExpanded ? handleSort(event, initialFilterValue, 'DOC_COUNT') : {}}
            >
              <label className="label pointer">
                Agreements
                {(order?.[initialFilterValue?.field]?.key !== 'BUCKET') && ((order?.[initialFilterValue.field]?.order === 'DESC' || !order?.[initialFilterValue?.field]?.order) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />)}
              </label>
            </Box>
          </Box>
        </CustomAccordionSummary>
        <span><hr style={{marginTop: 0, marginBottom: 4}}/></span>
        <Card>
          <Box sx={{ padding: '0px 0px 4px 4px', alignItems: 'center', display: 'flex', borderBottom: 1, borderColor: 'grey.500' }}>
            <div className="search-bar-container">
              <input
                type="text"
                className="input-box"
                value={searchString}
                placeholder={'Search here'}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value) {
                    setSearchString(value);
                  } else {
                    handleClearSearch(initialFilterValue.field);
                  }
                }}
              />
              {
                searchString &&
                <IconButton
                  sx={{padding: 0}}
                  onClick={() => {
                    setSearchString("");
                    handleClearSearch(initialFilterValue.field)
                  }} >
                  <ClearIcon fontSize="small" />
                </IconButton>
              }
              <Box sx={{paddingLeft: 1, marginLeft: 1, borderLeft: 1, borderLeftColor: "lightgray"}}>
                <IconButton
                  aria-label="search"
                  className="search-icon"
                  onClick={() => {
                    if (searchString) {
                      updateSingleFieldFilterOptionsOnSearch(initialFilterValue.field, searchString);
                    }
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Box>
            </div>
          </Box>
          <CardContent sx={{ padding: 0, height: '220px', overflow: 'auto' }}>
            {filterOptions?.[initialFilterValue?.field]?.length ?
              filterOptions?.[initialFilterValue?.field]?.map((item: any, index: number) => (
                <Box
                  key={index}
                  sx={{ padding: '10px', display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'grey.300', ":hover": { backgroundColor: 'grey.200' } }}
                >
                  <Box
                    sx={{ color: 'primary.main', fontWeight: 600, cursor: 'pointer', ":hover": { textDecoration: 'underline' } }}
                    key={`item-${initialFilterValue?.displayName}-${index}`}
                    onClick={() => handleFilterOptionsClick(initialFilterValue.field, item)}
                  >
                    {item.filterKey || item.filterValue || ''}
                  </Box>
                  <Box>{(item?.filterCount)?.toLocaleString()}</Box>
                </Box>
              ))
              :
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box>No results found</Box>
              </Box>
            }
          </CardContent>
        </Card>
      </Accordion>
    </Grid>
  )
});

export default CompsTable;