/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

// DEV -- npm start
    //    npm run build

// QA -- npm run start:qa
    //    npm run build:qa


// PROD -- npm run start:prod
    //    npm run build:prod

// PROD -- npm run start:refprod
    //    npm run build:refprod

    
export let apiBasePathAuth =process.env.REACT_APP_API_BASE_PATH_AUTH;
export let apiBasePathSearch=process.env.REACT_APP_API_BASE_PATH_SEARCH;
export let accessMangerBasePath =process.env.REACT_APP_ACCESS_MANGER_BASE_PATH;
export let passwordLength:any = process.env.REACT_APP_PASSWORD_LENGTH;
export let apiBasePathRefData = process.env.REACT_APP_API_BASE_PATH_REFDATA;
export let convertKey:any = process.env.REACT_APP_PASSPHRASE;
export let apiBasePathUserDetail =process.env.REACT_APP_API_BASE_PATH_USER_DETAILS;
export let mapBoxToken=process.env.REACT_APP_MAPBOX_TOKEN;
export let mapBoxStyle = process.env.REACT_APP_MAPBOX_STYLE;

export const kibanaLoginDetails = process.env.REACT_APP_KIBANA_LOGIN_DETAILS;
export const kibanaLoginAPIUrl = process.env.REACT_APP_KIBABA_LOGIN_API;
export const kibanaVersion = process.env.REACT_APP_KIBANA_VERSION;
export const contractModelKibanaUrl = process.env.REACT_APP_KIBANA_CONTRACT_MODEL_PATH;
export const financialModelKibanaUrl = process.env.REACT_APP_KIBANA_FINANCIAL_MODEL_PATH;
export const spreadJsLicense = process.env.REACT_APP_SPREADJS_LICENSE;
export const muiLicense = process.env.REACT_APP_MUI_LICENSE;
    
