import { PropsWithChildren } from "react";

interface TermFinderWrapperProps extends PropsWithChildren {
  className?: string;
}

const TermFinderWrapper = (props: TermFinderWrapperProps) => {
  const { children, className } = props;

  return (
    <section className={`${className}`}>
      {children}
    </section>
  );
}

export default TermFinderWrapper;
