/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { TextField } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { alphaNumaricInput } from '../../../constants/commonConstants';

interface Props{
    formikRef:any,
    textBoxId:any,
    textBoxDisable:any,
    textBoxName:any,
    textBoxLabel:any,
    textBoxPlaceHolder:any,
    values:any,
    margin:any,
    onChangeCallBack:any,
    inputPropsObj:any,
    alphaNumaricInputText:any,
}


export const TextBox: React.FC<any> = (props: Props) => {
    return (
        <div>
            <Field
                InputLabelProps={{ shrink: true }}
                inputProps={props.inputPropsObj}
                disabled={props.textBoxDisable}
                placeholder={props.textBoxPlaceHolder}
                as={TextField}
                autoComplete="off"
                name={props.textBoxName}
                fullWidth
                onKeyPress={(e: any) => {
                    if(props.alphaNumaricInputText){
                        alphaNumaricInput(e)
                    }
                }}
                onKeyDown ={ (e: KeyboardEvent) => { e.stopPropagation(); }}  //we used textfiled in menu then block "S" type thats why he added 
                size='small'
                label={props.textBoxLabel}
                margin={props.margin}
                onChange={(event:any,value:any)=>{
                     props.onChangeCallBack(event.target.value);                        
                  }}
                error={props.formikRef.touched.hasOwnProperty(props.textBoxName) && props.formikRef.errors.hasOwnProperty(props.textBoxName)}
                helperText={<ErrorMessage  name={props.textBoxName} 
                />
            }

            />
        </div>
    )
}