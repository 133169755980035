import { Box, Card, CardContent, Grid } from "@mui/material";
import './style.scss';

interface AggregateProps {
  count: string,
  label: string
}

const Aggregate = (props: AggregateProps) => {
  const { count, label } = props;
  
  return (
    <Grid item lg={3}>
      <Card className="aggregate-card">
        <CardContent sx={{ backgroundColor: '#d4e8ff', color: '#1976d2' }}>
          <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
            <Box sx={{ fontWeight: 800 }}>{count}</Box>
            <Box>{label}</Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Aggregate;
