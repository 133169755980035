/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { Box, IconButton, InputAdornment, Tab, Tabs } from "@mui/material";
import React, {  useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./searchHeader.scss";
import { Field, Form, Formik } from "formik";
import { fullTextSearchTextFiled } from "../../constants/commonConstants";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import * as Yup from "yup";
import { MessageText } from "../../constants/messageText";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

interface Props {
  dataLoading:any,
  textValue:any,
  searchPlaceholders:any,
  searchTxtValue:(values:any)=>void;
}

export const SearchHeader = (Props:Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = localStorage.getItem("currentTab");
  const [selectedTab, setSelectedTab] = React.useState<any>(
    currentTab ? currentTab : "/projects"
  );
  const [value, setValue] = React.useState(selectedTab == "/projects" ? 0 : 1);

  const onTabChanged = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue == 0) {
      setSelectedTabChange("/projects");
    } else if (newValue == 1) {
      setSelectedTabChange("/agreements");
    }
    setValue(newValue);
  };

  const setSelectedTabChange = (tab: any) => {
    setSelectedTab(tab);
    localStorage.setItem("currentTab", tab);
    navigate(tab);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  // let searchText: any = sessionStorage.getItem("searchTextValue");

  const initial_state = {
    searchname: Props.textValue,
  };

  const noOnlySpace = /^(?!\s+$).*/;

  const SearchFormValidation = Yup.object().shape({
    searchname: Yup.string()
      .min(3, MessageText.VALIDATION_MIN_2)
      .required(MessageText.VALIDATION_FILTER_NAME_REQUIRED)
      .matches(noOnlySpace, MessageText.VALIDATION_FILTER_NAME_REQUIRED),
  });

  useEffect(() => {

    if (location.pathname == "/projects") {
      localStorage.setItem("currentTab", location.pathname);
      setValue(0);
    } else if (location.pathname == "/projects") {
      localStorage.setItem("currentTab", location.pathname);
      setValue(1);
    }
  }, [location.pathname]);

  return (
    <div className="menuTabsWrapper">
      <Box sx={{ borderColor: "#2A90BD" }}>
        <Tabs value={value} onChange={onTabChanged}>
          <Tab
            className="tabsProject"
            label={
              <React.Fragment>
                <div className="menuTab">
                  <span className="namestab">Projects</span>
                  <span className="userLogo">
                        <FolderOutlinedIcon/>
                  </span>
                </div>
              </React.Fragment>
            }
            {...a11yProps(0)}
          />
          <Tab
            className="tabsAgreement"
            label={
              <React.Fragment>
                <div className="menuTab">
                  <span className="namestab">Agreements</span>
                  <span className="userLogo">
                        <InsertDriveFileOutlinedIcon/>
                  </span>
                </div>
              </React.Fragment>
            }
            {...a11yProps(1)}
          />
          
        </Tabs>
      </Box>

      <div className="mainclassForSeachFitler">
        <div className="mainclassForSerach">
          <Formik
            enableReinitialize={true}
            initialValues={initial_state}
            validationSchema={SearchFormValidation}
            onSubmit={(data: any) => {
              Props.searchTxtValue(data.searchname);
            }}
          >
            {(formik) => (
              <Form>
                <Field
                  as={fullTextSearchTextFiled}
                  className="fullTextSearchBox"
                  type="search"
                  placeholder={Props.searchPlaceholders}
                  name="searchname"
                  autoComplete="off"
                  disabled ={Props.dataLoading}
                  inputProps={{ spellCheck: "false" }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: any) => {
                    formik.setFieldValue("searchname", e.target.value); 
                    if(e.target.value.length <= 0){
                      Props.searchTxtValue("");
                    }
                  }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                      <IconButton className="searchIconButtoon" type="submit">
                       <SearchIcon className="searchUserIcon" />
                       </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
