/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import "./projectDetails.scss";

import { Backdrop, Button, CircularProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectDetail, Project } from "../../constants/api-urlConstants";
import { PDFViewer } from "../../component/pdfView/pdfview";
import instance from "../../utils/axiosInstance";
import DataTable from "../../component/datatable/datatable";
import { CommonContextValueStore } from "../../constants/commonContext";
import { MessageText } from "../../constants/messageText";
import { ErrorCodes } from "../../constants/messageCodes";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export const ProjectDetails: React.FC<any> = () => {

  const {setSnackabrConfig,setUserHeartBeat,timeExit} = React.useContext(CommonContextValueStore);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<any>(true);
  const [projectDetails, setProjectDetails] = useState<any>();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [dataLoading, setDataLoading] = useState(false);
 
  const queryStringUrl = window.location.search;
  const urlParamsDetails = new URLSearchParams(queryStringUrl);
  const urlDetails:any =urlParamsDetails.get('urldetails')
  const agreementId = urlParamsDetails.get('agreementId');

  useEffect(() => {
    if (agreementId) {
      setShowConfirmation(true);
    }
  }, [])

  const columns = [
    {
      field: "name",
      headerName: "Agreement name",
      minWidth: 520,
      flex: 1,
      sortable: false,
      filterable: false,
      className: "StyleColumnsNAmw ",
      renderCell: (params: any) => {
        return (
          <div className="cellWithImg">
            <Tooltip title={params?.row?.name}>
              <div className="contractNameText">{params.row.name}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "contractType",
      headerName: "Type of agreement",
      minWidth: 250,

      flex: 1,
      sortable: false,
      filterable: false,
      headerClassName: "StyleColumns",
      renderCell: (params: any) => {
        return (
          <div className="addEllipsis">
            <Tooltip title={params?.row?.contractType}>

              <div className="contractNameText">{params.row.contractType ? params.row.contractType : "- -"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "sponsors",
      headerName: "Sponsor",
      minWidth: 250,
      flex: 1,
      sortable: false,
      filterable: false,
      headerClassName: "StyleColumns",
      renderCell: (params: any) => {
        return (
          <div className="addEllipsis">
            <Tooltip title={params?.row?.sponsors}>
              <div className="contractNameText">{params.row.sponsors ? params.row.sponsors : "- -"}</div>

            </Tooltip>
          </div>
        );
      },
    },

    // {
    //   field: "action",
    //   headerName: "Actions",
    //   minWidth: 50,
    //   maxWidth: 100,
    //   flex: 1,
    //   sortable: false,
    //   disableSelectionOnClick: true,
    //   renderCell: (params: any) => {
    //     return (
    //       <div className="cellAction">
    //         <div
    //           // classNa  me="viewButton"
    //           onClick={() => {
    //             console.log("parmas",params.row)
    //             localStorage.setItem(
    //               "selectedAgreement",
    //               JSON.stringify(params.row)
    //             );
    //             setShowConfirmation(true);
    //           }}
    //         >
    //           View
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  const onConfirmAction = (value: any) => {
    if (value) {
      setShowConfirmation(false);
    } else {
      setShowConfirmation(false);
    }
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('agreementId');
    if (agreementId) {
      window.close();
    }
    const urlExceptagreementId = `${window.location.pathname}?${searchParams.toString()}`;
    navigate(urlExceptagreementId);
  }

  const getAllProjectDetails = () => {
    setDataLoading(true);

    if (urlParamsDetails?.get('projectId')) {
      const ALL_PROJECT_DETAILS_URL = `${Project}/${urlParamsDetails.get('projectId')}`;
      instance
        .get(ALL_PROJECT_DETAILS_URL)
        .then((response) => {
          setUserHeartBeat(timeExit());
          if (response.data.success) {
            const res = response.data.data;
            getAllProjectAgreementList();
            setProjectDetails(res);

          }
          if (response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR) {
            setSnackabrConfig({
              type: 'error',
              message: MessageText.INTERNAL_SERVER_ERROR,
              snackFlag: true
            })
          }
        })
        .catch((error) => {
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          });
          setIsLoading(false);
          console.log(error);
        });
      }
  };


  const getAllProjectAgreementList = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    setPageState((old) => ({ ...old, isLoading: true }));

    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      projectId: urlParams.get('projectId'),
    };

    const ALL_PROJECT_DETAILS_URL = `${ProjectDetail}`;
    instance
      .post(ALL_PROJECT_DETAILS_URL, payload)
      .then((response) => {
        setIsLoading(false);
        setDataLoading(false)
        if (response.data.success) {
          const res = response.data.data;
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: res.dataList,
            total: res.totalRecords,
          }));

        }
        if (response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR) {
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getAllProjectDetails();
    localStorage.removeItem("selectedAgreement");
  }, [pageState.page, pageState.pageSize]);

  return (
    <div className="Detailsdatatable">
        <div className="tableHeader"> 
        <div className="breadcrum">

          
              <Button variant="outlined" onClick={() => {
                console.log(urlDetails)
                   navigate("/"+urlDetails);
                
              }}
                startIcon={<ArrowBackIosIcon className="arrowClass" />} className="returnButton">
               <span className="returnButtonText"> Return to search results</span>
              </Button>

          <div>


          </div>
        </div>
        <div className="projectDetailsHeader">
          <div className="agreementNameStyle">
          <Tooltip  title={projectDetails?.name} placement={"bottom-start"} >
            <span className="agreementName">
              {projectDetails?.name || "- -"}{" "}
            </span>
          </Tooltip>
          </div>
          <div className="countryStyle">
              <Tooltip title={projectDetails?.country} placement={"bottom-start"}>
              <span className="countryTextAlign">
                {projectDetails?.country || "- -"}
              </span>
              </Tooltip>
            <div className="countryPadding">
              <span className="countryNameStyle">COUNTRY</span>
            </div>
          </div>
          <div className="sectorStyle">
           <Tooltip title={projectDetails?.sector} placement={"bottom-start"}>
            <span className="countryTextAlign">
                {projectDetails?.sector || "- -"}
              </span>
          </Tooltip>
            <div className="countryPadding">
              <span className="countryNameStyle">SECTOR</span>
            </div>
          </div>
          <div className="subSectorStyle">
              <Tooltip title={projectDetails?.subSector} placement={"bottom-start"}>
              <span className="countryTextAlign">  {projectDetails?.subSector || "- -"}</span>
              </Tooltip>
            <div className="countryPadding">
              <span className="countryNameStyle">
              SUB-SECTOR
              </span>
            </div>
          </div>
        </div>
      </div>
      <>
        {!isLoading &&
          <div className="dataTableDetails">
            <DataTable
              dataGridColumn={columns}
              tableConfig={pageState}
              onPageChangeCallback={(pageValue: any) => {
                setPageState((old) => ({ ...old, page: pageValue + 1 }));
              } }
              onPageSizeCallback={(pageSizeValue: any) => {
                setPageState((old) => ({ ...old, pageSize: pageSizeValue }));
              } }
              showPDF={(flag: any) => {
                setShowConfirmation(flag);
              } }
              handleClick='projectDetails' navigateTab={undefined} tableHeaderName={"Agreements"} />
          </div>
        }
      </>
      {showConfirmation &&
        <PDFViewer pageView={!!agreementId} openConfirmation={showConfirmation} closeConfirmation={onConfirmAction} />
      }
        <Backdrop
        sx={{ color:"#1976D2" ,backgroundColor:'#c0c6cc70', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
