/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { Navigate, Route, Routes } from 'react-router-dom';
import { HomePage } from "../pages/home/home";
import GuardedRoute, { getCurrentUserRoles } from './routeGuard';
import { ProjectsPage } from '../pages/project/projects';
import { ProjectDetails } from '../pages/projectDetails/projectDetails';
import { AgreementsPage } from '../pages/agreements/agreements';
import { EditProfile } from '../pages/userProfile/userProfile';
import { RequestForm } from '../pages/requestFrom/requestForm';
import { LandingPage } from '../component/dashboard/landingPage';
import { HelpPage } from '../pages/help/helpPage';
import MapDashboard from '../component/map';
import ContractModel from '../pages/contractModel';
import FinancialModel from '../pages/financialModel';
import { showContracts, showFinancialModels } from '../utils';
import { CurrentUserContext } from '../App';
import { useContext } from 'react';
import TermFinder from '../pages/termFinder';
import FindTerms from '../pages/dashboard/findTerms';
import AgreementTable from '../pages/dashboard/agreementTable';
import ModelTable from '../pages/dashboard/modelTable';


const AppRoutes = () => {
   const user: any = useContext(CurrentUserContext);
   const roles = user.roles || [];

   return (
      <Routes>
         <Route path="/">
            {roles?.length &&
               <>
                  <Route path="/" element={
                     <Navigate to={showContracts(roles) ? "/dashboard/agreement" : '/help'} />
                  }>
                  </Route>
                  <Route path="*" element={
                     <Navigate to={showContracts(roles) ? "/dashboard/agreement" : '/help'} />
                  }>
                  </Route>
               </>
            }


         
            <Route path="/" element={
               <GuardedRoute>
                  <HomePage />
               </GuardedRoute>
            }>
               <Route path='/map'>
               <Route index element={
                     <GuardedRoute>
                        <MapDashboard />
                     </GuardedRoute>
                  } />
               </Route>
               <Route path='/projects'>
               <Route index element={
                     <GuardedRoute>
                        <ProjectsPage />
                     </GuardedRoute>
                  } />
               </Route>

               <Route path='/agreements'>
               <Route index element={
                     <GuardedRoute>
                        <AgreementsPage />
                  </GuardedRoute>
                  } />
               </Route>
               
               <Route path='/project-details'>
               <Route index element={
                     <GuardedRoute>
                        <ProjectDetails />
                     </GuardedRoute>
                  } />
               </Route>
               <Route path='/profile'>
               <Route index element={
                     <GuardedRoute>
                        <EditProfile/>                    
                     </GuardedRoute>
                  } />
               </Route>
               <Route path='/dashboard'>
               <Route index element={
                     <GuardedRoute>
                        <ContractModel />
                     </GuardedRoute>
                  } />
               </Route>
               <Route path='/financial-model'>
               <Route index element={
                     <GuardedRoute>
                        <FinancialModel />
                     </GuardedRoute>
                  } />
               </Route>
               <Route path='/help'>
                  <Route index element={
                     <GuardedRoute>
                        <HelpPage/>
                     </GuardedRoute>
                  } />
               </Route>
               <Route path='/term-finder'>
                  <Route index element={
                     <GuardedRoute>
                        <TermFinder />
                     </GuardedRoute>
                  } />
               </Route>

               <Route path='/dashboard/term'>
                  <Route index element={
                     <GuardedRoute>
                        <FindTerms />
                     </GuardedRoute>
                  } />
               </Route>

               <Route path='/dashboard/agreement'>
                  <Route index element={
                     <GuardedRoute>
                        <AgreementTable />
                     </GuardedRoute>
                  } />
               </Route>

               <Route path='/dashboard/model-table'>
                  <Route index element={
                     <GuardedRoute>
                        <ModelTable />
                     </GuardedRoute>
                  } />
               </Route>

            </Route>
         </Route>

      </Routes>
   );
};

export default AppRoutes;
