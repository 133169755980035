import React, {  useEffect } from 'react';
import { SessionExpirePopup } from './sessionExpirePopup';
import { CommonContextValueStore } from '../../constants/commonContext';


const SessionTimer: React.FC<any> = () => {

  const {  userHeartBeat,setClosPopupeConfirmation,closePopupConfirmation ,setShowConfirmation,showConfirmation,timeExit} =React.useContext(CommonContextValueStore);

  const [userTimerValue,setUserTimerValue]= React.useState<any>()
  // const [showConfirmation, setShowConfirmation] = React.useState(false);


  useEffect(()=>{
    setUserTimerValue(userHeartBeat)
  },[userHeartBeat]);
  
  useEffect(() => {
    let interval:any;
    if (userTimerValue > 0) {
        interval = setInterval(()  => {
        if(userTimerValue <= 300 && !closePopupConfirmation){
          setShowConfirmation(true);
          setClosPopupeConfirmation(true);
          }
        setUserTimerValue((prevTime: number) => prevTime - 1);
        }, 1000);
    } else if (userTimerValue <= 0 ) {
      const timerValue = timeExit();
      console.log(timerValue)
      // if(timerValue <= 0){
      //   // logoutUser();
      // }else
      if(timerValue < 300){
        setShowConfirmation(true);
      }else{
        setShowConfirmation(false);
      }
    }

    return () => clearInterval(interval);
  }, [userTimerValue ]);

  const onConfirmAction = (value: any) => {
    setShowConfirmation(false);
  };

  return (
    <>
        {showConfirmation && (
          <SessionExpirePopup
            expireTime={userTimerValue}
            openConfirmation={showConfirmation}
            closeConfirmation={onConfirmAction}
          />
        )}
    </>
  );
};

export default SessionTimer;