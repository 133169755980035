/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import React from "react";

const CountdownTimer: React.FC<any> = ({
  initialSeconds,
  closeConfirmation,
}) => {


  const formatTime = (timeInSeconds: any) => {
    if(timeInSeconds > 0){
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
  
      return `${minutes}m:${seconds < 10 ? "0" : ""}${seconds}s`;
    }
    else{
      return `00m:00s`;
    }
  };

  return (
    <span>
      <b>{formatTime(initialSeconds)}</b>
    </span>
  );
};

export default CountdownTimer;
