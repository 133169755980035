/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import axios from 'axios'
import React from 'react';
import { CommonContextValueStore } from '../constants/commonContext';
import { accessMangerBasePath, apiBasePathSearch } from '../constants/env-url';
// import jwt_decode from "jwt-decode";
// import dayjs from 'dayjs'


const instance = axios.create({
  withCredentials: true,
  baseURL: apiBasePathSearch
})


instance.interceptors.request.use(
  async (config) => {
    config.headers = {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '***'
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


instance.interceptors.response.use((response) => {
  // console.log('response',response)
  // const { setUserHeartBeat} = React.useContext(CommonContextValueStore);
  // setUserHeartBeat(10000)
  return response;
}, error => {
  // if (error.response.status === 401) {
  //   //place your reentry code
  //   // console.log(error.response.status)
  //   // localStorage.clear();
  //   // window.location.reload();
  //   // const navigate = useNavigate();
  //   // setTimeout(() => {
  //   //   navigate("/login");
  //   // })
  // }

  // if (error.response.status === 401 || error.response.status === 500) {
    if (error.response.status === 401) {
      //place your reentry code
      console.log(error.response.status)
      localStorage.clear();
      sessionStorage.clear();
      // window.location.reload();
      window.location.href =`${accessMangerBasePath}/login`;            
    }
    return Promise.reject(error);
  });


export default instance;