/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { Backdrop, Chip, CircularProgress, Tooltip } from "@mui/material";
import  AgreementSideBar  from "../../component/agreementSidebar/agreementSidebar";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import "./agreements.scss";
import {useEffect, useState } from "react";
import  DataTable  from "../../component/datatable/datatable";
import { Agreements } from "../../constants/api-urlConstants";
import instance from "../../utils/axiosInstance";
import { PDFViewer } from "../../component/pdfView/pdfview";
import { MessageText } from "../../constants/messageText";
import _ from "lodash";
import { CommonContextValueStore } from "../../constants/commonContext";
import React from "react";
import { ErrorCodes } from "../../constants/messageCodes";
import { SearchHeader } from "../../component/searchHeader/searchHeader";

export const AgreementsPage: React.FC<any> = () => {

  const {setSnackabrConfig,setUserHeartBeat,timeExit} = React.useContext(CommonContextValueStore);

  const [filterApply, setFilterApply] = useState<any>([]);
  const[dataLoading,setDataLoading] =useState(false);
  const [sidebarToggledChanged, setSidebarToggledChanged] = useState<any>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [ShowLoading, setShowLoading] = useState(true);
  const [removeFilterApply,setRemoveFilterApply]=useState<any>({
    isChipRemoved:false,
    chipItem:null,
  });
  const [savedFilterApply,setSavedFilterApply]=useState<any>({
    isChipAdd:false,
    chipItem:null,
  });


  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 50,
    searchParam:""
  });

  const columns = [
    {
      field: "name",
      headerName: "Agreement name",
      minWidth: 550,
      // maxWidth:900,
      flex: 1,
      sortable: false,
      filterable:false,
      className: "StyleColumnsNAmw ",
      renderCell: (params: any) => {
        return (
          <div className="cellWithImg">
            <Tooltip  title={params?.row?.name}>
            <div className="contractNameText">{params.row.name}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "contractType",
      headerName: "Type of agreement",
      minWidth: 200,
      // maxWidth:300,
      flex: 1,
      sortable: false,
      filterable:false,
      headerClassName: "StyleColumns",
      renderCell: (params: any) => {
        return (
          <div className="addEllipsis">
            <Tooltip title={params?.row?.contractType}>
               <div className="contractNameText">{params.row.contractType ? params.row.contractType : "- -"}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "sponsors",
      headerName: "Sponsor",
      minWidth: 200,
      // maxWidth:300,
      flex: 1,
      sortable: false,
      filterable:false,
      headerClassName: "StyleColumns",
      renderCell: (params: any) => {
        return (
          <div  className="addEllipsis">
            <Tooltip title={params?.row?.sponsors}>
              <div className="contractNameTextsponser">{params.row.sponsors ? params.row.sponsors : "- -"}</div>
            </Tooltip>
          </div>
        );
      },
    },

    // {
    //   field: "action",
    //   headerName: "Actions",
    //   minWidth: 50,
    //   maxWidth: 100,
    //   flex: 1,
    //   sortable: false,
    //   disableSelectionOnClick: true,
    //   renderCell: (params: any) => {
    //     return (
    //       <div className="cellAction">
    //         <div
    //           className="viewButton"
    //           onClick={() => {
    //             localStorage.setItem(
    //               "selectedAgreement",
    //               JSON.stringify(params.row)
    //             );
    //             setShowConfirmation(true);
    //           }}
    //         >
    //           View
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];
  //This function is Agreement API call
  const getAllAgreementList = (pageNo:any,pageSizeNo:any,filtersObj:any,searchTextValue:any) => {
    setPageState((old) => ({ ...old, isLoading: true }));
    sessionStorage.setItem("agreementPageSize", JSON.stringify(pageSizeNo));
    sessionStorage.setItem("agreementPageNumber", JSON.stringify(pageNo));
    sessionStorage.setItem("searchTextValueAgreement",searchTextValue);

    setDataLoading(true);
    const typeOfAgreementFilter: any = [];
    const sponsorFilter: any = [];
    const lenderFilter: any = [];

    filtersObj.map((item: any) => {
      if (item.type == "CONTRACT_TYPE") {
        typeOfAgreementFilter.push(item.key);
      }

      if (item.type == "SPONSOR") {
        sponsorFilter.push(item.key);
      }

      if (item.type == "LENDER") {
        lenderFilter.push(item.key);
      }
    });

    const payload = {
      page: pageNo,
      size: pageSizeNo,
      contractTypes:typeOfAgreementFilter,
      sponsors:sponsorFilter,
      // legalAdvisors:advisorFilter,
      lenders:lenderFilter,
      searchText: searchTextValue ? searchTextValue : "", 
    };

    const ALL_AGREEMENT_LIST_URL = `${Agreements}`;

    instance
      .post(ALL_AGREEMENT_LIST_URL, payload)
      .then((response) => {
        setDataLoading(false);
        setShowLoading(false);
        setUserHeartBeat(timeExit());
        if(response.data.success){
          const res = response.data.data;
          setPageState((old) => ({
            ...old,
            isLoading: false,
            page:pageNo,
            pageSize:pageSizeNo,
            searchParam:searchTextValue,
            data: res.dataList,
            total: res.totalRecords,
          }));

          setFilterApply(res.chips)
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        console.log(error);
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        })
      });
  };
 //This function used for open pdf confromation 
  const onPDFOpenConfirmAction = (value: any) => {
    if (value) {
      setShowConfirmation(false);
    } else {
      setShowConfirmation(false);
    }
  };

  //This function is used for delete chip
  const deleteChip = (item:any) => () => {
    const index= filterApply.indexOf(item);
    filterApply.splice(index,1);
    setRemoveFilterApply({
      isChipRemoved:true,
      chipItem:item,
    });
    sessionStorage.setItem("agreementFilters", JSON.stringify(filterApply));  
    getAllAgreementList(pageState.page,pageState.pageSize,filterApply, pageState.searchParam);
   };





  
  useEffect(() => {
    const appliedFilter: any = JSON.parse(
      sessionStorage.getItem("agreementFilters") || '{}'
    );
    if(appliedFilter.length){
      setFilterApply(appliedFilter);
    }
    const agreementPageSize: any = sessionStorage.getItem("agreementPageSize"); 
    const agreementPageNumberVal: any = sessionStorage.getItem("agreementPageNumber") ;
    const searchText: any = sessionStorage.getItem("searchTextValueAgreement");

    getAllAgreementList(agreementPageNumberVal ? +agreementPageNumberVal :pageState.page,agreementPageSize ? +agreementPageSize : pageState.pageSize,appliedFilter.length ? appliedFilter : filterApply, searchText? searchText : pageState.searchParam);
 
  }, [sidebarToggledChanged]);



  return (
    <>
    <SearchHeader searchTxtValue={(searchvalue: any) => {
      sessionStorage.setItem("searchTextValueAgreement", searchvalue);
      const pageSize: any =sessionStorage.getItem("agreementPageSize");
      getAllAgreementList(1,+pageSize,filterApply,searchvalue)
      } }
      dataLoading={dataLoading}
      searchPlaceholders={MessageText.AGREEMENT_SEARCH_PLACEHOLDER}
      textValue={sessionStorage.getItem("searchTextValueAgreement")}
      />
    <div className="agreementHomeWrapper">
      <div  className={sidebarToggledChanged ? "toogleButton" : "ptojectSidebarStyle"}>
        <AgreementSideBar
          onFilters={async (filter: any) => {
            sessionStorage.setItem("agreementFilters", JSON.stringify(filter));  
            const pageSize: any =sessionStorage.getItem("agreementPageSize");
            const searchText: any = sessionStorage.getItem("searchTextValueAgreement");

            getAllAgreementList(1,+pageSize,filter, searchText);
          }}
          sidebarToggled={(value:any) =>{
            setSidebarToggledChanged(!value)
          }}
          removeFilters={removeFilterApply}
          applySavedFilter={savedFilterApply}
        />
      </div>
      <div className="projectGridtableClass">
        <div  className={filterApply.length > 0? "tableHeader" : "noFilterHeader" }>
          <div className="filterApply">

          {!ShowLoading  && 
          <>
            <div className="chipsADD">
              {filterApply.length > 0 &&
                filterApply.map((item: any) => {
                  return (
                    <Chip
                      key={item.key}
                      label={item.value}
                      variant="outlined"
                      deleteIcon={<CloseRoundedIcon  className="chipCross" />}
                      onDelete={deleteChip(item)}
                      className="chipClass"
                    />
                  );
                })}
            </div>
            <div className="projectCountMain">
              <span className="headersText">
                <span className="projectCount">
                  {`${Math.round(pageState.total).toLocaleString("en-US")}`}&nbsp;
                </span>
                <span>{filterApply.length === 0 && "agreements"}</span>
                {filterApply.length > 0 && (
                  <span style={{paddingLeft:5}}>
                    {pageState.data.length == 1
                      ? " Agreement"
                      : " Agreements"}
                  </span>
                )}
              </span>
            {/* )} */}
            </div>   
          </>
           }
          </div>
        </div>
        <div>
        {pageState.data.length > 0 &&(
        <div className={filterApply.length > 0 ? "noFilter" :"filterApplyFilter"}>
            <DataTable
                dataGridColumn={columns}
                tableConfig={pageState}
                onPageChangeCallback={(pageValue: any) => {
                  getAllAgreementList(pageValue + 1, pageState.pageSize, filterApply, pageState.searchParam);
                } }
                onPageSizeCallback={(pageSizeValue: any) => {
                  getAllAgreementList(1, pageSizeValue, filterApply, pageState.searchParam);
                } }
                handleClick='projectDetails'
                tableHeaderName="Agreements"
                showPDF={(flag: any) => {
                  setShowConfirmation(flag);
                } } navigateTab={undefined}            />
        </div>                  
        )}
        </div>
        {pageState.data.length === 0 && !ShowLoading &&(
            <div>
           {!dataLoading && 
             <div className="noFilter">
                <div className="noData" >
   
                  <img
                  className="ImgStyleNoFilter"
                  src={require("../../assets/images/empty_state.png")}
                 />
     
                 <span className="nofilrerHelperText">{MessageText.nofilter_present}</span>
                </div>
             </div>
           } 
            </div>
        )}
      </div>
      {showConfirmation && (
        <PDFViewer
          openConfirmation={showConfirmation}
          closeConfirmation={onPDFOpenConfirmAction}
        />
      )}

      <Backdrop
        sx={{ color:"#1976D2" ,backgroundColor:'#c0c6cc70', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    </>
  );
};
