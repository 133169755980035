import { Box, Button } from "@mui/material";
import { Filter } from "./model";
import { MessageText } from "../../../constants/messageText";

interface DeleteFilterProps {
  selectedFilter: any;
  onCancel?: () => void;
  onConfirmDelete?: (filter: Filter) => void;
}

const DeleteFilter = (props: DeleteFilterProps) => {
  const { selectedFilter, onCancel, onConfirmDelete } = props;
  
  return (
    <Box sx={{ p: 2 }}>
      {MessageText.CONFIRM_DELETE_FILTER}
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
        <Button variant="outlined" onClick={() => onCancel?.()}>Cancel</Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => onConfirmDelete?.(selectedFilter)}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
}

export default DeleteFilter;
