import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Dialog } from '@mui/material';
import * as GC from '@grapecity/spread-sheets';
import * as Excel from "@grapecity/spread-excelio";
import { SpreadSheets, Worksheet } from '@grapecity/spread-sheets-react';
import '@grapecity/spread-sheets-shapes';
import '@grapecity/spread-sheets-charts';
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import { Functions } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';

import './financial-model.scss';
import { KibanaDashboard } from "../kibanaDashboard/kibanaDashboard";
import { apiBasePathSearch, financialModelKibanaUrl, spreadJsLicense } from "../../constants/env-url";
import { DownloadXLSX, Key } from '../../constants/api-urlConstants';
import instance from '../../utils/axiosInstance';

GC.Spread.Sheets.LicenseKey = spreadJsLicense || '';
// @ts-ignore
Excel.LicenseKey = spreadJsLicense || '';

const hostStyle = {
  width: '100%',
  borderTop: '1px solid #a9a9a9',
  marginRight: '2px',
};

const FinancialModel = () => {
  const spreadSheetRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const s3Key = urlParams.get('s3Key');
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorInloadingFile, setIsErrorInloadingFile] = useState(false);
  const [metaData, setMetaData] = useState<any>();
  const [showAccordion, setShowAccordion] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const URL = `${apiBasePathSearch}${Key}/${s3Key?.split('/')[0]}`;
    instance.get(URL).then((res) => {
      const metaData = JSON.parse(JSON.parse(res.data.metadata));
      setMetaData(metaData)
    }).catch(() => { });
  }, []);

  useEffect(() => {
    if (metaData?.hasOwnProperty("Project Name") && metaData["Project Name"].split(',')[0].length > 50 || metaData?.Country.length > 15 || metaData?.ic_sector.length > 20 || metaData?.ic_subsector.length > 20) {
      setShowAccordion(true);
    }
  }, [metaData])

  const initSpread = async (spread: GC.Spread.Sheets.Workbook) => {
    window?.addEventListener('contextmenu', function (ev) {
      ev.preventDefault();
    });
    const formulaBar = document.getElementById('formulaBar');
    if (formulaBar) {
      const fbx = new GC.Spread.Sheets.FormulaTextBox.FormulaTextBox(formulaBar);
      fbx.workbook(spread);
    }

    if (s3Key) {
      setIsLoading(true);
      loadFile(spread, s3Key);
    }
  }

  const loadFile = async (spreadSheet: GC.Spread.Sheets.Workbook, s3Key: string) => {
    const defaultColumnCount = 50;
    const defaultRowCount = 50;
    const XLSX_FILE_BASE_URL = `${apiBasePathSearch}${DownloadXLSX}?s3Key=${s3Key}`;

    instance
      .get(XLSX_FILE_BASE_URL, { responseType: 'blob' })
      .then((response) => {
        const blob = response.data;
        const excelIO = new Excel.IO();
        excelIO.open(blob, (json: any) => {
          spreadSheet.fromJSON(json);
          spreadSheet.setActiveSheetIndex(1);
          spreadSheet.options.allowSheetReorder = false;
          spreadSheet.options.newTabVisible = false;
          spreadSheet.options.scrollbarMaxAlign = true;
          spreadSheet.options.tabEditable = false;
          spreadSheet.options.allowContextMenu = false;
          spreadSheet.sheets.map((sheet) => {
            sheet.options.allowCellOverflow = false;
            sheet.options.protectionOptions.allowResizeColumns = true;
            sheet.options.protectionOptions.allowResizeRows = true;
            sheet.options.isProtected = true;
            const columnCount = sheet.getColumnCount();
            const rowCount = sheet.getRowCount();
            sheet.setRowCount((rowCount > defaultRowCount) ? rowCount : defaultRowCount, GC.Spread.Sheets.SheetArea.viewport);
            sheet.setColumnCount((columnCount > defaultColumnCount) ? columnCount : defaultColumnCount, GC.Spread.Sheets.SheetArea.viewport);
          });
        }, (e: any) => {
          console.log('error while loading file:', e);
          setIsErrorInloadingFile(true);
        });
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
        setIsErrorInloadingFile(true);
      });
  }

  const clipboardChanging = async () => {
    const copyReplacingText = `Sorry you can't copy this XLSX`;
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(copyReplacingText);
    } else {
      return document.execCommand("copy", true, copyReplacingText);
    }
  }

  return (
    <>
      {s3Key
        ?
        <Dialog
          fullScreen
          open={Boolean(s3Key)}
          className="financial-dialogbox"
        >
          <Accordion className='project-info-outer' expanded={isExpanded}>
            <AccordionSummary
              expandIcon={ showAccordion ? <ExpandMoreIcon /> : ""} className='project-info' onClick={() => showAccordion ? setIsExpanded(!isExpanded) : {}}>
              <div className='info-box project-name'>
                <div>Project Name :</div>
                <div>{metaData?.hasOwnProperty("Project Name") ? metaData["Project Name"].split(',')[0].length > 50 ? isExpanded ? metaData["Project Name"].split(',')[0] : metaData["Project Name"].split(',')[0].slice(0, 50) + '...' : metaData["Project Name"].split(',')[0] : "-"}</div>
              </div>
              <div className='info-box country'>
                <div>Country :</div>
                <div>{metaData?.Country ? metaData?.Country.length > 15 ? isExpanded ? metaData?.Country : metaData?.Country.slice(0, 10) + '...' : metaData?.Country : "-"}</div>
              </div>
              <div className='info-box sector'>
                <div>Sector :</div>
                <div>{metaData?.ic_sector ? metaData?.ic_sector.length > 20 ? isExpanded ? metaData?.ic_sector : metaData?.ic_sector.slice(0, 15) + '...' : metaData?.ic_sector : "-"}</div>
              </div>
              <div className='info-box sub-sector'>
                <div>Sub-Sector :</div>
                <div>{metaData?.ic_subsector ? metaData?.ic_subsector.length > 20 ? isExpanded ? metaData?.ic_subsector : metaData?.ic_subsector.slice(0, 15) + '...' : metaData?.ic_subsector : "-"}</div>
              </div>
            </AccordionSummary>
          </Accordion>
          <Box>
            <div className='spread-sheet-container'>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Functions />
                <div id="formulaBar" contentEditable={true} className='formula-bar'></div>
              </Box>
              {(isLoading || isErrorInloadingFile) &&
                <div className="center-align h-full financial-loading">
                  {isLoading && <CircularProgress size={28} />}
                  {isErrorInloadingFile && <div>Error in loading file</div>}
                </div>
              }
              <SpreadSheets
                workbookInitialized={spread => initSpread(spread)}
                hostStyle={{
                  ...hostStyle,
                  height: 'calc(100vh - 100px)',
                }}
                enableFormulaTextbox={true}
                ref={spreadSheetRef}
                clipboardChanging={clipboardChanging}
                newTabVisible={false}
              >
                <Worksheet rowCount={50} colCount={50} rowHeaderVisible={true} columnHeaderVisible={true} ></Worksheet>
              </SpreadSheets>
            </div>
          </Box>
        </Dialog>
        :
        <KibanaDashboard
          iframeUrl={financialModelKibanaUrl}
        />
      }
    </>
  );
}

export default FinancialModel;
