/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import React, { useEffect, useState } from 'react'
import { passwordLength } from '../../constants/env-url'
import { MessageText } from '../../constants/messageText'
import './passwordFormat.scss'
export const PasswordFormat: React.FC<any> = (props:any) => {
    const [validateRegex,SetValidateRegex] = useState<any>({
        isPassLength:false,
        isUpperCase:false,
        isLowerCase:false,
        isDigit:false,
        isSpecialChar:false
      })
    

    useEffect(() => { 
            handelPasswordValidation(props.textBoxValue);
    }, [props.textBoxValue]);

    const handelPasswordValidation=(value:any)=>{
        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const lowercaseRegExp   = /(?=.*?[a-z])/;
        const digitsRegExp      = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp   = /.{12,}/;
        const uppercasePassword =   uppercaseRegExp.test(value);
        const lowercasePassword =   lowercaseRegExp.test(value);
        const digitsPassword =      digitsRegExp.test(value);
        const specialCharPassword = specialCharRegExp.test(value);
        const minLengthPassword =   minLengthRegExp.test(value);
       
          SetValidateRegex((old :any)=> ({ ...old, isPassLength: minLengthPassword }))
          SetValidateRegex((old :any)=> ({ ...old, isUpperCase: uppercasePassword }))
          SetValidateRegex((old :any)=> ({ ...old, isLowerCase: lowercasePassword }))
          SetValidateRegex((old :any)=> ({ ...old, isDigit: digitsPassword }))
          SetValidateRegex((old :any)=> ({ ...old, isSpecialChar: specialCharPassword }))
      
      }

    return (
        <div className='PasswordMessageWrapper'>

        <div className='callout bottom' >
          
            <div className='passwordMsg'>
              <div className='passSubTitleHeader'>

            <span className='passSubTitle passSubTitleHeader' >{MessageText.PASSWORD_MUST_FORMAT}</span>
              </div>
                <div className='passSubTitle'>
                    {
                        validateRegex.isPassLength ?
                        
                        <img className='passwordImg' src={require("../../assets/images/Icons/correct.png")}/>:<img className='passwordImg'  src={require("../../assets/images/Icons/wrong.png")}/>
                    }
                    <span className='messageText'>{MessageText.PASSWORD_MUST_FORMAT_TEXT.replace('%d', passwordLength)} </span>
                  </div>
                <div className='passSubTitle'>
                {
                        validateRegex.isUpperCase ?
                       
                        <img className='passwordImg' src={require("../../assets/images/Icons/correct.png")}/>:<img className='passwordImg'  src={require("../../assets/images/Icons/wrong.png")}/>
                    }
                    <span className='messageText'>{MessageText.PASSWORD_UPPERCASE_FORMAT}</span>
                    </div>
                <div className='passSubTitle'>
                {
                        validateRegex.isLowerCase ?
                      
                        <img className='passwordImg' src={require("../../assets/images/Icons/correct.png")}/>:<img className='passwordImg'  src={require("../../assets/images/Icons/wrong.png")}/>
                }
                 <span className='messageText'>{MessageText.PASSWORD_LOWERCASE_FORMAT}</span>
                    </div>
                <div className='passSubTitle'>
                {
                        validateRegex.isDigit ?
                        <img className='passwordImg' src={require("../../assets/images/Icons/correct.png")}/>:<img className='passwordImg'  src={require("../../assets/images/Icons/wrong.png")}/>
                }
                    <span className='messageText'>{MessageText.PASSWORD_DIGITS_FORMAT}</span>
                    </div>
                <div className='passSubTitle'>
                {
                        validateRegex.isSpecialChar ?
                        <img className='passwordImg' src={require("../../assets/images/Icons/correct.png")}/>:<img className='passwordImg'  src={require("../../assets/images/Icons/wrong.png")}/>
                }
                    <span className='messageText'>{MessageText.PASSWORD_SPECIAL_CHARACTERS_FORMAT}</span>
                    </div>
            </div>
          
        </div>
        </div>
    )
}

