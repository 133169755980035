import { Box, Button, FormControl, IconButton, Input, InputAdornment, List, ListItem, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { Modal } from "../../../component/Modal";
import DeleteFilter from "./deleteFilter";
import { Filter } from "./model";

interface SavedFiltersProps {
  savedFilters?: Filter[];
  onSelectFilter?: (value: any) => void;
  onUpdateFilter?: (value: any) => void;
  onConfirmDelete?: (value: Filter) => void;
  onCancel?: () => void;
}

const SavedFilters = (props: SavedFiltersProps) => {
  const { savedFilters, onSelectFilter, onUpdateFilter, onConfirmDelete, onCancel } = props;
  const [showDeleteFilter, setShowDeleteFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});

  useEffect(() => {
    if (savedFilters?.length === 0) {
      onCancel?.();
    }
  }, [savedFilters])

  return (
    <Box>
      <List>
        {savedFilters?.map((savedFilter, index) => (
          <ListItem key={`filter-${index}`} sx={{ gap: 3 }}>
            <FilterInput savedFilter={savedFilter} onUpdateFilter={onUpdateFilter} />
            <Button variant="contained" onClick={() => onSelectFilter?.(savedFilter)}>Apply</Button>
            <Button variant="outlined" color="error" onClick={() => {
              setShowDeleteFilter(true);
              setSelectedFilter(savedFilter);
            }}>Delete</Button>
          </ListItem>
        ))}
      </List>
      <Modal
        title="Delete Filter"
        open={showDeleteFilter}
        onClose={() => setShowDeleteFilter(false)}
      >
        <DeleteFilter
          onCancel={() => setShowDeleteFilter(false)}
          selectedFilter={selectedFilter}
          onConfirmDelete={(filter) => {
            setShowDeleteFilter(false);
            onConfirmDelete?.(filter);
          }}
        />
      </Modal>
    </Box>
  );
}

const FilterInput = (props: any) => {
  const { savedFilter, onUpdateFilter } = props;
  const [filterValue, setFilterValue] = useState(savedFilter?.name);

  useEffect(() => {
    setFilterValue(savedFilter?.name);
  }, [savedFilter?.name])

  return (
    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
      <Input
        value={filterValue}
        onChange={(e) => {
          const value = e?.target?.value;
          setFilterValue(value);
        }}
        endAdornment={(savedFilter?.name !== filterValue) &&
          <InputAdornment position="end">
            <Tooltip title="Cancel">
              <IconButton
                onClick={() => {
                  setFilterValue(savedFilter?.name);
                }}
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Update">
              <IconButton
                onClick={() => {
                  const existingFilter = JSON.parse(JSON.stringify(savedFilter));
                  const updatedFilter = {
                    ...existingFilter,
                    name: filterValue
                  };
                  onUpdateFilter(updatedFilter);
                }}
              >
                <DoneIcon color="primary" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default SavedFilters;