/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import React, { useEffect, useState } from 'react'
import "./resetPassword.scss";

import { GetUserEmail, ResetPassword, SendTwoFASignInCode } from '../../constants/api-urlConstants';
import { MessageText } from '../../constants/messageText';
import { accessMangerBasePath, apiBasePathUserDetail, passwordLength } from "../../constants/env-url";

import {
  Box,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,  
  styled,
  CircularProgress,  
  Divider,  
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from "@mui/material/InputAdornment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import instance from '../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorCodes } from '../../constants/messageCodes';
import { SnackBarMessage } from '../../component/snackBar/snackBar';
import { apiBasePathAuth } from '../../constants/env-url';
import { PasswordFormat } from '../../component/passwordFormat/passwordFormat';
import { CssTextField, encryptpassword, whiteSpaceRestrict } from '../../constants/commonConstants';
import { CommonContextValueStore } from '../../constants/commonContext';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const ResetPasswordPage: React.FC<any> = ({ openReset, closePopup ,trialValue}) => {

  const {setSnackabrConfig,setUserHeartBeat,timeExit} = React.useContext(CommonContextValueStore);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<any>(false);
  const [showNewPassword, setShowNewPassword] = useState<any>(false);
  const [showCnfPassword, setShowCnfPassword] = useState<any>(false);
  const [statusIsLoading, setStatusIsLoading] = React.useState(false);
  const [passwordTooltip, setPasswordTooltip] = useState<any>(false);
  const [passwordTextBoxValue, setPasswordTextBoxValue] = useState<any>();
  const [userEmailValue, setUserEmailValue] = useState<any>();
  const [getEmailIsLoading, setGetEmailIsLoading] = React.useState(false);
  const [userDetails, setUserDetails] = useState<any>();
  const [isTwoFASent, setIsTwoFASent] = React.useState(false);
  const [timer, setTimer] = React.useState<number>(30);

  const [open, setOpen] = React.useState(openReset);
  const initial_state = {
    code: "",
    password: "",
    newPassword: "",
    confirmpassword: "",
    trailValues: trialValue,
  };
  const [msgConfig, setMsgConfig] = React.useState({
    type: '',
    message: '',
    snackFlag: false
  })
  const regex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{${passwordLength},}$`);
  const ChangePasswordValidation = Yup.object().shape({
    trailValues: Yup.boolean(),
    code: (userDetails?.mfaEnabled || userDetails?.twofaEnabled) ? Yup.string().required(userDetails?.twofaEnabled ? MessageText.VALIDATION_TWOFA_CODE_REQUIRED : MessageText.VALIDATION_MFA_CODE_REQUIRED) :
      Yup.string().notRequired(),
    password: Yup.string().required(MessageText.VALIDATION_PASS_REQUIRED),

    newPassword: Yup.string().required(MessageText.VALIDATION_PASS_REQUIRED).matches(
      regex,
      MessageText.VALIDATION_PASS_INVALID
    ),
    confirmpassword: Yup.string().required(MessageText.VALIDATION_CONFIRM_PASS_REQUIRED)
      .oneOf([Yup.ref('newPassword'), null], MessageText.VALIDATION_PASS_MATCH)
  });

  const onFinish = async (credentials: any, actions: any) => {
    setStatusIsLoading(true);
    if (credentials && userEmailValue) {
      const encrypt_password:any = await encryptpassword(credentials.password);
      const encrypt_newPassword:any = await encryptpassword(credentials.newPassword);
      const USER_API_BASE_URL = `${apiBasePathAuth}${ResetPassword}`;

      const payload_MFA:any = {
        "email": userEmailValue,
        "password": encrypt_password,
        "newPassword": encrypt_newPassword,
      }
      if(!trialValue){
        payload_MFA['code'] = credentials.code;
      }

      instance
        .post(USER_API_BASE_URL, payload_MFA)
        .then((response) => {
          setStatusIsLoading(false);
          const res = response.data;
          setUserHeartBeat(timeExit());
          if (res.success) {
            actions.resetForm();

            setMsgConfig({
              type: 'success',
              message: MessageText.RESET_PASSWORD,
              snackFlag: true
            });
            sessionStorage.clear();
            setTimeout(() => {
              navigate("/login");
            })
          }
          else {
            if (response.data.message_code == ErrorCodes.INVALID_USERNAME_PASSWORD) {
              setMsgConfig({
                type: 'error',
                message: MessageText.INVALID_RESET_CREDS,
                snackFlag: true
              });
            } else if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
              setSnackabrConfig({
                type: 'error',
                message: MessageText.INTERNAL_SERVER_ERROR,
                snackFlag: true
              });
            } else if (response.data.message_code == ErrorCodes.INVALID_TWOFA_CODE) {
              setMsgConfig({
                type: 'error',
                message: MessageText.INVALID_TWOFA_NO_MATCH,
                snackFlag: true
              });
            } else if (response.data.message_code == ErrorCodes.INVALID_MFA_CODE) {
              setMsgConfig({
                type: 'error',
                message: MessageText.INVALID_MFA,
                snackFlag: true
              });
            } else {
              setMsgConfig({
                type: 'error',
                message: MessageText.WENT_WRONG,
                snackFlag: true
              });
            }
          }

        })
        .catch((error) => {
          setStatusIsLoading(false);
          if (!error.response.data.success) {

            if (error.response.data.message_code == ErrorCodes.INVALID_MFA_CODE) {
              setMsgConfig({
                type: 'error',
                message: MessageText.INVALID_MFA,
                snackFlag: true
              });
            } 
            else if (error.response.data.message_code == ErrorCodes.LIMIT_EXCEED_FORGOT) {
              setMsgConfig({
                type: 'error',
                message: MessageText.LIMIT_EXCEED_PASSWORD,
                snackFlag: true
              });
            } 
            else {
              setMsgConfig({
                type: 'error',
                message: MessageText.INVALID_RESET_CREDS,
                snackFlag: true
              });
            }
          }
        });
    }
  };



  const CloseResetPasswordDialog = () => {
    setOpen(false);
    closePopup(false);
  };

  const getUserEmailId = (id:any) => {
    setGetEmailIsLoading(true);
    let UserId = `id=${id}`;

    let USER_API_BASE_URL = `${apiBasePathUserDetail}${GetUserEmail}?${UserId}`;

    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        setUserHeartBeat(timeExit());
        const res = response.data.data;
        console.log(res.email);
        setUserEmailValue(res.email);
        setUserDetails(res);
      })
      .catch((error) => { })
      .finally(() => { setGetEmailIsLoading(false); });

  };


  useEffect(()=>{
    const userInfo = JSON.parse(localStorage.getItem('user-info') || '{}');
    if(userInfo.id){
      getUserEmailId(userInfo.id);
    }
  },[])

  const onClickTwoFACode = (email: string = userEmailValue) => {
    const USER_API_BASE_URL = `${apiBasePathAuth}${SendTwoFASignInCode}`;
    
    instance
      .post(USER_API_BASE_URL, { email: email || userEmailValue })
      .then((response) => {
        if (response.data.success) {
          setIsTwoFASent(true);
          const timerInterval = setInterval(() => {
            setTimer(old => (old - 1));
          }, 1000)
          setTimeout(() => {
            setIsTwoFASent(false);
            clearInterval(timerInterval);
            setTimer(30);
          }, 30000);

          setMsgConfig({
            type: "info",
            message: MessageText.RESET_CODE_SENT,
            snackFlag: true,
          });
        }
      })
      .catch(() => {
        setMsgConfig({
          type: "error",
          message: MessageText.RESET_CODE_FAILED,
          snackFlag: true,
        });
      });
  }

  return (

    <div >

      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        className="resetPopup"
      >
         <div className={getEmailIsLoading ? "resetPasswordContainerLoading" : " resetPasswordContainer" }>
          <BootstrapDialogTitle
            id="customized-dialog-title" onClose={CloseResetPasswordDialog}>
            <span className='titleTextConfirm'>Reset Password</span>
          </BootstrapDialogTitle>
          { getEmailIsLoading ?
          <div className="loadingApprove">
                  <CircularProgress size={28} />
          </div>
          :
          <Formik
            initialValues={initial_state}
            validationSchema={ChangePasswordValidation}
            onSubmit={(values, actions) => {
              onFinish(values, actions);

            }}
          >
            {({ setFieldValue, touched,errors, handleSubmit , values,handleBlur,handleChange}) => (
              <Form onSubmit={handleSubmit} className='formWidth'>
                <Divider />

                <DialogContent style={{ padding: "0px 16px 16px 16px", overflow: 'unset' }}>
                  <div className="resetPassword">

                    <div className='resetPasswordWrapper'>

                      <div className='changePassWrapper'>


                        <Box sx={{ mt: 1 }} >
                          {!trialValue && (userDetails?.mfaEnabled || userDetails?.twofaEnabled) &&
                            <div style={{position:'relative'}}>
                              <Field
                                as={TextField}
                                margin="normal"
                                error={touched.code && Boolean(errors.code)}
                                fullWidth
                                label={`${userDetails?.twofaEnabled ? "Two" : "Multi"}-factor Authentication Code*`}
                                name="code"
                                autoComplete='off'
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  autoComplete: 'new-password',
                                  form: {
                                    autoComplete: 'off',
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (
                                    userDetails?.twofaEnabled &&
                                    (<InputAdornment position="end">
                                      <IconButton tabIndex={-1}
                                        aria-label="Send Two Factor Auth Code"
                                        onClick={() => onClickTwoFACode()}
                                        disabled={isTwoFASent}
                                      >
                                        {isTwoFASent ?
                                          <span className='timer-count'>{timer} s</span>
                                          :
                                          <SendIcon
                                            color={!isTwoFASent ? "primary" : "disabled"}
                                          />
                                        }
                                      </IconButton>
                                    </InputAdornment>)
                                  )
                                }}
                                size="small"
                                helperText={<ErrorMessage name="code" />}
                              />
                            </div>
                          }
                          <div >
                        
                            <Field
                              as={TextField}
                              type={showPassword ? "text" : "password"}
                              margin="normal"
                              className="inputField"
                              fullWidth
                              autoComplete="off"
                              name="password"
                              label="Old Password*"
                              id="password"
                              size="small"
                              onKeyPress={(e: any) => whiteSpaceRestrict(e)}
                              InputLabelProps={{ shrink: true }}
                              error={touched.password && Boolean(errors.password)}
                              helperText={<ErrorMessage name="password" />}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton tabIndex={-1}
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowPassword(!showPassword)}
                                   
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />

                          </div>

                          <div className='newPasswordInput'>
                            {
                               passwordTooltip && <PasswordFormat textBoxValue={passwordTextBoxValue} 
                               />
                           }                           
                            <Field

                              as={TextField}
                              type={showNewPassword ? "text" : "password"}
                              margin="normal"
                              className="inputField"
                              fullWidth
                              autoComplete="off"
                              InputLabelProps={{ shrink: true }}
                              name="newPassword"
                              label="New Password*"
                              onKeyPress={(e: any) => whiteSpaceRestrict(e)}
                              id="newPassword"
                              size="small"
                              onFocus={(event: any) => {
                                setPasswordTextBoxValue(event.target.value)
                                setPasswordTooltip(true)

                              }}
                              onBlur={(event:any)=>{

                                setPasswordTooltip(false);
                                handleBlur(event)
                              }}
                              onChange={(event: any) => {
                                setFieldValue("newPassword", event.target.value);                                
                                setTimeout(() =>{
                                  setPasswordTextBoxValue(event.target.value);                             
                                });
                              }}
                             
                              error={touched.newPassword && Boolean(errors.newPassword)}
                              helperText={<ErrorMessage name="newPassword" />}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton tabIndex={-1}
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowNewPassword(!showNewPassword)}
                                    >
                                      {showNewPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />


                          </div>
            
                          <Field
                            as={TextField}  
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            className="inputField"
                            fullWidth
                            name="confirmpassword"
                            label="Confirm New Password*"
                            onKeyPress={(e: any) => whiteSpaceRestrict(e)}
                            type={showCnfPassword ? "text" : "password"}
                            id="confirmpassword"
                            size="small"
                            error={touched.confirmpassword && Boolean(errors.confirmpassword)}
                            helperText={<ErrorMessage name="confirmpassword" />}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton tabIndex={-1}
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowCnfPassword(!showCnfPassword)}
                                  >
                                    {showCnfPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />

                        </Box>
                      </div>
                    </div>
                  </div>
                </DialogContent>


                <div className="popupActions">
                  <div className="popupActionsWrapper">
                    <Button className='secondaryBtn'
                      onClick={CloseResetPasswordDialog} style={{ textTransform: "none" }}
                    >
                      Cancel
                    </Button>
                    {statusIsLoading && <div className="loader">

                      <div>
                        <CircularProgress size={25} />
                      </div>

                    </div>
                    }
                    {!statusIsLoading && <Button className="primeryBtn"
                    type="submit" style={{ textTransform: "none" }}

                    >
                      Reset Password
                    </Button>}
                  </div>
                </div>


              </Form>
            )}
          </Formik>
          }
          {
            msgConfig.snackFlag && <div>
              <SnackBarMessage snacBarValue={msgConfig}
              />

            </div>}
        </div>
      </BootstrapDialog>
    </div>

  )
}