/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/
import {
  CircularProgress,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useRef, useState } from "react";
import "./accordion.scss";
import ViewportList from "react-viewport-list";
import CheckboxList from "./checkboxList/listCheckbox";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

interface Props {
  name: any;
  list: any;
  loading: any;
  filterlist: any;
  searchTxtValue: any;
  searchPlaceholders: any;
  checkedlistValues: (values: any) => void;
  onSearchReq: (value: any) => void;
}

export default function Accordions(props: Props) {
  const ref = useRef(null);
  const [searchOpen, setSearchOpen] = useState<any>(false);
  const openSearchFunction = () => {
    setSearchOpen(!searchOpen);
  };

  return (
    <div className="accordion" key={props.name}>
      <div className="accorionMain">
        <div className="sidebuttons">
          <Typography className="titleOfSidebar">{props.name}</Typography>
          <IconButton
            className="searchButtonToggle"
            onClick={openSearchFunction}
          >
            <FilterListRoundedIcon
              className={!searchOpen ? "searchApply" : "notSearchApply"}
            />
          </IconButton>
        </div>
        <div
          className={
            !searchOpen ? "accordionDetailsWithSearch " : "accordionDetails"
          }
        >
          {!searchOpen && (
            <div className="serachBox">
              <IconButton sx={{ p: "5px" }} aria-label="search">
                <SearchIcon className="searchUserIcon" />
              </IconButton>
              <InputBase
                name={props.name}
                type="search"
                placeholder={props.searchPlaceholders}
                size="small"
                className="searchUser"
                autoComplete="off"
                value={props.searchTxtValue ? props.searchTxtValue : ""}
                onChange={(e) => {
                  props.onSearchReq(e.target.value);
                }}
              />
            </div>
          )}
          <div
            className={!searchOpen ? "listItemsWithSearch" : "listItems"}
            ref={ref}
          >
            {props.list.length > 0 && (
              <ViewportList viewportRef={ref} items={props.list}>
                {(item, index) => (
                  <CheckboxList
                    key={index}
                    value={item}
                    checkedValue={(value: any) => {
                      props.checkedlistValues(value);
                    }}
                  />
                )}
              </ViewportList>
            )}
            {!props.list.length && !props.loading && (
              <span className="nodataFound">No record found</span>
            )}
            {props.loading && (
              <div className="loadingApprove">
                <CircularProgress size={28} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
