import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Text from "./termHtmlText";

const TermText = () => {

  const [searchString, setSearchString] = useState('');

  const isElementInViewport = (el: any) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const onSearch = () => {
    const searchText = searchString;
    const index = 0;
    const term: any = document.getElementsByClassName('tei-body2')?.[index];
    const markRemovedText = term?.innerHTML?.replaceAll('<mark>', '')?.replaceAll('</mark>', '');
    if (searchText && term) {
      const re = new RegExp(searchText, 'gi');
      const newText = markRemovedText.replace(re, (match: any) => {
        return `<mark>${match}</mark>`;
      });
      term.innerHTML = newText;
      const highlightElement = document.getElementsByTagName('mark');
      const highlightedElements = Array.from(highlightElement);
      if (!highlightedElements.filter(e => isElementInViewport(e))?.length) {
        highlightedElements?.[0]?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
      }

      highlightedElements.map((ele, index) => {
        if (isElementInViewport(ele)) {
          if ((index + 1) < highlightedElements.length) {
            highlightedElements[index + 1]?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
          } else {
            highlightedElements?.[0]?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
          }
        }
      });
    } else if (term) {
      term.innerHTML = markRemovedText;
    }
  }

  return (
    <Box>
      <div className="navigation-panel">
        <Formik
          initialValues={{}}
          onSubmit={() => {}}
        >
          {({ handleSubmit}) => (
            <Form onSubmit={handleSubmit} className='formWidth'>
              <Field
                as={TextField}
                margin="normal"
                fullWidth
                label={`Search Text`}
                name="search-term"
                autoComplete='off'
                value={searchString}
                onChange={(e: any) => setSearchString(e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    (<InputAdornment position="end">
                      <IconButton className="searchIconButtoon" type="submit" onClick={onSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>)
                  )
                }}
                size="small"
              />
            </Form>
          )}
        </Formik>
      </div>

      <Text />

    </Box>
  );
}

export default TermText;