import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { TextBox } from "../../../component/allInputsFields/textBox/textBox";
import { MessageText } from "../../../constants/messageText";
import { Filter } from "./model";
import { AutoCompleteSingleSelect } from "../../../component/allInputsFields/singleDropdown/singleDropdown";
import { maximumFilterAllowedToSave } from "../../../utils/config";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

export type SaveFilterRef = {
	resetLoading: () => void;
};

interface SaveFilterProps {
  onSaveFilter?: (values: any, actions: any) => void;
  onCancel?: () => void;
  savedFilters: Filter[];
  onReplaceFilter?: (filter: { id: number, name: string }) => void;
}

const SaveFilter = forwardRef<SaveFilterRef, SaveFilterProps>((props, ref) => {
  const { onSaveFilter, onCancel, savedFilters, onReplaceFilter } = props;
  const isSaveFilter = ((savedFilters?.length || 0) < maximumFilterAllowedToSave);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    }
  }, []);

  useImperativeHandle(ref, () => ({
		resetLoading: () => {
			setIsLoading(false);
		}
	}));

  const initial_state = {
    filterName: '',
    filter: ''
  };
  const SaveFilterFormValidation = Yup.object().shape({
    filterName: Yup.string()
      .min(2, MessageText.VALIDATION_MIN_2)
      .required(MessageText.VALIDATION_FILTERNAME_REQUIRED),
    filter: isSaveFilter ? Yup.object().notRequired() : Yup.object()
    .required(MessageText.VALIDATION_FILTER_REQUIRED)
    .typeError(MessageText.VALIDATION_FILTER_REQUIRED),
  })

  return (
    <Box sx={{ padding: 2, minWidth: '300px', maxWidth: '400px' }}>
      {!isSaveFilter && <Box sx={{ marginBottom: 2 }}>{MessageText.REPLACE_EXISTING_FILTER_INFO}</Box>}
      <Formik
        initialValues={initial_state}
        validationSchema={SaveFilterFormValidation}
        onSubmit={(values, actions) => {
          // @ts-ignore
          const payload = { id: values?.filter?.id, name: values?.filterName };
          !isLoading && (isSaveFilter ? onSaveFilter?.(values, actions) : onReplaceFilter?.(payload));
          setIsLoading(true);
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} className='formWidth'>
            {!isSaveFilter &&
              <AutoCompleteSingleSelect
                formikRef={formik}
                dropDownId={"combo-box-filter"}
                dropDownName={"filter"}
                dropDownLabel={"Select Filter*"}
                margin={"dense"}
                optionData={savedFilters}
                loadingRef={false}
                onChangeCallback={(data: any) => {
                  formik.setFieldValue("filter", data);
                }}
              />
            }
            <TextBox
              formikRef={formik}
              textBoxName={"filterName"}
              textBoxLabel={"Filter Name*"}
              inputPropsObj={{
                maxLength: 25, spellCheck: false 
              }}
              margin={"dense"}
              onChangeCallBack={(data: any) => {
                formik.setFieldValue("filterName", data);
              }}
            />
            <Box sx={{ marginTop: '5px', display: 'flex', gap: 2 }}>
              <Button
                type="button"
                size="medium"
                fullWidth
                variant="outlined"
                onClick={() => onCancel?.()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="medium"
                fullWidth
                variant={isLoading ? 'outlined' : "contained"}
                style={{ textTransform: "none" }}
                disabled={!(formik.isValid && formik.dirty)}
              >
                {isLoading ? <CircularProgress size={18} /> : 'Save'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
})

export default SaveFilter;
