/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useEffect, useRef, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { EmbeddedPDF } from "../pdfeditor/EmbeddedPdf";
import './pdfView.scss';
import instance from "../../utils/axiosInstance";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { DownloadAllowed } from "../../constants/api-urlConstants";
import { CircularProgress } from "@mui/material";
import { apiBasePathAuth } from "../../constants/env-url";
import { CommonContextValueStore } from "../../constants/commonContext";
import { ErrorCodes } from "../../constants/messageCodes";
import { MessageText } from "../../constants/messageText";

export const Transiton = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  name: any;
  open: boolean;
  contractDetail: any;
  id: any;
  pageView?: boolean;
  flagedValue: (flaged: any) => void;
  setOpen: (open: boolean) => void;
  onFlag: (note: string) => void;
  openConfirmation: any;
  closeConfirmation: any;
}

export const PDFViewer: React.FC<any> = (props: Props) => {

  const {setSnackabrConfig,setUserHeartBeat,timeExit} = React.useContext(CommonContextValueStore);
  const [agreementDetails,setAgreementDetails]=useState<any>();
  const [pdfStore,setPdfStore]=useState<any>();
  const [showPDF,setShowPDF]=useState<any>(false);
  const [laodingPDF,setLoadingPDF]=useState<any>(false);

  const viewer = useRef(null);


  const [open, setOpen] = React.useState(props.openConfirmation);

  const handleNo = () => {
    setOpen(false);
    props.closeConfirmation(false);
  };

  const handleYes = () => {
    setOpen(false);
    props.closeConfirmation(true);
  };

  const getDownloadPdf = (detail:any) => {
    setLoadingPDF(false);
    const SELECTED_PDF_URL = `${apiBasePathAuth}${DownloadAllowed}`;    
    instance
      .get(SELECTED_PDF_URL)
      .then((response) => {
        setLoadingPDF(true);
        setUserHeartBeat(timeExit());
        if(response.data.success){
          setShowPDF(response.data.data);
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });
      });
  };


  useEffect(() => {
    const AgreementDetail: any = JSON.parse(
      localStorage.getItem("selectedAgreement") || "{}"
    );
    setAgreementDetails(AgreementDetail);
    getDownloadPdf(AgreementDetail);
  }, []);



  return (

      <Dialog
        fullScreen
        open={open} onClose={handleNo}
        TransitionComponent={Transiton}
      className="dialogbox"
      >
        {laodingPDF ?
         <div className={props.pageView ? "showPDFviewrFullPage" : "showPDFviewr"}>
          <div className="paddingBox">
            {!showPDF ?

            <div  className="NotPDF">
              <ErrorOutlineRoundedIcon color="error" className="errorSymbol"/>
              <span className="notPdfText">
              You have exhausted your view limits.
              </span>
              <span className="notPdfText">
              Please try after <b>24 hours</b>.
              </span>

            </div> :
            <div className="pdfbox">
        
              <EmbeddedPDF
              docUrl={pdfStore}
              
           />
            </div>
            }
              <Toolbar className="toolbarpdf" >
                <div className="pdfNameDiv">
                  <IconButton
                    edge="start"
                    onClick={handleYes}
                    aria-label="close"
                  >
                    <ClearOutlinedIcon className="arrowstyle" />
                  </IconButton>

                </div>
              </Toolbar>

          </div>
        </div> :
        <div className="loadingApprove">
        <CircularProgress size={40} />
      </div>
   }
  
      </Dialog>
  );
};
