/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import {
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { MessageText } from "../../constants/messageText";
import * as Yup from "yup";
import * as phone from "yup-phone";
import "yup-phone";
import MuiPhoneNumber from "material-ui-phone-number-2";
import React, { useState } from "react";
import { TextBox } from "../../component/allInputsFields/textBox/textBox";
import "./userProfile.scss";
import { AutoCompleteSingleSelect } from "../../component/allInputsFields/singleDropdown/singleDropdown";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import { apiBasePathAuth } from "../../constants/env-url";
import { CountriesList, EditUserDetails, GetSelectedUserDetails, JobFunction, UserDetails } from "../../constants/api-urlConstants";
import instance from "../../utils/axiosInstance";
import _ from "lodash";
import { SnackBarMessage } from "../../component/snackBar/snackBar";
import { CssTextField } from "../../constants/commonConstants";
import { ErrorCodes } from "../../constants/messageCodes";
import { CommonContextValueStore } from "../../constants/commonContext";

export const EditProfile: React.FC<any> = () => {

  const {trigerUser, setTrigerUser,setSnackabrConfig,setUserHeartBeat,timeExit} = React.useContext(CommonContextValueStore);

  const navigate = useNavigate();
  const [statusIsLoading, setStatusIsLoading] = React.useState(false);
  const [apiStatusLoading, setApiStatusLoading] = React.useState(false);
  const [userData, setUserData] = React.useState<any>({});
  const [countryData, setCountryData] = React.useState<any>({});

  const [jobFunctionList, setJobFunctionList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [noOptionJobRole, setNoOptionJobRole] = React.useState(false);
  const [noOptionCountry, setNoOptionCountry] = React.useState(false);
  const [APIMsgConfig, setAPIMsgConfig] = useState({
    type: '',
    message: '',
    snackFlag: false
  });

  const initial_state = {
    email: userData.email,
    firstName:userData.firstName,
    lastName: userData.lastName,
    company: userData.company,
    phoneNumber:userData.phoneNumber,
    linkedInProfile: userData.linkedInProfile,
    title: userData.title,
    location:countryData,
    parentCompany: userData.parentCompany,
    jobFunction:userData.jobFunction
  };
  const linkdinUrlFormat = /([\w]+\.)?linkedin\.com/gm;
  const noOnlySpace = /^(?!\s+$).*/;
  const noFreeMailBoxes =
    /^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|hotmail|aol)).*\.[a-zA-Z]{2,6}$/gm;
  const SignupFormValidation = Yup.object().shape({
    email: Yup.string()
      .email(MessageText.VALIDATION_EMAIL_FORMAT)
      .matches(noFreeMailBoxes, MessageText.VALIDATION_EMAIL_DOMAIN)
      .required(MessageText.VALIDATION_WORK_EMAIL_REQUIRED),
    firstName: Yup.string()
      .min(2, MessageText.VALIDATION_MIN_2)
      .required(MessageText.VALIDATION_FIRSTNAME_REQUIRED)
      .matches(noOnlySpace, MessageText.VALIDATION_FIRSTNAME_REQUIRED),
    lastName: Yup.string()
      .min(1, MessageText.VALIDATION_MIN_2)
      .required(MessageText.VALIDATION_LASTNAME_REQUIRED)
      .matches(noOnlySpace, MessageText.VALIDATION_LASTNAME_REQUIRED),
    company: Yup.string()
      .min(2, MessageText.VALIDATION_MIN_2)
      .required(MessageText.VALIDATION_COMPANY_REQUIRED)
      .matches(noOnlySpace, MessageText.VALIDATION_COMPANY_REQUIRED),
    // designation: Yup.string().min(2, MessageText.VALIDATION_MIN_2).required(MessageText.VALIDATION_DESIGNATION_REQUIRED).matches(noOnlySpace, MessageText.VALIDATION_DESIGNATION_REQUIRED),
    linkedInProfile: Yup.string().matches(
      linkdinUrlFormat,
      MessageText.VALIDATION_LINKEDIN_REQUIRED
    ),
    // phoneNumber: Yup.string().required("Phone number is required"),
    // phoneNumber: Yup.string().required(
    //   MessageText.VALIDATION_PHONENUMBER_REQUIRED
    // ),
    phoneNumber: Yup.string()
    .required(MessageText.VALIDATION_PHONENUMBER_REQUIRED).phone('', true, MessageText.VALIDATION_PHONENUMBER_FORMAT),
    // phoneno: Yup.string().phone('IN',true,"Invalid phone no").required("required"),
    // .matches(phoneRegExp, 'Phone number is not valid')
    title: Yup.string()
      .min(2, MessageText.VALIDATION_MIN_2)
      .required(MessageText.VALIDATION_TITLE_REQUIRED)
      .matches(noOnlySpace, MessageText.VALIDATION_TITLE_REQUIRED),
    // location: Yup.string().min(2, MessageText.VALIDATION_MIN_2).required(MessageText.VALIDATION_LOCATION_REQUIRED).matches(noOnlySpace, MessageText.VALIDATION_LOCATION_REQUIRED),
    // parentCompany: Yup.string().min(2, MessageText.VALIDATION_MIN_2).required(MessageText.VALIDATION_PARENTCOMP_REQUIRED).matches(noOnlySpace, MessageText.VALIDATION_PARENTCOMP_REQUIRED),
    jobFunction: Yup.object()
      .required(MessageText.VALIDATION_JOBFUN_REQUIRED)
      .typeError(MessageText.VALIDATION_JOBFUN_REQUIRED),
    location: Yup.object()
      .required(MessageText.VALIDATION_LOCATION_REQUIRED)
      .typeError(MessageText.VALIDATION_LOCATION_REQUIRED),
  });
  const currentTab = localStorage.getItem("currentTab");
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option:any) => (option.name),
  });
  const getJobFunction = () => {
    let USER_API_BASE_URL = `${apiBasePathAuth}${JobFunction}`;
    setNoOptionJobRole(true);
    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        setNoOptionJobRole(false);
        if(response.data.success){
          const res = response.data.data;
          setJobFunctionList(res);
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        })
        // console.log(error);
      });

  }
  const getCountriesList = () => {
    let USER_API_BASE_URL = `${apiBasePathAuth}${CountriesList}`;
    setNoOptionCountry(true);
    instance
      .get(USER_API_BASE_URL)
      .then((response) => {
        setNoOptionCountry(false);
        if(response.data.success){
          const res = response.data.data;
          setCountriesList(res);
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          })
        }
      })
      .catch((error) => {
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        })
        // console.log(error);
      });

  }
  const getUserDetails = (id: any) => {
    setStatusIsLoading(true);
    const USER_API_BASE_URL = `${apiBasePathAuth}${GetSelectedUserDetails}${id}`;
    instance
        .get(USER_API_BASE_URL)
        .then((response) => {
          setUserHeartBeat(timeExit());
          if(response.data.success){
            setStatusIsLoading(false);
            const res = response.data.data
            setUserData(res.user);
            setCountryData(res.country)
          }
          if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
            setStatusIsLoading(false);
            setSnackabrConfig({
              type: 'error',
              message: MessageText.INTERNAL_SERVER_ERROR,
              snackFlag: true
            })
          }
        })
        .catch((error) => {
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          });
            setStatusIsLoading(false)
            // console.log(error);
        });
}


  const postUserDetails = (data:any) => {

    setApiStatusLoading(true);
    const payload = {
      email:data.email,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      phoneNumber: data.phoneNumber,
      company:data.company,
      title: data.title,
      location: data.location.name,
      parentCompany:data.parentCompany,
      linkedInProfile:data.linkedInProfile,
      jobFunction:data.jobFunction
    };
    const EDIT_USER_DETAILS_API_BASE_URL = `${apiBasePathAuth}${EditUserDetails}`;
    instance
      .post(EDIT_USER_DETAILS_API_BASE_URL, payload)
      .then((response) => {
        setApiStatusLoading(false);
        setTrigerUser(!trigerUser);
        setUserHeartBeat(timeExit());
        if (response.data.success) {
          setAPIMsgConfig({
            type: 'success',
            message: MessageText.PROFILE_UPDATE,
            snackFlag: true
          })
          const res = response.data.data;
        }
        if(response.data.message_code == ErrorCodes.INTERNAL_SERVER_ERROR){
          setSnackabrConfig({
            type: 'error',
            message: MessageText.INTERNAL_SERVER_ERROR,
            snackFlag: true
          });
        }
      })
      .catch((error) => {
        setApiStatusLoading(false);
        setSnackabrConfig({
          type: 'error',
          message: MessageText.INTERNAL_SERVER_ERROR,
          snackFlag: true
        });

        console.log(error);
      });
  };

  const onFinish = (data: any) => {
    postUserDetails(data);
  };

  const handleBack = () => {
    navigate(`${currentTab}`);
  };

  React.useEffect(() => {  
    let user: any = JSON.parse(localStorage.getItem('user-info') || '{}');
    getCountriesList();
    getJobFunction(); 
    if(user.id){
      getUserDetails(user.id);
    }
  }, []);

  return (
    <div className="profilewrapper">
      <div className="breacrumTitle">
        <IconButton onClick={handleBack}  className="buttonBredcrum">
          <ArrowBackIosNewIcon className="breacrumTitleIcon" />
        </IconButton>
       <span onClick={handleBack} className="breacrumTitleIcon" >Profile</span> 
      </div>
      <div className="formWrapper">
        {!statusIsLoading &&
        <Box className="formBox">
          <Box className="innerBox">
            <Formik
              enableReinitialize={true}
              initialValues={initial_state}
              validationSchema={SignupFormValidation}
              onSubmit={onFinish}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <div className="tileEdit">Basic information</div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className="gridboxMargin">
                      <TextBox
                        formikRef={formik}
                        textBoxName={"firstName"}
                        textBoxLabel={"First Name*"}
                        alphaNumaricInputText={true}
                        inputPropsObj={{
                          maxLength: 25,spellCheck: false 
                        }}
                        margin={"dense"}
                        onChangeCallBack={(data: any) => {
                          formik.setFieldValue("firstName", data);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} className="gridboxMargin">
                      <TextBox
                        formikRef={formik}
                        textBoxName={"lastName"}
                        textBoxLabel={"Last Name*"}
                        alphaNumaricInputText={true}
                        inputPropsObj={{
                          maxLength: 25,spellCheck:false 
                        }}
                        margin={"dense"}
                        onChangeCallBack={(data: any) => {
                          formik.setFieldValue("lastName", data);
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className="gridboxMargin">
                      <div className="phoneNumberWrapper ">
                      <Field
                        as={MuiPhoneNumber}
                        id="phoneNumber"
                        defaultCountry={"us"}
                        disableAreaCodes
                        fullWidth
                        autoFormat={false}
                        margin="dense"
                        label="Phone Number*"
                        variant="outlined"
                        name="phoneNumber"
                        onBlur={formik.handleBlur}
                        sx={{ mb: 1.5 }}
                        size="small"
                        autoComplete="off"
                        style={{colorScheme:'dark'}}
                        InputLabelProps={{ shrink: true }}
                        onChange={formik.handleChange("phoneNumber")}
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                        helperText={<ErrorMessage name="phoneNumber" />}
                      />
                      </div>
                    </Grid>
                  </Grid>
                    <Grid item xs={12} sm={12} className="gridboxMargin">
                      <TextBox
                        formikRef={formik}
                        textBoxName={"email"}
                        textBoxDisable={true}
                        inputPropsObj={{spellCheck: false }}
                        textBoxLabel={"Business Email*"}
                        margin={"dense"}
                        onChangeCallBack={(data: any) => {
                          formik.setFieldValue("email", data);
                        }}
                      />

                      <div className="emailInfo">
                        The address is used to identify your Infraclear account.
                        You can’t change this address.
                      </div>
                    </Grid>
                  <div className="jobDescription">
                    <span className="sectionTitle">Job description</span>
                  </div>
                  <Grid item xs={12} sm={12} className="gridboxMargin">
                    <TextBox
                      formikRef={formik}
                      textBoxName={"title"}
                      textBoxLabel={"Title*"}
                      inputPropsObj={{spellCheck: false }}
                      margin={"dense"}
                      onChangeCallBack={(data: any) => {
                        formik.setFieldValue("title", data);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} className="gridboxMargin">
                    <AutoCompleteSingleSelect
                      formikRef={formik}
                      dropDownId={"combo-box-demo"}
                      dropDownName={"jobFunction"}
                      dropDownLabel={"Job Role*"}
                      margin={"dense"}
                      defaultValue={formik.values.jobFunction}
                      optionData={jobFunctionList}
                      loadingRef={noOptionJobRole}
                      onChangeCallback={(data: any) => {
                        formik.setFieldValue("jobFunction", data);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} className="gridboxMargin">
                    <TextBox
                      formikRef={formik}
                      textBoxName={"company"}
                      textBoxLabel={"Company Name*"}
                      inputPropsObj={{spellCheck: false }}
                      margin={"dense"}
                      onChangeCallBack={(data: any) => {
                        formik.setFieldValue("company", data);
                      }}
                    />
                  </Grid>

                    <Grid item xs={12} sm={12} className="gridboxMargin">
                      <AutoCompleteSingleSelect
                        formikRef={formik}
                        dropDownId={"combo-box-demo"}
                        dropDownName={"location"}
                        dropDownLabel={"Country*"}
                        defaultValue={formik.values.location}
                        filterByOptionValue={filterOptions}
                        margin={"dense"}
                        optionData={countriesList}
                        loadingRef={noOptionCountry}
                        onChangeCallback={(data: any) => {
                          formik.setFieldValue("location", data);
                        }}
                      />
                    </Grid>

                  <Grid item xs={12} sm={12} className="gridboxMargin">
                  <TextBox
                      formikRef={formik}
                      textBoxName={"parentCompany"}
                      inputPropsObj={{spellCheck: false }}
                      textBoxLabel={"Parent Company"}
                      margin={"dense"}
                      onChangeCallBack={(data: any) => {
                        formik.setFieldValue("parentCompany", data);
                      }}
                    />
                  </Grid>
                  <div className="jobDescription">
                    <span className="sectionTitle">Other</span>
                  </div>
                  <Grid item xs={12} sm={12} className="gridboxMargin">
                    <Field
                      as={TextField}
                      fullWidth
                      autoComplete="off"
                      label="Linkedin Profile"
                      name="linkedInProfile"
                      margin="dense"                      
                      style={{colorScheme:'dark'}}
                      error={
                        formik.touched.linkedInProfile &&
                        Boolean(formik.errors.linkedInProfile)
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginRight: "0px" }}
                          >
                            https://
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event: any) => {
                        const linkedinValue = event.target.value;
                        if (linkedinValue.includes("https://")) {
                          const httpRemoveString = linkedinValue.replace(
                            "https://",
                            ""
                          );
                          formik.setFieldValue(
                            "linkedInProfile",
                            httpRemoveString
                          );
                        } else {
                          formik.setFieldValue(
                            "linkedInProfile",
                            linkedinValue
                          );
                        }
                      }}

                      sx={{ mb: 1.5 }}
                      size="small"
                      helperText={<ErrorMessage name="linkedInProfile" />}
                    />
                  </Grid>

                  <Grid item className="buttonWrapper" xs={12} sm={12}>
                    <div className="loader">
                      {apiStatusLoading && (
                        <div>
                          <CircularProgress size={25} />
                        </div>
                      )}
                      {!apiStatusLoading && (
                        <Button
                          type="submit"
                          fullWidth
                          size="medium"
                          variant="contained"

                          className={!(formik.isValid && formik.dirty) ? "signupBtnDisabled":"signupBtn"}
                          style={{ textTransform: "none" }}
                          disabled={!(formik.isValid && formik.dirty)}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
        }
         {statusIsLoading &&
             <div className="circuleBox">
             <CircularProgress  />
           </div>
         }
      </div>

      {APIMsgConfig.snackFlag && <div>
        <SnackBarMessage snacBarValue={APIMsgConfig}
        />

      </div>}
    </div>
  );
};
