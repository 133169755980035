/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import "./datatable.scss";
import { MessageText } from "../../constants/messageText";
import {  gridClasses } from "@mui/x-data-grid";
import { GridFooter, GridFooterContainer, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import {DataGridPremium} from '@mui/x-data-grid-premium';
import { useNavigate } from "react-router-dom";
import { RemoveCircleOutline, AddCircleOutline } from '@mui/icons-material';

interface Props {
  tableConfig:any;
  dataGridColumn:any;
  navigateTab:any;
  tableHeaderName?:any;
  onPageChangeCallback: (page: any) => void;
  onPageSizeCallback:(pageSize:any)=>void;
  showPDF?:(flag:any)=>void;
  handleClick:any;
  rowHeight?: number;
  onCellFilterAdd?: (data: any) => void;
  onCellFilterRemove?: (data: any) => void;
  enableCellFilter?: boolean;
  showFooterMessage?: boolean;
}

export default function DataTable(props:Props) {
  const navigate = useNavigate();
  const { rowHeight = 62, onCellFilterAdd, onCellFilterRemove, enableCellFilter = false } = props;


  const CustomToolbar: React.FC<any> = () => {
    return (
      <div style={{paddingLeft:10}}>
        <GridToolbarContainer>
          {/* @ts-ignore */}
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      </div>
    );
  };

  const CustomPaginationText:React.FC<any>= ()=> {
    return (
      <Box>
        {props.showFooterMessage &&
          <Box sx={{ textAlign: 'center', fontWeight: 600, paddingY: 1, background: '#eaecf0', color: '#7f7f7f' }}>
            Search results are limited to 10000 documents. Add more filters to narrow your search results.
          </Box>
        }
        <GridFooterContainer sx={{ border: 0 }}>
          <span className="footerCopyRightText">
            Copyright © 2019-2024 Infraclear Inc.
          </span>
          <GridFooter sx={{
            border: "0px", // To delete double border.
          }} />
        </GridFooterContainer>
      </Box>
    );
  }

  const NoRowsOverlay: React.FC<any> = () => {
    return (
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        fontSize={14}
        color="#FFFF"
      >
        {MessageText.contracts_not_assigned_to_you}
      </Stack>
    );
  };
  
  
  const StripedDataGrid = styled(DataGridPremium)(() => ({

    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: "#F9FAFB",
      "&:hover, &.Mui-hovered": {
        cursor: "pointer",
        backgroundColor: "#eaecf0",
        "@media (hover: none)": {
          cursor: "pointer",
          backgroundColor: "#eaecf0",
        },
      },
    },
    [`& .${gridClasses.row}.even`]: {
      "&:hover, &.Mui-hovered" : {
        cursor: "pointer",
        backgroundColor: "#eaecf0",
        "@media (hover: none)": {
          cursor: "pointer",
          backgroundColor: "#eaecf0",
        },
      },
    },
  
    "& .MuiDataGrid-main": {
      width: "98%",
      margin: "0 auto",
      borderLeft:"1px solid #EAECF0",
      borderRight:"1px solid #EAECF0",
    },

  }));

  const getFilterableCell = () => {
    return props?.dataGridColumn?.map((c: any) => {
      if (!c?.renderCell) {
        c.renderCell = (params: any) => {
          const payload = { field: params.field, value:params?.row?.[params?.field], params };
          return (
            <div className="common-cell-renderer">
              <div className="filter">
                {enableCellFilter &&
                  <div>
                    <AddCircleOutline onClick={() => onCellFilterAdd?.(payload)} />
                    <RemoveCircleOutline onClick={() => onCellFilterRemove?.(payload)} />
                  </div>
                }
              </div>
              <span>{params?.row?.[params?.field] || '-'}</span>
            </div>
          );
        }
      }
      return c;
    }) || [];
  }

  return (
    <div className="datatable">
      <div>
      {props?.tableHeaderName &&
        <div className="tableHeaderName" >
          <span className="tableHead">
            {props.tableHeaderName}
          </span>
        </div>
      }
      </div>
      <StripedDataGrid
        className="gridTable"
        rows={props.tableConfig.data}
        rowCount={props.tableConfig.total}
        rowsPerPageOptions={[20, 30, 50, 100, 250, 500]}
        pagination
        page={props.tableConfig.page - 1}
        pageSize={props.tableConfig.pageSize}
        // columns={props.dataGridColumn}
        columns={props.dataGridColumn} // props.dataGridColumn
        rowHeight={rowHeight || 62}
        // rowHeight={62}
        headerHeight={38} 
        paginationMode={'server'}

        disableSelectionOnClick
        hideFooterSelectedRowCount
        components={{ NoRowsOverlay, Footer: CustomPaginationText }}
        onCellClick={(selectedID)=>{
          if(props.handleClick =='projects'){
              if(props.navigateTab =='/project-details'){
                const URL = `/project-details?projectId=${selectedID.row.id}&urldetails=projects`;
                navigate(URL);
              }else{
                navigate(props.navigateTab);
              }
          }
          if(props.handleClick =='projectDetails'){
            localStorage.setItem(
              "selectedAgreement",
              JSON.stringify(selectedID.row)
            );
            props?.showPDF?.(true);
          }
        }}
        onPageChange={(page: number) => {
          props.onPageChangeCallback(page);
          
        }}
        onPageSizeChange={(pageSize: number) => {
         
          props.onPageSizeCallback(pageSize);

        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        {...props}
      />
    </div>
  );
};
