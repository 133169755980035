/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";

import "./ConfirmationBox.scss";

export const ConfirmationBox: React.FC<any> = ({
  openConfirmation,
  closeConfirmation,
  msgConfig,
}) => {
  const [open, setOpen] = React.useState(openConfirmation);

  const handleNo = () => {
    setOpen(false);
    closeConfirmation(false);
  };

  const handleYes = () => {
    setOpen(false);
    closeConfirmation(true);
  };

  return (
  
      <Dialog open={open}  className="logoutPopup">

        <DialogTitle className="dialogTitleStyle">
          <span className="titleText">Confirm</span>

        </DialogTitle>
        <Divider className="divider"/>
        <DialogContent>
          <DialogContentText>
            <span className="titleDesc">Are you sure you want to logout?</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{p:"3px 20px 20px 20px;"}}>
          <Button
            className="confirmationCancel"
            variant="outlined"
            onClick={handleNo}
          >
            Cancel
          </Button>
          <Button
            className="confirmation"
            variant="contained"
            onClick={handleYes}
            autoFocus
          >
            Logout
          </Button>
        </DialogActions>

  
      </Dialog>
  
  );
};
