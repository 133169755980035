/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/


import { styled, TextField } from "@mui/material";
import { convertKey } from "./env-url";


export const CssTextField = styled(TextField)({
 
  '& label': {
    color: "#D5D8DC",
  },
  "& label.Mui-focused": {
    color: "#D5D8DC",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },

  "& .MuiSvgIcon-root": {
    color: "#D5D8DC",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D5D8DC",
  },
  "& .MuiOutlinedInput-root": {
    color: "#D5D8DC",
    colorScheme: "dark",
    "& fieldset": {
      borderColor: "#D5D8DC",
    },
    "&:hover fieldset": {
      borderColor: "#D5D8DC",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D5D8DC",
    },
    "&.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
    "& .MuiTypography-root": {
      color: "#D5D8DC",
    },
  },

  "& .MuiInputLabel-root.Mui-disabled": {
    color: "#80808087 !important",
  },

  "& .MuiInputBase-input.Mui-disabled": {
    color: "#80808087 !important",
    "-webkit-text-fill-color": "#80808087 !important",
    borderColor: "#80808087 !important",
  },
  "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "#80808087 !important",
  }  
});

export const alphaNumaricInput = (e: any) => {
  const re = /^[ a-zA-Z0-9]+$/;;

  if (!re.test(e.key)) {
    e.preventDefault();
  }
};
export const whiteSpaceRestrict = (e: any) => {
  const re = /^\S*$/;

  if (!re.test(e.key)) {
    e.preventDefault();
  }
}
//password encryption

var CryptoJS = require("crypto-js/core");
CryptoJS.AES = require("crypto-js/aes");
var PBKDF2: any = require("crypto-js/pbkdf2");

export const encryptpassword = async (plaintext:any) => {
  const encryptedPassword_value = await encryptMyPassword(plaintext);
  return encryptedPassword_value;
}

export const encryptMyPassword = async (plaintext: string) => {
  const keySize = 128;
  const iterations = 1000;
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const iv = crypto.getRandomValues(new Uint8Array(16));

  const derivedKey = await crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(convertKey),
    { name: "PBKDF2" },
    false,
    ["deriveBits", "deriveKey"]
  );
  const key = await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: iterations,
      hash: "SHA-256",
    },
    derivedKey,
    { name: "AES-CBC", length: keySize },
    true,
    ["encrypt", "decrypt"]
  );

  const encrypted = await crypto.subtle.encrypt(
    { name: "AES-CBC", iv: iv },
    key,
    new TextEncoder().encode(plaintext)
  );

  const saltBase64 = arrayBufferToBase64(salt);
  const ivBase64 = arrayBufferToBase64(iv);
  const ciphertextBase64 = arrayBufferToBase64(encrypted);

  const encryptedData = `${ivBase64}::${saltBase64}::${ciphertextBase64}`;
  return btoa(encryptedData);
};

const arrayBufferToBase64 = (buffer: any) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export const fullTextSearchTextFiled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    padding:0,
    '& fieldset': {
      borderColor: '#D5D8DC',
    },
    '&:hover fieldset': {
      borderColor: '#D5D8DC',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #D5D8DC',
    },
  },
});

export const ROLES = {
  DOC_VERIFICATION_USER: 'DOC_VERIFICATION_USER',
  DOC_ACQUISITION_USER: 'DOC_ACQUISITION_USER',
  DOC_SEARCH_USER: 'DOC_SEARCH_USER',
  DOC_SEARCH_DEMO_USER: 'DOC_SEARCH_DEMO_USER',
  DOC_VERIFICATION_ADMIN: 'DOC_VERIFICATION_ADMIN',
  SALES_REPRESENTATIVE: 'SALES_REPRESENTATIVE',
  USER_MGMT_ADMIN: 'USER_MGMT_ADMIN',
  GUEST: 'GUEST',
  SUPER_ADMIN: 'SUPER_ADMIN',
  FINANCIAL_MODEL_SUBSCRIBER: 'FINANCIAL_MODEL_SUBSCRIBER',
  CONTRACTS_TRAIL: 'CONTRACTS_TRAIL',
  FINANCIAL_MODELS_TRAIL: 'FINANCIAL_MODELS_TRAIL',
  CONTRACTS_SUBSCRIBER: 'CONTRACTS_SUBSCRIBER',
};