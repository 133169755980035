/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

import './helpPage.scss';
import * as React from 'react';
import ContractHelp from './contractHelp';

export const HelpPage: React.FC<any> = (props: any) => {

  return (
    <div className="faq-pane">
      <div className='helpWrapper'>
      <div className='description fixed-parent'>
        <ContractHelp/>
      </div>
    </div>
      <div className="footerCopyRightText">
        <span className="copyRightText">
          Copyright © 2019-2024 Infraclear Inc.
        </span>
      </div>
    </div>


  )
}