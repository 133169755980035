/**
Copyright (C) 2019-2023, Infraclear Inc. All Rights Reserved.
*/

import * as React from 'react';
import { PropsWithChildren } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Divider, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './modal.scss'


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    maxWidth: 'unset',
    margin: '15px !important',
    maxHeight: 'calc(100% - 30px)',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  className?: string;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 1, display: 'flex', minWidth: '200px' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    </div>
  );
};

export interface ModalProps extends PropsWithChildren<any> {
  title?: string;
  onClose: () => void;
  open: boolean;
  bodyClassName?: string;
}

export const Modal = (props: ModalProps) => {

  const { open, title, onClose, children, bodyClassName } = props;

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="rolesPopupWrapper">
          {title && <div className='term-comparision-box'>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={onClose}
              className=""
            >
              <span className='titleText'>{title}</span>
            </BootstrapDialogTitle>
            </div>
          }
          <div className={bodyClassName ? bodyClassName : 'rolesWrapper'}>
            {children}
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
}
