// @ts-nocheck
import { Box, CircularProgress, Grid } from "@mui/material";
import { Close } from '@mui/icons-material';
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import Select from 'react-select';
import '../style.scss';
import DataTable from "../../../component/datatable/datatable";
import instance from "../../../utils/axiosInstance";
import { apiBasePathSearch } from "../../../constants/env-url";
import { applyUserFilter, deleteUserSearchFilter, documentCount, projectFilter, termDetails, userFilters } from "../../../constants/api-urlConstants";
import { FilterValues } from "./model";
import TreeMap from "../../../component/Treemap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { components } from "react-select";
import { Button } from "@mui/material";
import { Modal } from "../../../component/Modal";
import { GridRowGroupingModel, GridSortModel, useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import { TAB_NAMES } from "../../../utils/constant";
import { Filter, SaveFilterPayload } from "../agreementTable/model";
import SavedFilters from "../agreementTable/filters";
import SaveFilter, { SaveFilterRef } from "../agreementTable/saveFilter";
import { maximumFilterAllowedToSave } from "../../../utils/config";
import { CommonContextValueStore } from "../../../constants/commonContext";
import { ErrorCodes } from "../../../constants/messageCodes";
import { MessageText } from "../../../constants/messageText";
import { postActivity } from "../trackActivity";

const initialFilterValues: FilterValues[] = [  
  {
    displayName: 'Country',
    field: 'country',
    options: []
  },
  {
    displayName: 'Project Name',
    field: 'project',
    options: []
  },
  {
    displayName: 'Organizations Named',
    field: 'party',
    options: []
  },
  {
    displayName: 'Sector',
    field: 'sector',
    options: []
  },
  {
    displayName: 'Agreement Name',
    field: 'contract_name',
    options: []
  },
  {
    displayName: 'Term Header',
    field: 'header',
    options: []
  },
  {
    displayName: 'Sub-sector',
    field: 'subsector',
    options: []
  },
  {
    displayName: 'Agreement Type',
    field: 'doc_type',
    options: []
  },
  {
    displayName: 'Key Term Type',
    field: 'header_tags',
    options: []
  },
];

const defaultSort = {
  field: 'header_tags',
  direction: 'ASC'
};

const FindTerms = () => {
  const { setSnackabrConfig } = useContext(CommonContextValueStore);
	const saveFilterRef = useRef<SaveFilterRef>(null);
  const [filters, setFilters] = useState<any>({});
  const [appliedDashboardFilters, setAppliedDashboardFilters] = useState<any>([]);
  const [filterOptions, setFilterOptions] = useState<any>({});
  const [chartData, setChartData] = useState<any>();
  const [searchParams] = useSearchParams();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 20,
    sortDirection: defaultSort.direction,
    sortField: defaultSort.field
  });
  const [inputSearchText, setInputSearchText] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [debouncedText] = useDebounce(inputSearchText, 1000);
  const [showCompareTerms, setShowCompareTerms] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [aggregationBucketPage, setAggregationBucketPage] = useState({});
  const [rowGroupingModel, setRowGroupingModel] = useState<GridRowGroupingModel>([]);
  const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(true);
  const [tableSearchString, setTableSearchString] = useState<string>('');
  const [showSaveFilter, setShowSaveFilter] = useState(false);
  const [showSaveFilterModel, setShowSaveFilterModel] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [showSavedFilters, setShowSavedFilters] = useState(false);
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    rowGroupingModel,
  });
  
  useEffect(() => {
    const field = Object.keys(debouncedText)?.[0];
    const value = debouncedText?.[field];
    if (field && value) {
      updateSingleFieldFilterOptionsOnSearch(field, value);
    }
  }, [debouncedText]);

  const columns = [
    {
      field: "keyTermType",
      headerName: "Key Term Type",
			flex: 0.5,
      searchable: true,
      groupable: false,
      renderCell: (params: any) => {
        return (
          <span>{params?.row?.keyTermType?.join(' | ') || ''}</span>
        );
      },
    },
    {
      field: "termHeader",
      headerName: "Term Header",
      flex: 1,
      hideable: false,
      searchable: true,
      groupable: true,
    },
    {
      field: "contractName",
      headerName: "Contract Name",
      flex: 1,
      searchable: true,
      groupable: true,
    },
    {
      field: "agreementType",
      headerName: "Agreement Type",
      flex: 0,
      searchable: true,
      groupable: true,
    },
    {
      field: "project",
      headerName: "Project Name",
      flex: 0,
      searchable: true,
      groupable: true,
    },
    {
      field: "bucketId",
      headerName: "View Term",
      flex: 0,
      hideable: false,
      sortable: false,
      searchable: false,
      groupable: false,
      renderCell: (params: any) => {
        if (params?.formattedValue) {
          const fileName = `${params.row.bucketId}indexed_document.pdf`;
          const path = `/term-finder?docId=${params.row.docId}&s3Key=${fileName}&contractName=${params.row.contractName}#nameddest=${params.row.headerId}&page=${params.row.pageNumber}`;
          const url = `${window.location.origin}${path}`;
          return (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
								const payload = {
									fileName: fileName,
									contractName: params?.row?.contractName || '',
									contractDate: params?.row?.contractDate || '',
									contractTypeName: params?.row?.agreementType || '',
									financialLevel: params?.row?.financialLevel || '',
									projectName: params?.row?.project || '',
									country: params?.row?.country || '',
									sectors: params?.row?.sectors?.join(', ') || '',
									subSectors: params?.row?.SubSectors?.join(', ') || '',
									fileUrl: path
								};
								postActivity(payload);
							}}
            >
              Open Term
            </a>
          );
        } else {
          return '';
        }
      },
    },
  ];

  useEffect(() => {
    const contractName = searchParams.get('contractName');
    const documentId = searchParams.get('doc_id');
    if (contractName) {
      const previousFilter = JSON.parse(JSON.stringify(filters));
      const newFilters = {
        ...previousFilter,
        contract_name: [{
          "filterKey": contractName,
          "filterValue": contractName,
        }]
      };
      setFilters(newFilters);
    }

    if (contractName && documentId) {
      const dashboardFilters = [
        {
          elasticCriteria : "INCLUDE",
          field : 'contract_name',
          filterCriterias : [contractName]
        },
        {
          elasticCriteria : "INCLUDE",
          field : 'doc_id', 
          filterCriterias : [documentId]
        }
      ]
      setAppliedDashboardFilters(dashboardFilters);
      fetchFilterOptions({field: 'project'}, [], 0);
      fetchDashboardFilterOptions(dashboardFilters);
      fetchTermCounts(dashboardFilters);
      fetchChartData(dashboardFilters);
      fetchTableData(pageState.page, pageState.pageSize, dashboardFilters);
    } else {
      onChangeFilter(null, null);
    }
    fetchSavedFilters();
  }, [])

  const fetchDashboardFilterOptions = (dashboardFilters = []) => {
		const filterAPI = `${apiBasePathSearch}${projectFilter}`;
		[...initialFilterValues].map(filter => {
      if (filter.field !== 'project') {
        const payload: any = {
          field: filter.field,
          documentSearchPage: "TERMS",
          dashboardFilters: dashboardFilters,
          aggregationBucketPage: 0,
          bucketSort: 'DOC_COUNT',
          sortOrder: "Desc",
        };
        (filter.field) && instance.post(filterAPI, payload).then((res: any) => {
          const data = res.data;
          setFilterOptions((old: any) => ({
            ...old,
            [filter.field]: data?.filterDetailsList,
          }));
        }).catch(() => {})
      }
		})
	}

  // fetch terms for chart data
  const fetchChartData = (dashboardFilters = []) => {
    const payload = {
      field: "header_tags",
      documentSearchPage: "TERMS",
      dashboardFilters: dashboardFilters,
      aggregationBucketPage: 0,
      bucketSort: 'DOC_COUNT',
      sortOrder: "Desc"
    }
    const filterAPI = `${apiBasePathSearch}${projectFilter}`;
    instance.post(filterAPI, payload).then((res: any) => {
      const list = res?.data?.filterDetailsList || [];
      const chartData = [{
        name: "",
        children: (list?.length === 1 && list?.[0]?.filterValue === "Other") ? list : list.filter(a => a.filterValue !== "Other")
      }];
      setChartData(chartData);
    }).catch(() => {})
  }

  // fetch total terms count
  const fetchTermCounts = (dashboardFilters = []) => {
    const payload = {
      dashboardFilters: dashboardFilters,
      documentSearchPage: "TERMS"
    }
    const filterAPI = `${apiBasePathSearch}${documentCount}`;
    instance.post(filterAPI, payload).then((res: any) => {
      setPageState(old => ({ ...old, total: res?.data?.documentCount }));
    }).catch(() => {});
  }

  // fetch terms for table data
  const fetchTableData = (page: number, pageSize: number, dashboardFilters = [], sortField = pageState.sortField, sortDirection = pageState.sortDirection) => {
    const payload = {
      page: page,
      size: pageSize,
      sortField,
      sortDirection,
      dashboardFilters: dashboardFilters
    }
    setPageState((old) => ({ ...old, page, pageSize, sortDirection, sortField }));
    fetchData(payload);
  }

  const fetchData = (payload: any) => {
    const filterAPI = `${apiBasePathSearch}${termDetails}`;
    instance.post(filterAPI, payload).then((res: any) => {
      const data = res?.data?.data?.dataList || [];
      setPageState((old) => ({ ...old, data: data }));
    }).catch(() => {})
  }

  const onScrollEnd = (field: string) => {
    if ((aggregationBucketPage?.[field]?.currentCount < aggregationBucketPage?.[field]?.totalAvailable) || !aggregationBucketPage?.[field]?.currentCount) {
      const newCount = (aggregationBucketPage?.[field]?.currentCount || 0) + 20;
      fetchFilterOptions({ field: field }, appliedDashboardFilters, newCount, true);
    }
  }

  const onChangeFilter = (field, value, item, isOnClearAll = false) => {
    
    const previousFilter = JSON.parse(JSON.stringify(filters));
    const newFilters = isOnClearAll ? {} : {
      ...previousFilter,
      [field]: value
    };
    setFilters(newFilters);
    const unFilteredFields = initialFilterValues.filter((key) => !newFilters?.[key?.field] || (!Object.keys(newFilters).includes(key.field) && newFilters?.[key?.field]));
    const dashboardFilters = isOnClearAll ? [] : Object.keys(newFilters).filter((key) => {
      return newFilters?.[key]?.length > 0;
    })?.map((key) => {
      const criterias = newFilters?.[key]?.map(m => m.filterKey) || newFilters?.[key]?.map(m => m.filterValue) || [];
      return (criterias?.length) && {
        field: key,
        filterCriterias: criterias?.filter(a => a),
        elasticCriteria: "INCLUDE"
      }
    });
    setAppliedDashboardFilters(dashboardFilters.length !== 0 ? dashboardFilters : value?.length ? value : []);
    updateFilterOptions(unFilteredFields, dashboardFilters.length !== 0 ? dashboardFilters : value?.length ? value : [], item);
    fetchTermCounts(dashboardFilters.length !== 0 ? dashboardFilters : value?.length ? value : []);
    fetchTableData(pageState.page, pageState.pageSize, dashboardFilters.length !== 0 ? dashboardFilters : value?.length ? value : []);
    fetchChartData(dashboardFilters.length !== 0 ? dashboardFilters : value?.length ? value : []);
  }

  const updateFilterOptions = (unFilteredFields: any[], dashboardFilters: any, item: any) => {
    if (item) {
      unFilteredFields.map(filter => {
        if (filter.field) {
          fetchFilterOptions(filter, dashboardFilters, 0);
        }
      })
    } else {
      unFilteredFields.map(filter => {
        fetchFilterOptions(filter, dashboardFilters, 0);
      })
    }
  }
  
  const fetchFilterOptions = (filter: any, dashboardFilters: any, aggregationBucketPage: number, isOnScroll = false) => {
    const filterAPI = `${apiBasePathSearch}${projectFilter}`;
    const payload = {
      field: filter.field,
      dashboardFilters: dashboardFilters,
      documentSearchPage: "TERMS",
      aggregationBucketPage: aggregationBucketPage,
      bucketSort: 'DOC_COUNT',
      sortOrder: "Desc"
    };
    (filter.field) && instance.post(filterAPI, payload).then((res: any) => {
      const data = res.data;
      setFilterOptions((old: any) => ({
        ...old,
        [filter.field]: [
          ...(isOnScroll ? (old?.[filter?.field] || []) : []),
          ...data?.filterDetailsList
        ],
      }));
      setAggregationBucketPage((old) => ({
        ...old,
        [filter.field]: {
          totalAvailable: data?.cardinalCount,
          currentCount: aggregationBucketPage
        }
      }))
    }).catch(() => {})
  }

  const updateSingleFieldFilterOptionsOnSearch = (field: string, value: string) => {
    setIsLoading(true);
    const filterAPI = `${apiBasePathSearch}${projectFilter}`;
    const payload = {
      field: field,
      dashboardFilters: [],
      wildCardFilterStatus: true,
      wildCardFilterCriteria: value,
      documentSearchPage: "TERMS",
      aggregationBucketPage: 0,
      bucketSort: 'DOC_COUNT',
      sortOrder: "Desc"
    };
    instance.post(filterAPI, payload).then((res: any) => {
      const data = res.data;
      setFilterOptions((old: any) => ({
        ...old,
        [field]: data?.filterDetailsList,
      }));
    }).catch(() => {}).finally(() => {
      setIsLoading(false);
    });
  }

  const onClickData = (data: any) => {
    setShowSaveFilter(true);
    onChangeFilter('header_tags', [data], data);
  }

  const getLabelForField = (field: string) => {
    return (field !== 'contract_name' && field !== 'doc_id') ? initialFilterValues.find(filter => filter.field === field)?.displayName || '' : field === 'contract_name' ? 'Agreement Name' : 'Doc Id';
  }

  const getValueForField = (f: any) => {
    const contractName = searchParams.get('contractName');
    return f?.field === 'doc_type' ? contractName || f?.filterCriterias?.join(' | ') : f?.filterCriterias?.join(' | ');
  }

  const onClickRemoveFilter = (field: string) => {
    onChangeFilter(field, null, field === 'project' ? null : field);
    if (field === 'contract_name') {
      navigate("/dashboard/term");
    }
  }

  const onClickSearch = (options: any) => {
    setTableSearchString(options.value);
    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      sortField: pageState.sortField,
      sortDirection: pageState.sortDirection,
      dashboardFilters: appliedDashboardFilters,
      wildCardField: options?.field?.field,
      wildCardFilterStatus: true,
      wildCardFilterCriteria: options.value
    }
    fetchData(payload);
  }

  const Option = (props: any) => {
		return (
			<div>
				<components.Option {...props}>
          <Box sx={{ display: 'flex', gap: 1, cursor: 'pointer' }}>
            <input
              type="checkbox"
              defaultChecked={props.isSelected}
              className="select-checkbox"
            />
            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', gap: '5px', width: '100%' }}>
              <Box sx={{ overflowWrap: 'break-word', maxWidth: '130px' }}>{props.label}</Box>
              <Box>{(props?.data?.filterCount)?.toLocaleString()}</Box>
            </Box>
          </Box>
				</components.Option>
			</div>
		);
	};
  
  const onCloseCompareTerms = () => {
    setShowCompareTerms(false);
  }

  const fetSelectedRows = () => {
    const ids = apiRef?.current?.getSelectedRows();
    const obj = Object.fromEntries(ids.entries());
    const selectedIDs = new Set(Object.keys(obj));
    const pageStateObj = JSON.parse(JSON.stringify(pageState));
    const selectedRowData = pageStateObj?.data?.filter((row) =>
      selectedIDs.has(row.id.toString())
    );
    setSelectedRows(selectedRowData);
  }

  const onSortModelChange = (currentSortModel: GridSortModel) => {
    setSortModel(currentSortModel);
    const field = currentSortModel?.[0]?.field;
    const sort = currentSortModel?.[0]?.sort;
    const fields = {
      keyTermType: 'header_tags',
      termHeader: 'header',
      contractName: 'contract_name',
      agreementType: 'doc_type_tags',
      project: 'project',
    }
    if (field && sort) {
      fetchTableData(pageState.page, pageState.pageSize, appliedDashboardFilters, fields[field], (sort)?.toUpperCase());
    }
  }

  const onClickClearAllFilter = () => {
    onChangeFilter(null, null, null, true);
    const contractName = searchParams.get('contractName');
    if (contractName) {
      navigate("/dashboard/term");
    }
  }

  const onClickClearAllTableFilter = () => {
    setRowGroupingModel([]); // reset row grouping
    setSortModel([]); // reset sorting
    setTableSearchString(''); // reset table search

    fetchTableData(pageState.page, pageState.pageSize, appliedDashboardFilters, defaultSort.field, defaultSort.direction);
  }

  const fetchSavedFilters = () => {
    const filterAPI = `${apiBasePathSearch}${userFilters}?filterPage=${TAB_NAMES.TERMS}`;
    instance.get(filterAPI).then((res: any) => {
      const data = res.data || [];
      setSavedFilters(data);
    });
  }

  const fetchFilterBasedOnId = (filterId: string) => {
    const filterAPI = `${apiBasePathSearch}${applyUserFilter}?filterId=${filterId}`;
    instance.post(filterAPI).then((res: any) => {
      const data = res.data;
      const userFilter = JSON.parse(data.userFilter);
      setAppliedDashboardFilters(userFilter.dashboardFilters);

      fetchTermCounts(userFilter.dashboardFilters);
      fetchChartData(userFilter.dashboardFilters);
      fetchTableData((userFilter.page + 1), userFilter.size, userFilter.dashboardFilters, userFilter.sortField, userFilter.sortDirection);
      let filters: any = {};
      userFilter?.dashboardFilters?.map((df: any) => {
        filters[df?.field] = df.filterCriterias?.map((x: any) => ({ filterKey: x, filterValue: x }));
      });
      setFilters(filters);

    });
  }

  const onClickSaveFilter = () => {
    setShowSaveFilterModel(true);
  }

  const closeSaveFilterPopup = () => {
    setShowSaveFilterModel(false);
  }

  const onSaveFilter = (values: any) => {
    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      sortField: pageState.sortField,
      sortDirection: pageState.sortDirection,
      dashboardFilters: appliedDashboardFilters,
      userFilterName: values.filterName,
    }
    saveOrUpdateFilter(payload);
  }

  const onUpdateFilter = (filter: Filter) => {
    const payload = filter.userFilter ? JSON.parse(filter.userFilter) : {};
    payload.userFilterId = filter.id;
    payload.userFilterName = filter.name;
    saveOrUpdateFilter(payload);
  }

  const onReplaceFilter = (filter: { id: number, name: string }) => {
    const payload = {
      page: pageState.page,
      size: pageState.pageSize,
      sortField: pageState.sortField,
      sortDirection: pageState.sortDirection,
      dashboardFilters: appliedDashboardFilters,
      userFilterName: filter.name,
      userFilterId: filter.id,
    }
    saveOrUpdateFilter(payload);
  }

  const saveOrUpdateFilter = (payload: SaveFilterPayload) => {
    const filterAPI = `${apiBasePathSearch}${termDetails}`;
    instance.post(filterAPI, payload).then(() => {
      closeSaveFilterPopup();
      fetchSavedFilters();
    }).catch(error => {
			if (error?.response?.data?.message_code === ErrorCodes.FILTER_NAME_NOT_UNIQUE) {
				setSnackabrConfig({
					type: 'error',
					message: MessageText.FILTER_NAME_NOT_UNIQUE,
					snackFlag: true
				});
				saveFilterRef?.current?.resetLoading();
			}
    }).finally(() => {
      setShowSaveFilter(false);
    });
  }

  const onDeleteFilter = (filter: Filter) => {
    const filterAPI = `${apiBasePathSearch}${deleteUserSearchFilter}?filterId=${filter.id}&filterPage=${TAB_NAMES.TERMS}`;
    instance.post(filterAPI).then(() => {
      fetchSavedFilters();
    });
  }

  return (
    <Fragment>
      <div className="filter-wrapper">
        <Box sx={{ width: '100%' }}>
          {appliedDashboardFilters?.length > 0 &&
            <div className="filters">
              {appliedDashboardFilters?.map((f: any, index: number) => (
                <div key={index} className="filter-value">
                  <span><span className="font-bold">{getLabelForField(f.field)}</span>: {getValueForField(f)}</span> <Close onClick={() => { onClickRemoveFilter(f.field) }} />
                </div>
              ))}
            </div>
          }
        </Box>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          {appliedDashboardFilters?.length > 0 &&
            <Fragment>
              {showSaveFilter &&
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  onClick={() => onClickSaveFilter()}
                  variant="contained"
                >
                  Save
                </Button>
              }
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                onClick={() => onClickClearAllFilter()}
                variant="outlined"
              >
                Clear All
              </Button>
            </Fragment>
          }

          <Button
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => savedFilters?.length ? setShowSavedFilters(true) : {}}
            variant="contained"
          >
            Saved Filters {savedFilters?.length ? `(${savedFilters?.length})` : '(0)'}
          </Button>
        </Box>
      </div>
      <section className={`term-dashboard`}>
        <Box sx={{ display: 'flex', marginBottom: 2, gap: '15px' }}>
					<Box sx={{ width: 'calc(100% - 150px)' }}>
            <Grid container spacing={2}>
              {initialFilterValues.map((filter, index) => {
                const searchingField = Object.keys(inputSearchText)?.[0];
                return (
                  <Grid item lg={4} key={index}>
                    <label className="label">{filter?.displayName}</label>
                    <Select
                      key={`select-${filter?.field}-${index}`}
                      isMulti
                      closeMenuOnSelect= {false}
                      hideSelectedOptions= {false}
                      isLoading={searchingField === filter?.field && isLoading}
                      components={{
                        LoadingIndicator: () => <CircularProgress size={14} sx={{ marginRight: '8px' }} />,
                        Option
                      }}
                      placeholder={`Select ${filter?.displayName?.toLowerCase()}`}
                      options={filterOptions?.[filter?.field] || []}
                      isClearable
                      value={filters?.[filter?.field] || null}
                      onChange={(value) => {
                        const filterValue = value?.length ? value : null;
                        onChangeFilter(filter.field, filterValue, filter.field === 'project' ? null : filter);
                        setShowSaveFilter(true);
                      }}
                      onInputChange={(value, actionMeta) => {
                        if (value) {
                          setInputSearchText({ [filter.field]: value });
                        } else if (!value && actionMeta?.action === "input-change") {
                          setTimeout(() => {
                            fetchFilterOptions(filter, appliedDashboardFilters, 0);
                          }, 1000);
                        }
                      }}
                      onMenuScrollToBottom={() => {
                        onScrollEnd(filter?.field);
                      }}
                      styles={{
                        container: (provided: MediaProvider) => ({
                          ...provided,
                          marginTop: '5px',
                        }),
                        control: (provided: MediaProvider) => ({
                          ...provided,
                          cursor: 'pointer'
                        }),
                        input: (provided: MediaProvider) => ({
                          ...provided,
                          cursor: 'text'
                        }),
                        option: (provided: MediaProvider, state: any) => ({
                          ...provided,
                          color: "#000000",
                          backgroundColor: "#ffffff",
                          "&:hover": { backgroundColor: "#1976d2", color: "#ffffff" },
                        }),
                        menu: (baseStyles) => ({ ...baseStyles, zIndex: 10 })
                      }}
                      getOptionLabel={(option: any) => option.filterKey || option.filterValue || ""}
                      getOptionValue={(option: any) => option.filterKey || option.filterValue || ""}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Box sx={{ width: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className={`doc-count ${isFilterExpanded ? '' : 'closed'}`}>
							<Box sx={{ fontSize: isFilterExpanded ? 18 : 16 }}>{(pageState.total)?.toLocaleString()}</Box>
							<Box sx={{ margin: isFilterExpanded ? 1 : '1px', opacity: '0.7' }}>Terms</Box>
						</div>
					</Box>
        </Box>

        <TreeMap
          data={chartData}
          onClickData={(data) => onClickData(data)}
        />

        <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                setShowCompareTerms(true);
                fetSelectedRows();
              }}
              variant="contained"
              disabled={selectedRowId.length !== 2}
            >
              Compare Terms
            </Button>
            <div className="beta-version">Beta</div>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => onClickClearAllTableFilter()}
              variant="contained"
              disabled={!rowGroupingModel.length && !sortModel.length}
            >Reset</Button>
          </Box>
        </Box>

        <DataTable
          // @ts-ignore
          disableColumnFilter
          apiRef={apiRef}
          initialState={initialState}
          checkboxSelection={rowGroupingModel.length === 0}
          onSelectionModelChange={(newRowSelectionModel) => {
            const currentPosition = apiRef.current.getScrollPosition();
            setSelectedRowId(newRowSelectionModel);
            setTimeout(() => {
              document.querySelector('.MuiDataGrid-virtualScroller').scrollTop = currentPosition.top;
            }, 0);
          }}
          rowGroupingModel={rowGroupingModel}
          onRowGroupingModelChange={(model) => {
            setRowGroupingModel(model)
          }}
          selectionModel={selectedRowId}
          dataGridColumn={columns}
          tableConfig={pageState}
          onPageChangeCallback={(pageValue: any) => {
            const page = pageValue + 1;
            setPageState((old) => ({ ...old, page: page }));
            fetchTableData(page, pageState.pageSize, appliedDashboardFilters);
          }}
          onPageSizeCallback={(pageSizeValue: any) => {
            setPageState((old) => ({ ...old, pageSize: pageSizeValue }));
            fetchTableData(pageState.page, pageSizeValue, appliedDashboardFilters);
          }}
          handleClick='projectDetails'
          navigateTab={undefined}
          rowHeight={45}
          getDetailPanelContent={({ row }) => (
            <Box sx={{ p: 2 }}>
              <div className="label">Organizations Named</div>
              <div>{row?.parties?.join(' | ')}</div>
              {row?.term &&
                <Box sx={{ marginTop: 1 }}>
                  <div className="label">Preview</div>
                  <div>{row?.term}</div>
                </Box>
              }
            </Box>
          )}
          getDetailPanelHeight={() => 150}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          showFooterMessage={pageState?.total > 10000}
        />
        <Modal
          title="Compare Terms"
          open={showCompareTerms}
          onClose={() => onCloseCompareTerms()}
          bodyClassName="hide-body"
        >
          <Box sx={{ display: 'flex', width: '96vw', height: '96vh' }}>
            {selectedRows?.map((selectedRow, index) => (
              <div key={`term-compare-${index}`} className="term-comparision-wrapper">
                <iframe
                  src={`${window.location.origin}/term-finder?docId=${selectedRow.docId}&s3Key=${selectedRow.bucketId}indexed_document.pdf&contractName=${selectedRow.contractName}&defaultTab=text#nameddest=${selectedRow.headerId}&page=${selectedRow.pageNumber}`}
                  width="100%"
                  height="100%"
                />
              </div>
            ))}
          </Box>
        </Modal>
      </section>
      <Modal
        title={(savedFilters.length < maximumFilterAllowedToSave) ? 'Save Filter' : "Replace Existing Filter"}
        open={showSaveFilterModel}
        onClose={() => closeSaveFilterPopup()}
        bodyClassName="hide-body"
      >
        <SaveFilter
          ref={saveFilterRef}
          onSaveFilter={onSaveFilter}
          onReplaceFilter={onReplaceFilter}
          onCancel={() => closeSaveFilterPopup()}
          savedFilters={savedFilters}
        />
      </Modal>
      <Modal
        title="Saved Filters"
        open={showSavedFilters}
        onClose={() => setShowSavedFilters(false)}
      >
        <SavedFilters
          savedFilters={savedFilters}
          onCancel={() => setShowSavedFilters(false)}
          onSelectFilter={(filter) => {
            setShowSavedFilters(false);
            fetchFilterBasedOnId(filter.id);
            setShowSaveFilter(false);
          }}
          onUpdateFilter={onUpdateFilter}
          onConfirmDelete={onDeleteFilter}
        />
      </Modal>
    </Fragment>
  );
}
export default FindTerms;
