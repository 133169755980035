import { Box, Card } from "@mui/material";
import MetaData from "./metaData";
import TermFinderWrapper from "./termFinderStyleWrapper";
import './style.scss';
import { useEffect, useState } from "react";
import { apiBasePathSearch } from "../../constants/env-url";
import { metaDataDetails } from "../../constants/api-urlConstants";
import instance from "../../utils/axiosInstance";
import TermViewer from "./termViewer";

const TermFinder = () => {

  const [metaData, setMetaData] = useState<any>();
  const urlParams = new URLSearchParams(window.location.search);
  const s3Key = urlParams.get('s3Key');

  useEffect(() => {
    const docId = urlParams.get('docId');
    const URL = `${apiBasePathSearch}${metaDataDetails}?documentId=${docId}`;
    instance.get(URL).then((res) => {
      const metaData = res.data;
      setMetaData(metaData)
    }).catch(() => { });
  }, [])

  return (
    <TermFinderWrapper className="term-finder-wrapper-updated">
      <Box sx={{ padding: '5px', display: 'flex', gap: '10px', width: '100%' }}>
        <Card sx={{ width: '300px' }} id="meta-data">
          <MetaData data={metaData} />
        </Card>
        <Card sx={{ width: '100%' }} id="meta-term">
          <Box id="custom_document_header">
            {metaData?.contractName}
          </Box>
          <TermViewer s3Key={s3Key} metaData={metaData} />
        </Card>
      </Box>
    </TermFinderWrapper>
  );
}

export default TermFinder;
